import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Grid, Icon } from "semantic-ui-react";
import LoadComponent from "../../app/layout/LoadComponent";
import { RootStoreContext } from "../../app/stores/rootStore";
import ModuleForm from "./form/ModuleForm";
import ModuleList from "./ModuleList";

interface DetailParams {
  id: string;
}

const ModuleDashboard: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { loadCourse, course } = rootStore.courseStore;
  const { loadModules, loadingInitial, setPredicate } = rootStore.moduleStore;
  const { openModal } = rootStore.modalStore;

  useEffect(() => {
    loadCourse(match.params.id);
    setPredicate(match.params.id);
    loadModules();
  }, [loadCourse, setPredicate, loadModules, match.params.id]);

  if (loadingInitial) return <LoadComponent content="Carregando módulos..." />;

  return (
    <Fragment>
      {course && (
        <Grid>
          <Grid.Row>
            <Grid.Column width={14}>
              <h1>{course!.name}</h1>
              <Link to="/admin/cursos">
                <Icon name="chevron circle left" /> Voltar para Cursos
              </Link>
            </Grid.Column>
            <Grid.Column width={2}>
              <Button
                active
                positive
                content="Novo Módulo"
                floated="right"
                style={{ marginTop: "12px" }}
                onClick={() =>
                  openModal(
                    <ModuleForm id="" courseId={match.params.id} />,
                    "small",
                    false,
                    true
                  )
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <ModuleList id={match.params.id} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Fragment>
  );
};

export default observer(ModuleDashboard);
