import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Pagination, Segment, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import UserListItem from "./UserListItem";

const UserList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    studentsByName,
    loadStudents,
    setPage,
    page,
    totalPages,
    studentCount,
  } = rootStore.userStore;

  const onPageChange = (e: any, pageInfo: any) => {
    setPage(pageInfo.activePage);
    loadStudents();
  };
  
  return (
    <Fragment>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Usuário</Table.HeaderCell>
            <Table.HeaderCell width={8}>Nome</Table.HeaderCell>
            <Table.HeaderCell width={5}>Email</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {studentsByName.map((user) => (
            <UserListItem key={user.id} user={user} />
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              <Segment
                basic
                clearing
                floated="left"
                style={{ paddingTop: "35px", fontSize: "12pt" }}
              >
                <strong>Total de Alunos: {studentCount}</strong>
              </Segment>
              <Segment basic clearing floated="right">
                <Pagination
                  defaultActivePage={page}
                  onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
                  totalPages={totalPages}
                />
              </Segment>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Fragment>
  );
};

export default observer(UserList);
