import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Header, List, Segment } from "semantic-ui-react";
import LoadComponent from "../../../app/layout/LoadComponent";
import { ExamFormValues } from "../../../app/models/exam";
import { RootStoreContext } from "../../../app/stores/rootStore";

interface DetailParams {
  id: string;
}

const ReleasedExamItem: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { loadExam, loadingInitial } = rootStore.examStore;
  const { loadExamCategories } = rootStore.examCategoryStore;
  const { startExam } = rootStore.examUsersStore;

  const [exam, setExam] = useState(new ExamFormValues());

  useEffect(() => {
    if (match.params.id) {
      loadExam(match.params.id).then((exam) =>
        setExam(new ExamFormValues(exam))
      );

      loadExamCategories(match.params.id);
    }
  }, [loadExam, match.params.id, loadExamCategories]);

  if (loadingInitial) return <LoadComponent content="Carregando simulado" />;

  return (
    <Fragment>
      <Header as="h1" content={exam.name} />
      <Segment clearing>
        <Header as="h2" content="Descrição" />
        <Segment basic style={{ fontSize: "12pt" }}>
          <div>{exam.description}</div>
          <br />
          {exam.examiningBoardCode && (
            <div>
              <span style={{ fontWeight: "bold" }}>Banca:</span>{" "}
              <span style={{ paddingLeft: "28px" }}>
                {exam.examiningBoardCode}
              </span>
            </div>
          )}
          <div>
            <span style={{ fontWeight: "bold" }}>Total:</span>{" "}
            <span style={{ paddingLeft: "34px" }}>
              {exam.totalQuestions} questões
            </span>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>Duração:</span>{" "}
            <span style={{ paddingLeft: "9px" }}>
              {exam.durationTime} minutos
            </span>
          </div>
        </Segment>
        <Header as="h2" content="Observações" />
        <Segment>
          <List as="ul">
            <List.Item as="li">
              Antes de iniciar o simulado esteja em um ambiente tranquilo longe
              de interrupções já que não será possível pausar o simulado;
            </List.Item>
            <List.Item as="li">
              Durante o simulado será exibido um contador no canto superior
              direito informando o tempo restante ate o fim do simulado;
            </List.Item>
            <List.Item as="li">
              Não será possível retornar a um questão já respondida;
            </List.Item>
            <List.Item as="li">
              Caso esteja confortável clique no botão 'Iniciar Simulado' no
              canto inferior direito.
            </List.Item>
          </List>
        </Segment>
        <Button
          secondary
          floated="left"
          content="Voltar"
          as={Link}
          to="/aluno/simulados"
        />
        <Button
          positive
          floated="right"
          content="Iniciar simulado"
          onClick={() => startExam(match.params.id)}
        />
      </Segment>
    </Fragment>
  );
};

export default observer(ReleasedExamItem);
