import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { combineValidators, isRequired } from "revalidate";
import { Form, Button, Segment, Grid } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { ModuleFormValues } from "../../../app/models/module";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TextInput from "../../../app/common/form/TextInput";

const validate = combineValidators({
  name: isRequired({ message: "Nome é obrigatório" }),
  order: isRequired({ message: "Ordem é obrigatório" }),
});

const ModuleForm: React.FC<{ id: string; courseId: string }> = ({
  id,
  courseId,
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    loading,
    submitting,
    createModule,
    setPredicate,
    loadModules,
    loadModule,
    editModule,
  } = rootStore.moduleStore;
  const { closeModal } = rootStore.modalStore;

  useEffect(() => {
    if (id !== "") {
      loadModule(id).then((resp) => setModule(resp));
    }
  }, [id, loadModule]);

  const [module, setModule] = useState(new ModuleFormValues());

  const handleFinalFormSubmit = (values: any) => {
    const { ...module } = values;
    let newModule = {
      ...module,
      courseId: courseId,
    };
    if (id !== "") {
      editModule(newModule).then(() => {
        setPredicate(courseId);
        loadModules().then(() => closeModal());
      });
    } else {
      createModule(newModule).then(() => {
        setPredicate(courseId);
        loadModules().then(() => closeModal());
      });
    }
  };

  return (
    <Segment basic clearing>
      <h2>Novo Módulo</h2>
      <FinalForm
        validate={validate}
        initialValues={module}
        onSubmit={handleFinalFormSubmit}
        render={({ handleSubmit, invalid, pristine }) => (
          <Form onSubmit={handleSubmit} loading={loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={14}>
                  <Field
                    name="name"
                    placeholder="Nome"
                    title="Nome"
                    value={module.name}
                    component={TextInput}
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <Field
                    name="order"
                    placeholder="Ordem"
                    title="Ordem"
                    value={module.order.toString()}
                    component={TextInput}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Button
                    loading={submitting}
                    disabled={loading || invalid || pristine}
                    floated="right"
                    positive
                    type="submit"
                    content="Salvar"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      />
    </Segment>
  );
};

export default observer(ModuleForm);
