import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Card,
  Grid,
  Header,
  Rating,
  Segment,
  Statistic,
  Table,
} from "semantic-ui-react";
import LoadComponent from "../../../../../app/layout/LoadComponent";
import { RootStoreContext } from "../../../../../app/stores/rootStore";

interface DetailParams {
  examuserid: string;
}

const ExamResult: React.FC<RouteComponentProps<DetailParams>> = ({ match }) => {
  const rootStore = useContext(RootStoreContext);
  const { examUserResult, getResult, initialLoading } =
    rootStore.examUsersStore;

  useEffect(() => {
    getResult(match.params.examuserid);
  }, [getResult, match.params.examuserid]);

  if (initialLoading)
    return <LoadComponent content="Carregando resultado..." />;

  const getStarCount = (percentage: number) => {
    if (percentage <= 10) return 0;
    if (percentage >= 11 && percentage <= 30) return 1;
    if (percentage >= 31 && percentage <= 50) return 2;
    if (percentage >= 51 && percentage <= 70) return 3;
    if (percentage >= 71 && percentage <= 90) return 4;
    if (percentage >= 91) return 5;
  };

  const getResultMessageByPercentagem = (percentage: number) => {
    if (percentage <= 30)
      return (
        <Fragment>
          <p>
            Poxa… Você ficou um pouco longe do mínimo para ser aprovado! Está
            com dificuldade em qual matéria?
            <br />
            Reveja nossa aula de estratégia de estudos e deixa um comentário que
            vou te ajudar!
          </p>
        </Fragment>
      );

    if (percentage > 30 && percentage <= 59)
      return (
        <Fragment>
          <p>
            Você não passou mas chegou perto! Continue estudando e assistindo
            nossas aulas.
            <br />
            Quando tiver dúvidas, deixa um comentário na questão que irei
            responder!
          </p>
        </Fragment>
      );

    if (percentage > 59 && percentage <= 70)
      return (
        <Fragment>
          <p>
            Hm.. Sua nota foi acima do mínimo necessário para aprovação, mas não
            foi tão alta…
            <br />
            Pratica um pouco mais e tenta manter um nível de acertos acima de
            71%
          </p>
        </Fragment>
      );

    if (percentage > 70 && percentage <= 90)
      return (
        <Fragment>
          <p>
            Boa! Sua nota foi acima do mínimo necessário. Suas chances de
            aprovação são boas!
            <br />
            Me convida pra comemoração da aprovação, tá?
          </p>
        </Fragment>
      );

    if (percentage > 90)
      return (
        <Fragment>
          <p>
            Parabéns, seu índice de acerto foi acima de 90%. Você tem uma alta
            probabilidade de aprovaçāo!
            <br />
            Nāo esquece de me convidar pro Churrasco de comemoraçāo, tá?
          </p>
        </Fragment>
      );
  };

  if (window.innerWidth <= 760) {
    return (
      <Fragment>
        <h1>Resultado</h1>
        {examUserResult && (
          <Fragment>
            <Segment textAlign="center">
              <h2>{examUserResult.examName}</h2>
              <h3>
                Total Geral de Acertos:{" "}
                {Math.trunc(examUserResult.totalPercentageScore)}%
              </h3>
              <div>
                <Rating
                  icon="star"
                  defaultRating={5}
                  maxRating={getStarCount(examUserResult.totalPercentageScore)}
                  style={{ fontSize: "3rem" }}
                  disabled
                />
              </div>
              <div>
                <h3
                  style={{
                    color:
                      examUserResult.totalPercentageScore >= 60
                        ? "green"
                        : "red",
                  }}
                >
                  {getResultMessageByPercentagem(
                    examUserResult.totalPercentageScore
                  )}
                </h3>
              </div>
            </Segment>
            <h3>Resultado por Categoria</h3>
            {examUserResult.categoryResults.map((categoryResult) => {
              return (
                <Card centered fluid>
                  <Card.Content
                    header={categoryResult.categoryName}
                  ></Card.Content>
                  <Card.Content>
                    <Statistic.Group size="tiny" widths={2}>
                      <Statistic>
                        <Statistic.Value>
                          {categoryResult.scoreCount}
                        </Statistic.Value>
                        <Statistic.Label>Acertos</Statistic.Label>
                      </Statistic>
                      <Statistic>
                        <Statistic.Value>
                          {Math.trunc(categoryResult.scorePercentage)}%
                        </Statistic.Value>
                        <Statistic.Label>Aproveitamento</Statistic.Label>
                      </Statistic>
                    </Statistic.Group>
                  </Card.Content>
                  <Card.Content extra textAlign="right">
                    <Rating
                      icon="star"
                      defaultRating={5}
                      maxRating={getStarCount(categoryResult.scorePercentage)}
                      disabled
                    />
                  </Card.Content>
                </Card>
              );
            })}
          </Fragment>
        )}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <h1>Resultado</h1>
        {examUserResult && (
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                  <br />
                  <h2>{examUserResult.examName}</h2>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                  <h1>
                    Total Geral de Acertos:{" "}
                    {Math.trunc(examUserResult.totalPercentageScore)}%
                  </h1>
                  <div>
                    <Rating
                      icon="star"
                      defaultRating={5}
                      maxRating={getStarCount(
                        examUserResult.totalPercentageScore
                      )}
                      style={{ fontSize: "3rem" }}
                      disabled
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                  <h2
                    style={{
                      color:
                        examUserResult.totalPercentageScore >= 60
                          ? "green"
                          : "red",
                    }}
                  >
                    {getResultMessageByPercentagem(
                      examUserResult.totalPercentageScore
                    )}
                  </h2>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                  <br />
                  <h3>Resultado por Categoria</h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Table celled padded selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell singleLine>
                          Categoria / Subcategoria
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          Sucesso
                        </Table.HeaderCell>
                        <Table.HeaderCell>Eficácia</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {examUserResult.categoryResults.map((categoryResult) => {
                        return (
                          <Fragment key={categoryResult.categoryId}>
                            <Table.Row>
                              <Table.Cell width={12}>
                                <Header
                                  as="h2"
                                  content={categoryResult.categoryName}
                                />
                              </Table.Cell>
                              <Table.Cell textAlign="right" width={2}>
                                <strong>
                                  {Math.trunc(categoryResult.scorePercentage)}%
                                </strong>
                                <br />
                                <strong>
                                  {categoryResult.scoreCount} questões
                                </strong>
                              </Table.Cell>
                              <Table.Cell width={2}>
                                <Rating
                                  icon="star"
                                  defaultRating={5}
                                  maxRating={getStarCount(
                                    categoryResult.scorePercentage
                                  )}
                                  disabled
                                />
                              </Table.Cell>
                            </Table.Row>
                            {categoryResult.subcategoryResults.map(
                              (subcategoryResult) => (
                                <Table.Row
                                  key={subcategoryResult.subcategoryId}
                                >
                                  <Table.Cell>
                                    <span
                                      style={{
                                        marginLeft: "30px",
                                        fontSize: "13pt",
                                      }}
                                    >
                                      {subcategoryResult.subcategoryName}
                                    </span>
                                  </Table.Cell>
                                  <Table.Cell textAlign="right">
                                    {Math.trunc(
                                      subcategoryResult.scorePercentage
                                    )}
                                    % <br />
                                    {subcategoryResult.scoreCount} questões
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Rating
                                      icon="star"
                                      defaultRating={5}
                                      maxRating={getStarCount(
                                        subcategoryResult.scorePercentage
                                      )}
                                      disabled
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Fragment>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        )}
      </Fragment>
    );
  }
};

export default observer(ExamResult);
