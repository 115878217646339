import React, { useContext } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { Form, Button, Header } from "semantic-ui-react";
import TextInput from "../../app/common/form/TextInput";
import { RootStoreContext } from "../../app/stores/rootStore";
import { IUserFormValues } from "../../app/models/user";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import { observer } from "mobx-react-lite";

const validate = combineValidators({
  email: isRequired({ message: "Email é obrigatório" }),
});

const ForgotPasswordForm: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { forgotPassword, submitting } = rootStore.userStore;
  const { closeModal } = rootStore.modalStore;

  return (
    <FinalForm
      onSubmit={(values: IUserFormValues) => {
        forgotPassword(values.email)
          .catch((error) => ({
            [FORM_ERROR]: error,
          }))
          .finally(() => closeModal());
      }}
      validate={validate}
      render={({ handleSubmit, invalid, pristine, dirtySinceLastSubmit }) => (
        <Form onSubmit={handleSubmit} error>
          <Header
            as="h2"
            content="Esqueci minha senha"
            color="teal"
            textAlign="center"
          />
          <Field name="email" placeholder="Email" component={TextInput} />
          <Button
            disabled={
              (invalid && !dirtySinceLastSubmit) || pristine || submitting
            }
            loading={submitting}
            color="teal"
            content="Enviar email"
            fluid
          />
        </Form>
      )}
    />
  );
};

export default observer(ForgotPasswordForm);
