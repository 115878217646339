import { action, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import IExamUserAnswer from "../models/examUserAnswer";
import { RootStore } from "./rootStore";
import { history } from "../..";
import { format } from "date-fns";

export default class ExamUserAnswerStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loadingInitial = false;
  @observable examUserAnswer: IExamUserAnswer | null = null;
  @observable answerId: string = "";

  @action setSelectedAnswer = (id: string) => {
    this.answerId = id;
  };

  @action clearSelectedAnswer = () => {
    this.answerId = "";
  };

  @action getQuestion = async (examUserId: string) => {
    try {
      this.loadingInitial = true;
      let nowDate = new Date();
      let examQuestion = await agent.ExamUserAnswers.getQuestion(
        examUserId,
        format(nowDate, "yyyy-MM-dd HH:mm:ss")
      );
      if (!examQuestion) {
        await agent.ExamUsers.stopExam(
          examUserId,
          format(nowDate, "yyyy-MM-dd HH:mm:ss")
        );
        history.push(`/aluno/simulado/resultado/${examUserId}`);
      }
      runInAction(() => {
        this.examUserAnswer = examQuestion;
        this.loadingInitial = false;
      });
      return examQuestion;
    } catch (error) {
      runInAction(() => {
        this.loadingInitial = false;
      });

      throw error;
    }
  };

  @action getAnsweredQuestions = async (examUserId: string) => {
    try {
      this.loadingInitial = true;
      let answeredQuestions = await agent.ExamUserAnswers.getAnsweredQuestions(
        examUserId
      );
      runInAction(() => {
        this.loadingInitial = false;
      });
      return answeredQuestions;
    } catch (error) {
      runInAction(() => {
        this.loadingInitial = false;
      });

      throw error;
    }
  };

  @action setAnswered = async (id: string, answerId: string) => {
    try {
      let nowDate = new Date();
      await agent.ExamUserAnswers.setAnswered(id, answerId, format(nowDate, "yyyy-MM-dd HH:mm:ss"));
      runInAction(() => {
        this.examUserAnswer = null;
      });
    } catch (error) {
      toast.error("Erro ao salvar resposta");
    }
  };
}
