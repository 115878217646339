export interface IAnswerEnvelope {
  dataCollection: IAnswer[];
  dataCount: number;
}

export interface IAnswer {
  id: string;
  questionId: string;
  identifier: string;
  text: string;
  isCorrect: boolean;
}

export class AnswerFormValues {
  id?: string = undefined;
  questionId: string = "";
  identifier: string = "";
  text: string = "";
  isCorrect: boolean = false;

  constructor(init?: AnswerFormValues) {
    Object.assign(this, init);
  }
}