import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";
import LoadComponent from "../../app/layout/LoadComponent";
import { RootStoreContext } from "../../app/stores/rootStore";
import QuestionList from "./QuestionList";
import QuestionFilter from "./QuestionFilter";

const QuestionDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadCategoriesOptions } = rootStore.categoryStore;
  const { loadExaminingBoardsOptions } = rootStore.examiningBoardStore;
  const { loadQuestions, loadingInitial } = rootStore.questionStore;

  useEffect(() => {
    loadCategoriesOptions(false);
    loadExaminingBoardsOptions();
    loadQuestions();
  }, [loadCategoriesOptions, loadExaminingBoardsOptions, loadQuestions]);

  if (loadingInitial) return <LoadComponent content="Carregando questoes..." />;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={14}>
          <h1>Questões</h1>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            as={Link}
            to="/admin/novaquestao"
            active
            positive
            content="Nova"
            floated="right"
            style={{ marginTop: "12px" }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <QuestionFilter />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <QuestionList />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(QuestionDashboard);
