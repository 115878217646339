export interface ILessonCommentsEnvelope {
  dataCollection: ILessonComment[];
  dataCount: number;
}

export interface ILessonComment {
  id: string;
  lessonId: string;
  appUserId: string;
  lessonName: string;
  username: string;
  displayName: string;
  gender: string;
  text: string;
  postedDate: string;
  parentLessonCommentId: string;
  parentLessonCommentUsername: string;
  parentLessonCommentDisplayName: string;
  parentLessonCommentText: string;
  parentLessonCommentPostedDate: string;
  answeredByProfessor: boolean;
  isProfessorAnswer: boolean;
  replys: ILessonComment[];
}

export class LessonCommentFilter {
  sortBy: string = "";
}

export class LessonCommentFormValues {
  id?: string = undefined;
  lessonId: string = "";
  appUserId: string = "";
  displayName: string = "";
  text: string = "";
  postedDate?: Date = undefined;

  constructor(init?: LessonCommentFormValues) {
    Object.assign(this, init);
  }
}

export class LessonCommentReplyFormValues {
  id?: string = undefined;
  lessonId: string = "";
  appUserId: string = "";
  displayName: string = "";
  text: string = "";
  postedDate?: Date = undefined;
  parentCommentId: string = "";

  constructor(init?: LessonCommentReplyFormValues) {
    Object.assign(this, init);
  }
}