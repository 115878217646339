import { observer } from "mobx-react-lite";
import React from "react";
import { Icon, Table } from "semantic-ui-react";
import { IStudentProgressByExaminingBoard } from "../../../../app/models/studentProgress";

const StudentProgressByExaminingBoardListItem: React.FC<{
  item: IStudentProgressByExaminingBoard;
}> = ({ item }) => {
  return (
    <Table.Row>
      <Table.Cell>{item.examiningBoardName}</Table.Cell>
      <Table.Cell textAlign="center">{item.totalAnsweredQuestions}</Table.Cell>
      <Table.Cell textAlign="center">
        {item.totalWinAnsweredQuestions}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {item.totalFailAnsweredQuestions}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {Math.round(
          (item.totalWinAnsweredQuestions * 100) / item.totalAnsweredQuestions
        )}
        %{" "}
        {Math.round(
          (item.totalWinAnsweredQuestions * 100) / item.totalAnsweredQuestions
        ) >= 50 ? (
          <Icon name="thumbs up" color="green" />
        ) : (
          <Icon name="thumbs down" color="red" />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default observer(StudentProgressByExaminingBoardListItem);
