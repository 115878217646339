import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Segment } from "semantic-ui-react";
import TextInput from "../../../app/common/form/TextInput";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { CourseFormValues } from "../../../app/models/course";

const validate = combineValidators({
  name: isRequired({ message: "Nome é obrigatório" }),
});

const CourseForm: React.FC<{ id: string }> = ({ id }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadCourses,
    loadCourse,
    loading,
    submitting,
    createCourse,
    editCourse,
  } = rootStore.courseStore;
  const { closeModal } = rootStore.modalStore;

  useEffect(() => {
    if (id !== "") {
      loadCourse(id).then((resp) => setCourse(resp));
    }
  }, [id, loadCourse]);

  const [course, setCourse] = useState(new CourseFormValues());

  const handleFinalFormSubmit = (values: any) => {
    const { ...course } = values;
    let newCourse = {
      ...course,
    };
    if (id !== "") {
      editCourse(newCourse).then(() => {
        loadCourses().then(() => closeModal());
      });
    } else {
      createCourse(newCourse).then(() => {
        loadCourses().then(() => closeModal());
      });
    }
  };

  return (
    <Segment basic clearing>
      {id !== "" ? <h2>Editar Curso</h2> : <h2>Novo Curso</h2>}
      <FinalForm
        validate={validate}
        initialValues={course}
        onSubmit={handleFinalFormSubmit}
        render={({ handleSubmit, invalid, pristine }) => (
          <Form onSubmit={handleSubmit} loading={loading}>
            <Field
              title="Nome"
              name="name"
              placeholder="Nome"
              value={course.name}
              component={TextInput}
            />
            <Button
              loading={submitting}
              disabled={loading || invalid || pristine}
              floated="right"
              positive
              type="submit"
              content="Salvar"
            />
          </Form>
        )}
      />
    </Segment>
  );
};

export default observer(CourseForm);
