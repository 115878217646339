import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Container,
  Segment,
  Header,
  Icon,
  Image,
  Grid,
  Button,
} from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import LoginForm from "../../user/LoginForm";
import { history } from "../../..";

const HomePage = () => {
  const rootStore = useContext(RootStoreContext);
  const { isLoggedIn, user } = rootStore.userStore;

  const [cookiesMessageVisible, setCookiesMessageVisible] =
    useState<boolean>(true);

  useEffect(() => {
    setCookiesMessageVisible(window.localStorage.getItem("consent") !== "true");
  }, [setCookiesMessageVisible]);

  if (process.env.REACT_APP_UNDER_MAINTENANCE === "true") {
    history.push("/manutencao");
  }

  if (isLoggedIn && user) {
    if (user.role === "Admin" || user.role === "Manager") {
      history.push("/admin/dashboard");
    } else {
      history.push("/aluno/dashboard");
    }
  }

  return (
    <Segment vertical className="masthead">
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column width={9}>
              <div>
                <Header as="h1" content="CFC Academy" textAlign="left" />
              </div>
              <div>
                <Header
                  as="h2"
                  content="Passe de primeira nos Exames do CFC, CNAI, CNPC e Concursos. Entre na maior comunidade de contadores e seja o melhor em sua área."
                  textAlign="left"
                />
              </div>
              <br />
              <hr />
              <Fragment>
                <Header
                  as="h3"
                  content="Siga nossas redes sociais:"
                  style={{ marginBottom: "2px" }}
                />
                <a
                  href="https://www.instagram.com/cfcacademybr/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ paddingRight: "6px" }}
                >
                  <Image
                    src="/assets/socialmedia/instagram.svg"
                    style={{ width: "30px" }}
                    inline
                  />
                </a>
                <a
                  href="https://www.youtube.com/felipenunes"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ paddingRight: "6px" }}
                >
                  <Image
                    src="/assets/socialmedia/youtube.svg"
                    style={{ width: "32px" }}
                    inline
                  />
                </a>
                <a
                  href="https://www.tiktok.com/@cfcacademy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ paddingRight: "6px" }}
                >
                  <Image
                    src="/assets/socialmedia/tiktok.svg"
                    style={{ width: "30px" }}
                    inline
                  />
                </a>
              </Fragment>
            </Grid.Column>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={6}>
              <LoginForm />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {cookiesMessageVisible && (
          <div className="InitialCookiesMessage">
            Este site faz o uso de cookies e ao continuar navegando você
            consente da utilzação dos mesmos.{" "}
            <Button
              style={{ marginLeft: "10px" }}
              content="OK"
              circular
              primary
              color="google plus"
              onClick={() => {
                window.localStorage.setItem("consent", "true");
                setCookiesMessageVisible(false);
              }}
            />
          </div>
        )}
        <div className="InitialFooter">
          Copyright@{new Date().getFullYear()} CFC Academy - Todos os direitos
          reservados. Desenvolvido por{" "}
          <a
            href="https://www.linkedin.com/in/igorabilio/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Igor Santos{"  "}
            <Icon name="linkedin" />
          </a>
        </div>
      </Container>
    </Segment>
  );
};

export default HomePage;
