import React, { useContext, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import CategoryChart from "./CategoryChart";

const Dashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.userStore;
  const { loadCategoriesPerQuestion } = rootStore.categoryStore;

  useEffect(() => {
    loadCategoriesPerQuestion();
  }, [loadCategoriesPerQuestion]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={10}>
          <h1>Página Inicial</h1>
        </Grid.Column>
        <Grid.Column width={6} textAlign="right" verticalAlign="bottom">
          <h2>Bem vindo {user!.displayName}</h2>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <CategoryChart />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(Dashboard);
