import React, { Fragment, useContext } from "react";
import { Table, Pagination, Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../app/stores/rootStore";
import SubcategoryListItem from "./SubcategoryListItem";

const SubcategoryList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    subcategoriesByName,
    setPage,
    page,
    totalPages,
    loadSubcategories,
  } = rootStore.subcategoryStore;

  const onPageChange = (e: any, pageInfo: any) => {
    setPage(pageInfo.activePage);
    loadSubcategories();
  };

  return (
    <Fragment>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Sigla</Table.HeaderCell>
            <Table.HeaderCell width={4}>Subcategoria</Table.HeaderCell>
            <Table.HeaderCell width={5}>Descriçāo</Table.HeaderCell>
            <Table.HeaderCell width={4}>Categoria</Table.HeaderCell>
            <Table.HeaderCell width={2}>Qtd. Questões</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {subcategoriesByName.map((subcategory) => (
            <SubcategoryListItem
              key={subcategory.id}
              subcategory={subcategory}
            />
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="5">
              <Segment basic clearing floated="right">
                <Pagination
                  defaultActivePage={page}
                  onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
                  totalPages={totalPages}
                />
              </Segment>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Fragment>
  );
};

export default observer(SubcategoryList);
