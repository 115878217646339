import React, { Fragment, useContext } from "react";
import { Table, Segment, Pagination } from "semantic-ui-react";
import CategoryListItem from "./CategoryListItem";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/rootStore";

const CategoryList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    categoriesByName,
    loadCategories,
    setPage,
    page,
    totalPages,
  } = rootStore.categoryStore;

  const onPageChange = (e: any, pageInfo: any) => {
    setPage(pageInfo.activePage);
    loadCategories();
  };

  return (
    <Fragment>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Sigla</Table.HeaderCell>
            <Table.HeaderCell width={5}>Categoria</Table.HeaderCell>
            <Table.HeaderCell width={8}>Descriçāo</Table.HeaderCell>
            <Table.HeaderCell width={2}>Qtd. Questões</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {categoriesByName.map((category) => (
            <CategoryListItem key={category.id} category={category} />
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4">
              <Segment basic clearing floated="right">
                <Pagination
                  defaultActivePage={page}
                  onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
                  totalPages={totalPages}
                />
              </Segment>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Fragment>
  );
};

export default observer(CategoryList);
