import React, { useContext, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";

am4core.useTheme(am4themes_animated);

const CategoryChart: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { categoriesPerQuestion } = rootStore.categoryStore;

  useEffect(() => {
    let catChart = am4core.create("catchartdiv", am4charts.PieChart);
    catChart.data = [];

    categoriesPerQuestion.map((category) =>
      catChart.data.push({
        category: `${category.name} (${category.questionCount})`,
        questions: category.questionCount,
      })
    );

    let pieCatSeries = catChart.series.push(new am4charts.PieSeries());
    pieCatSeries.dataFields.value = "questions";
    pieCatSeries.dataFields.category = "category";

    return () => {
      catChart.dispose();
    };
  }, [categoriesPerQuestion]);

  return (
    <Segment>
      <h3>Questões por categoria</h3>
      <div id="catchartdiv" style={{ width: "100%", height: "500px" }} />
    </Segment>
  );
};

export default observer(CategoryChart);
