import { RootStore } from "./rootStore";
import { observable, action, runInAction, computed, reaction } from "mobx";
import {
  ExaminingBoardFilter,
  IExaminingBoard,
} from "../models/examiningBoard";
import agent from "../api/agent";
import { toast } from "react-toastify";
import { history } from "../..";
import { SyntheticEvent } from "react";

const LIMIT = 10;

export default class ExaminingBoardStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.examiningBoardRegistry.clear();
        this.loadExaminingBoards();
      }
    );
  }

  @observable examiningBoardRegistry = new Map();
  @observable examiningBoard: IExaminingBoard | null = null;
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";
  @observable loading = false;
  @observable examiningBoardCount = 0;
  @observable page = 1;
  @observable predicate = new Map();
  @observable examiningBoardFilter = new ExaminingBoardFilter();

  @action setPredicate = () => {
    this.predicate.clear();

    if (this.examiningBoardFilter.code) {
      this.predicate.set("code", this.examiningBoardFilter!.code);
    }

    if (this.examiningBoardFilter.name) {
      this.predicate.set("name", this.examiningBoardFilter!.name);
    }

    if (this.examiningBoardFilter.sortBy) {
      this.predicate.set("sortby", this.examiningBoardFilter!.sortBy);
    }
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page > 1 ? (this.page - 1) * LIMIT : 0}`);
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });

    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.examiningBoardCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @computed get examiningBoardsByName() {
    const examiningBoards = Array.from(this.examiningBoardRegistry.values());
    return examiningBoards;
  }

  @computed get examiningBoardsOptions() {
    const examiningBoards = Array.from(this.examiningBoardRegistry.values());
    return examiningBoards.map((x) => ({ key: x.id, value: x.id, text: x.name }));
  }

  @action loadExaminingBoards = async () => {
    this.loadingInitial = true;
    try {
      this.examiningBoardRegistry.clear();
      const examiningBoardsEnvelope = await agent.ExaminingBoards.list(
        this.axiosParams
      );
      const { dataCollection, dataCount } = examiningBoardsEnvelope;
      runInAction("loading examining boards", () => {
        dataCollection.forEach((category) => {
          this.examiningBoardRegistry.set(category.id, category);
        });
        this.examiningBoardCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load examining boards error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar bancas");
    }
  };

  getExaminingBoard = (id: string) => {
    return this.examiningBoardRegistry.get(id);
  };

  @action loadExaminingBoard = async (id: string) => {
    let examiningBoard = this.getExaminingBoard(id);
    if (examiningBoard) {
      this.examiningBoard = examiningBoard;
      return examiningBoard;
    } else {
      this.loadingInitial = true;
      try {
        examiningBoard = await agent.ExaminingBoards.details(id);
        runInAction("loading examiningBoard", () => {
          this.examiningBoard = examiningBoard;
          this.examiningBoardRegistry.set(examiningBoard.id, examiningBoard);
          this.loadingInitial = false;
        });
        return examiningBoard;
      } catch (error) {
        runInAction("load examiningBoard error", () => {
          this.loadingInitial = false;
        });
        toast.error("Erro ao carregar banca");
      }
    }
  };

  @action loadExaminingBoardsForSelect = async () => {
    try {
      const params = new URLSearchParams();
      params.append("limit", "1000");
      const examiningBoardsEnvelope = await agent.ExaminingBoards.list(params);
      const { dataCollection } = examiningBoardsEnvelope;
      return dataCollection;
    } catch (error) {
    }
  };

  @action loadExaminingBoardsOptions = async () => {
    try {
      this.examiningBoardRegistry.clear();
      const examiningBoardsOptions = await agent.ExaminingBoards.listForSelect();
      runInAction(() => {
        examiningBoardsOptions.forEach((examiningBoard) => {
          this.examiningBoardRegistry.set(examiningBoard.id, examiningBoard);
        });
      });
    } catch (error) {
      toast.error("Erro ao carregar bancas");
    }
  };

  @action createExaminingBoard = async (examiningBoard: IExaminingBoard) => {
    this.submitting = true;
    try {
      await agent.ExaminingBoards.create(examiningBoard);
      runInAction("creating examiningBoard", () => {
        this.submitting = false;
      });
      history.push("/admin/bancas/");
    } catch (error) {
      runInAction("create examiningBoard error", () => {
        this.submitting = false;
      });
      toast.error("Erro ao criar banca");
    }
  };

  @action editExaminingBoard = async (examiningBoard: IExaminingBoard) => {
    this.submitting = true;
    try {
      await agent.ExaminingBoards.update(examiningBoard);
      runInAction("editing examiningBoard", () => {
        this.examiningBoardRegistry.set(examiningBoard.id, examiningBoard);
        this.examiningBoard = examiningBoard;
        this.submitting = false;
      });
      history.push("/admin/bancas/");
    } catch (error) {
      runInAction("edit examiningBoard error", () => {
        this.submitting = false;
      });
      toast.error("Problem submitting data");
    }
  };

  @action deleteExaminingBoard = async (
    event: SyntheticEvent<HTMLButtonElement>,
    id: string
  ) => {
    this.submitting = true;
    this.target = event.currentTarget.name;
    try {
      await agent.ExaminingBoards.delete(id);
      runInAction("deleting examiningBoard", () => {
        this.examiningBoardRegistry.delete(id);
        this.submitting = false;
        this.target = "";
      });
      history.push("/admin/bancas");
    } catch (error) {
      runInAction("delete examiningBoard error", () => {
        this.submitting = false;
        this.target = "";
      });
    }
  };
}
