import React, { Fragment, useContext } from "react";
import { Comment, Icon } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import CommentItem from "./CommentItem";
import { Link } from "react-router-dom";

const Comments: React.FC<{ questionId: string }> = ({ questionId }) => {
  const rootStore = useContext(RootStoreContext);
  const { question } = rootStore.questionStore;
  const { comments } = question!;

  return (
    <Fragment>
      <Comment.Group>
        {question &&
          question.comments &&
          comments.map(
            (comment) =>
              comment.parentCommentId === null && (
                <Comment key={comment.id}>
                  <Comment.Avatar
                    as="a"
                    src={
                      comment.isProfessorAnswer
                        ? "/assets/professor.jpg"
                        : comment.gender === "F"
                        ? "/assets/female.jpg"
                        : "/assets/male.jpg"
                    }
                  />
                  <Comment.Content>
                    <Comment.Author>
                      {comment.isProfessorAnswer ? (
                        <Fragment>
                          Professor <Icon name="graduation cap" />
                        </Fragment>
                      ) : (
                        <Fragment>
                          {comment.displayName} (
                          <Link to={`/admin/usuarios/${comment.username}`}>
                            {comment.username}
                          </Link>
                          )
                        </Fragment>
                      )}
                    </Comment.Author>
                    <Comment.Text>
                      {comment.isProfessorAnswer ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: comment.text }}
                        />
                      ) : (
                        <div>
                          {comment.text.split("\n").map((i, key) => {
                            return <p key={key}>{i}</p>;
                          })}
                        </div>
                      )}
                    </Comment.Text>
                    <Comment.Metadata>
                      <div>{comment.postedDate}</div>
                    </Comment.Metadata>
                  </Comment.Content>
                  {comment.replys.length > 0 && (
                    <Comment.Group>
                      {comment.replys.map((reply) => (
                        <Fragment>
                          <CommentItem commentItem={reply} key={reply.id} />
                          {reply.replys.length > 0 && (
                            <Comment.Group>
                              {reply.replys.map((rereply) => (
                                <CommentItem
                                  commentItem={rereply}
                                  key={rereply.id}
                                />
                              ))}
                            </Comment.Group>
                          )}
                        </Fragment>
                      ))}
                    </Comment.Group>
                  )}
                </Comment>
              )
          )}
      </Comment.Group>
    </Fragment>
  );
};

export default observer(Comments);
