import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  Comment,
  Form,
  Grid,
  Label,
  Segment,
} from "semantic-ui-react";
import { Form as FinalForm } from "react-final-form";
import { CommentReplyFormValues, IComment } from "../../app/models/comment";
import { RootStoreContext } from "../../app/stores/rootStore";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";

const CommentListItem: React.FC<{ comment: IComment }> = ({ comment }) => {
  const rootStore = useContext(RootStoreContext);
  const { selectComment, removeSelectedComment, loadComments } =
    rootStore.commentStore;
  const { commentReplyCreate } = rootStore.questionStore;

  const [checked, setChecked] = useState(false);
  const [replyVisible, setReplyVisible] = useState(false);
  const [newComment] = useState(new CommentReplyFormValues());
  const [value, setValue] = useState("");

  const onCheckboxChange = (comment: IComment) => {
    if (checked) {
      removeSelectedComment(comment.id);
    } else {
      selectComment(comment);
    }
    setChecked(!checked);
  };

  const handleFinalFormSubmit = (values: any) => {
    if (value === "" || value === undefined) {
      toast.error("Comentário é obrigatório");
    } else {
      commentReplyCreate(comment!.questionId, comment!.id, value).then(() =>
        loadComments()
      );
      setValue("");
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        {
          color: ["red", "blue", "green", "orange", "violet", "yellow", "grey"],
        },
        {
          background: [
            "red",
            "blue",
            "green",
            "orange",
            "violet",
            "yellow",
            "grey",
          ],
        },
      ],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "color",
    "background",
    "list",
    "bullet",
    "link",
  ];

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={1}>
          <Checkbox
            style={{ paddingTop: "3px" }}
            onChange={() => onCheckboxChange(comment)}
            checked={checked}
          />
        </Grid.Column>
        <Grid.Column width={15}>
          <Comment>
            <Comment.Avatar
              src={
                comment.isProfessorAnswer
                  ? "/assets/professor.jpg"
                  : comment.gender === "F"
                  ? "/assets/female.jpg"
                  : "/assets/male.jpg"
              }
            />
            <Comment.Content>
              <div>
                <strong>{comment.displayName}</strong> (
                <Link to={`/admin/usuarios/${comment.username}`}>
                  {comment.username}
                </Link>
                ) comentou em{" "}
                <strong>
                  <Link
                    target="_blank"
                    to={`/admin/questoes/${comment.questionId}`}
                  >
                    {comment.questionCode}
                  </Link>
                </strong>{" "}
                {comment.answeredByProfessor && (
                  <Label as="a" content="Respondido" icon="check" />
                )}
              </div>
              <Comment.Metadata>
                <div>{comment.postedDate}</div>
              </Comment.Metadata>
              <Comment.Text>
                {!comment.isProfessorAnswer ? (
                  <div>
                    {comment.text.split("\n").map((i, key) => {
                      return <p key={key}>{i}</p>;
                    })}
                  </div>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: comment.text }}></div>
                )}
              </Comment.Text>
              <Comment.Actions>
                <Link to="#" onClick={() => setReplyVisible(!replyVisible)}>
                  Responder
                </Link>
              </Comment.Actions>
            </Comment.Content>
          </Comment>
          {replyVisible && (
            <FinalForm
              initialValues={newComment}
              onSubmit={handleFinalFormSubmit}
              render={({ handleSubmit }) => (
                <Form reply onSubmit={handleSubmit}>
                  <br />
                  <div className="text-editor">
                    <ReactQuill
                      theme="snow"
                      value={value}
                      onChange={setValue}
                      modules={modules}
                      formats={formats}
                    />
                  </div>
                  <br />
                  <Button
                    content="Responder"
                    labelPosition="left"
                    icon="edit"
                    primary
                  />
                </Form>
              )}
            />
          )}
          {comment.parentCommentId && (
            <Segment secondary>
              <h4>Comentário citado:</h4>
              <div>
                <strong>{comment.parentCommentDisplayName}</strong> (
                {comment.parentCommentUsername}) comentou em{" "}
                {comment.parentCommentPostedDate}:
                {!comment.isProfessorAnswer ? (
                  <div>
                    {comment.parentCommentText.split("\n").map((i, key) => {
                      return <p key={key}>{i}</p>;
                    })}
                  </div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: comment.parentCommentText,
                    }}
                  ></div>
                )}
              </div>
            </Segment>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(CommentListItem);
