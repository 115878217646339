import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Icon, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";

const QuestionRate: React.FC<{ questionId: string }> = ({ questionId }) => {
  const rootContext = useContext(RootStoreContext);
  const { rate, getRate, userRate } = rootContext.questionStore;

  useEffect(() => {
    getRate(questionId);
  }, [getRate, questionId]);

  return (
    <Segment basic style={{ padding: "0 0 0 0" }} textAlign="right">
      <span style={{ paddingRight: "10px" }}>Classifique a questāo:</span>
      <Icon
        name="thermometer empty"
        size="large"
        title="Fácil"
        color="green"
        onClick={() => rate(questionId, 0)}
        disabled={userRate ? true : false}
      />
      <Icon
        name="thermometer half"
        size="large"
        title="Médio"
        color="blue"
        onClick={() => rate(questionId, 1)}
        disabled={userRate ? true : false}
      />
      <Icon
        name="thermometer full"
        size="large"
        title="Difícil"
        color="red"
        onClick={() => rate(questionId, 2)}
        disabled={userRate ? true : false}
      />
    </Segment>
  );
};

export default observer(QuestionRate);
