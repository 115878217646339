import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Pagination, Segment, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import CourseListItem from "./CourseListItem";

const CourseList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { courseByName, loadCourses, setPage, page, totalPages } =
    rootStore.courseStore;

  const onPageChange = (e: any, pageInfo: any) => {
    setPage(pageInfo.activePage);
    loadCourses();
  };

  return (
    <Fragment>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={12}>Curso</Table.HeaderCell>
            <Table.HeaderCell width={4}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {courseByName.map((course) => (
            <CourseListItem key={course.id} course={course} />
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="2">
              <Segment basic clearing floated="right">
                <Pagination
                  defaultActivePage={page}
                  onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
                  totalPages={totalPages}
                />
              </Segment>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Fragment>
  );
};

export default observer(CourseList);
