import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import {
  Button,
  Container,
  Form,
  Icon,
  Image,
  Label,
  Menu,
  Message,
  Segment,
  Tab,
} from "semantic-ui-react";
import AnsweredQuestionProfComment from "./AnsweredQuestionProfComment";
import AnsweredQuestionComments from "./AnsweredQuestionComments";
import IExamUserAnswer, {
  IQuestionAnswer,
} from "../../../../../app/models/examUserAnswer";

const AnsweredQuestion: React.FC<{ question: IExamUserAnswer }> = ({
  question,
}) => {
  const panes = [
    {
      menuItem: (
        <Menu.Item name="comentarioProfessor" key="comentarioProfessor">
          <Icon name="graduation cap" /> Comentário do Professor
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <AnsweredQuestionProfComment question={question} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item name="comentarios" key="comentarios">
          <Icon name="comments outline" /> Comentários
          <Label color="teal">{question ? question.comments.length : 0}</Label>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <AnsweredQuestionComments comments={question.comments} />
        </Tab.Pane>
      ),
    },
  ];

  const handlerCopy = (e: any) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();

    alert("Não é permitido copiar");
  };

  return (
    <Segment key={question.id}>
      <Segment padded>
        <Label attached="top" size="big">
          <span
            style={{
              paddingRight: "10px",
              fontStyle: "italic",
              fontSize: "11pt",
              fontWeight: 100,
            }}
          >
            {question!.questionCode} - {question!.subcategoryName} / {question!.examiningBoardCode}
          </span>
        </Label>
        <Container
          fluid
          textAlign="left"
          style={{ fontSize: "11pt", paddingTop: "20px" }}
        >
          {question!.questionText.split("\n").map((i, key) => {
            return (
              <p onCopy={handlerCopy} key={key}>
                {i}
              </p>
            );
          })}
          {question!.images.length > 0 && (
            <Fragment>
              <Image src={question!.images[0].url} size="big" />
            </Fragment>
          )}
        </Container>
        <Segment clearing basic style={{ padding: "20px 0 0 0" }}>
          {question.questionAnswers.map((answer: IQuestionAnswer) => (
            <Segment key={answer.id}>
              <Form>
                <Form.Group inline>
                  <Form.Field>
                    <Button circular content={answer.identifier} disabled />
                  </Form.Field>
                  <Form.Field>
                    <span onCopy={handlerCopy}>{answer.text}</span>
                  </Form.Field>
                  {!answer.isCorrect &&
                    answer.id === question.selectedAnswer && (
                      <Form.Field>
                        <Segment basic floated="right" clearing>
                          <Icon name="times" color="red" size="large" />
                        </Segment>
                      </Form.Field>
                    )}
                  {answer.isCorrect && (
                    <Form.Field>
                      <Segment basic floated="right" clearing>
                        <Icon name="check" color="green" size="large" />
                      </Segment>
                    </Form.Field>
                  )}
                </Form.Group>
              </Form>
            </Segment>
          ))}
        </Segment>
        {question &&
          question.selectedAnswerIdentifier !==
            question.correctAnswerIdentifier && (
            <Message warning>
              <Message.Header>
                Você marcou a resposta {question.selectedAnswerIdentifier},
                quando alternativa correta era a{" "}
                {question.correctAnswerIdentifier}.
              </Message.Header>
            </Message>
          )}
        <br />
        <Tab menu={{ pointing: true }} panes={panes} />
      </Segment>
    </Segment>
  );
};

export default observer(AnsweredQuestion);
