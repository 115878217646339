import { action, computed, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { IExam } from "../models/exam";
import { RootStore } from "./rootStore";
import { history } from "../..";
import { SyntheticEvent } from "react";

const LIMIT = 10;

export default class ExamStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable examRegistry = new Map();
  @observable submitting = false;
  @observable exam: IExam | null = null;
  @observable loadingInitial = false;
  @observable examCount = 0;
  @observable page = 1;
  @observable target = "";

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page > 1 ? (this.page - 1) * LIMIT : 0}`);

    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.examCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @computed get examsByName() {
    const exams = Array.from(this.examRegistry.values());
    return exams;
  }

  @action loadExams = async () => {
    this.loadingInitial = true;
    try {
      this.examRegistry.clear();
      const examsEnvelope = await agent.Exams.list(this.axiosParams);
      const { dataCollection, dataCount } = examsEnvelope;
      runInAction("loading exams", () => {
        dataCollection.forEach((exam) => {
          this.examRegistry.set(exam.id, exam);
        });
        this.examCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load exams error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar simulados");
    }
  };

  @action loadReleasedExams = async () => {
    this.loadingInitial = true;
    try {
      this.examRegistry.clear();
      const examsEnvelope = await agent.Exams.listReleased(this.axiosParams);
      const { dataCollection, dataCount } = examsEnvelope;
      runInAction("loading exams", () => {
        dataCollection.forEach((exam) => {
          this.examRegistry.set(exam.id, exam);
        });
        this.examCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load exams error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar simulados");
    }
  };

  @action loadExam = async (id: string) => {
    this.loadingInitial = true;
    let exam = this.getExam(id);
    if (exam) {
      this.exam = exam;
      this.loadingInitial = false;
      return exam;
    } else {
      try {
        exam = await agent.Exams.details(id);
        runInAction("loading exam", () => {
          this.exam = exam;
          this.examRegistry.set(exam.id, exam);
          this.loadingInitial = false;
        });
        return exam;
      } catch (error) {
        runInAction("load exam error", () => {
          this.loadingInitial = false;
        });
        toast.error("Erro ao carregar simulado");
      }
    }
  };

  @action clearExam = () => {
    this.exam = null;
  };

  getExam = (id: string) => {
    return this.examRegistry.get(id);
  };

  @action createExam = async (exam: IExam) => {
    this.submitting = true;
    this.exam = null;
    try {
      exam.durationTime = Number(exam.durationTime);
      exam.totalQuestions = Number(exam.totalQuestions);

      let newExam = await agent.Exams.create(exam);

      runInAction("creating exam", () => {
        this.exam = newExam;
        this.submitting = false;
      });
      history.push(`/admin/simulados/${newExam.id}`);
    } catch (error) {
      runInAction("create exam error", () => {
        this.submitting = false;
      });
      toast.error(error.data.errors.code);
    }
  };

  @action editExam = async (exam: IExam) => {
    this.submitting = true;
    try {
      exam.durationTime = Number(exam.durationTime);
      exam.totalQuestions = Number(exam.totalQuestions);

      await agent.Exams.update(exam);
      runInAction("editing exam", () => {
        this.examRegistry.set(exam.id, exam);
        this.exam = exam;
        this.submitting = false;
      });
      history.push("/admin/simulados/");
    } catch (error) {
      runInAction("edit exam error", () => {
        this.submitting = false;
      });
      toast.error("Problem submitting data");
    }
  };

  @action deleteExam = async (
    event: SyntheticEvent<HTMLButtonElement>,
    id: string
  ) => {
    this.submitting = true;
    this.target = event.currentTarget.name;
    try {
      await agent.Exams.delete(id);
      runInAction("deleting exam", () => {
        this.examRegistry.delete(id);
        this.submitting = false;
        this.target = "";
      });
      history.push("/admin/simulados");
    } catch (error) {
      runInAction("delete exam error", () => {
        this.submitting = false;
        this.target = "";
      });
    }
  };
}
