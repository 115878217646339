import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Icon, Popup, Segment, Statistic } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";

const ComparizonTile: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { studentDashboard } = rootStore.studentDashboardStore;

  return (
    <Segment clearing style={{ height: "100%" }}>
      <h3>
        Comparativo da evolução{" "}
        <Popup
          content="Comparação de sua porcentagem de acertos (sucesso) com a porcentagem de acertos de todos os outros usuários da plataforma. Mantenha sua taxa de sucesso acima de 50%."
          basic
          trigger={<Icon name="info circle" />}
          position="bottom right"
        />
      </h3>
      <Segment basic clearing floated="right" style={{ marginTop: "40px" }}>
        <Statistic.Group>
          <Statistic floated="right">
            <Statistic.Value>
              {studentDashboard && studentDashboard.mySuccessPercentge}%
            </Statistic.Value>
            <Statistic.Label>Meu</Statistic.Label>
          </Statistic>
          <Statistic floated="right">
            <Statistic.Value>|</Statistic.Value>
          </Statistic>
          <Statistic floated="right">
            <Statistic.Value>
              {studentDashboard && studentDashboard.studentsSuccessPercentage}%
            </Statistic.Value>
            <Statistic.Label>Alunos</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Segment>
    </Segment>
  );
};

export default observer(ComparizonTile);
