export interface IUser {
  username: string;
  displayName: string;
  token: string;
  image?: string;
  role: string;
  hasTempPassword: boolean;
  activePlanName: string;
}

export interface IStudentEnvelope {
  dataCollection: IStudentUser[];
  dataCount: number;
}

export interface IStudentUser {
  id: string;
  displayName: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
}

export class StudentFilter {
  onlyActive: boolean = true;
  name?: string = "";
  paidStatus: string = "todos";
}

export interface IUserFormValues {
  email: string;
  oldPassword: string
  password: string;
  confirmPassword?: string;
  displayName?: string;
  username?: string;
  gender: string;
  cpf: string;
  terms?: boolean;
}

export class UserFormValues {
  email: string = "";
  oldPassword: string = "";
  password: string = "";
  confirmPassword: string = "";
  displayName: string = "";
  username: string = "";
  gender: string = "";
  cpf: string = "";
  birthDate: string = "";

  constructor(init?: UserFormValues) {
    Object.assign(this, init);
  }
}

export interface IUserListReport {
  firstName: string;
  lastName: string;
  apelido: string;
  username: string;
  cpf: string;
  email: string;
  birthDate: string
  birthYear: string;
  age: string;
  gender: string;
  phoneNumber: string;
  status: string;
  createdDate: string;
  lastLoginDate: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  cep: string;
  totalMoneySpent: string;
}