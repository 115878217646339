export interface IStudentProgressByCategoryEnvelope {
  dataCollection: IStudentProgressByCategory[];
  dataCount: number;
}

export interface IStudentProgressBySubcategoryEnvelope {
  dataCollection: IStudentProgressBySubcategory[];
  dataCount: number;
}

export interface IStudentProgressByExaminingBoardEnvelope {
  dataCollection: IStudentProgressByExaminingBoard[];
  dataCount: number;
}

export interface IStudentProgressByCategory extends IStudentProgressBase {
  categoryId: string;
  categoryName: string;
}

export interface IStudentProgressBySubcategory extends IStudentProgressBase {
  subcategoryId: string;
  subcategoryName: string;
}

export interface IStudentProgressByExaminingBoard extends IStudentProgressBase {
  examiningBoardId: string;
  examiningBoardName: string;
}

export interface IStudentProgressBase {
  totalAnsweredQuestions: number;
  totalWinAnsweredQuestions: number;
  totalFailAnsweredQuestions: number;
}

export class StudentProgressBySubcategoryFilter {
  categoryId?: string = "";
}
