import { FORM_ERROR } from "final-form";
import React, { useContext } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired, matchesField } from "revalidate";
import {
  Form,
  Button,
  Header,
  Segment,
  List,
} from "semantic-ui-react";
import ErrorMessage from "../../app/common/form/ErrorMessage";
import TextInput from "../../app/common/form/TextInput";
import { IUserFormValues } from "../../app/models/user";
import { RootStoreContext } from "../../app/stores/rootStore";

const validate = combineValidators({
  oldPassword: isRequired({ message: "Senha é obrigatória" }),
  password: isRequired({ message: "Senha é obrigatória" }),
  confirmPassword: matchesField(
    "password",
    "password"
  )({ message: "Confirmação de senha errada" }),
});

const ChangePasswordForm: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { changePassword } = rootStore.userStore;

  return (
    <FinalForm
      onSubmit={(values: IUserFormValues) =>
        changePassword(values).catch((error) => ({
          [FORM_ERROR]: error,
        }))
      }
      validate={validate}
      render={({
        handleSubmit,
        submitting,
        submitError,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (
        <Form onSubmit={handleSubmit} error>
          <Header
            as="h1"
            content="Alterar Senha"
            textAlign="center"
          />
          {submitError && !dirtySinceLastSubmit && (
            <ErrorMessage error={submitError} />
          )}
          <Field
            name="oldPassword"
            title="Senha Antiga"
            placeholder="Senha Antiga"
            component={TextInput}
            type="password"
          />
          <Segment style={{ fontSize: "9pt" }}>
            <h4>Sua nova senha deve conter:</h4>
            <List as="ul">
              <List.Item as="li">Mínimo 6 caracteres</List.Item>
              <List.Item as="li">Mínimo 1 letra maiúscula</List.Item>
              <List.Item as="li">Mínimo 1 letra minúscula</List.Item>
              <List.Item as="li">Mínimo 1 número</List.Item>
              <List.Item as="li">Mínimo 1 caracter especial</List.Item>
            </List>
          </Segment>
          <Field
            name="password"
            title="Senha Nova"
            placeholder="Senha Nova"
            component={TextInput}
            type="password"
          />
          <Field
            name="confirmPassword"
            title="Confirmar Senha Nova"
            placeholder="Confirmar Senha Nova"
            component={TextInput}
            type="password"
          />
          <Button
            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
            loading={submitting}
            content="Alterar"
            fluid
            positive
          />
        </Form>
      )}
    />
  );
};

export default ChangePasswordForm;
