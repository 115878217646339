import { RootStore } from "./rootStore";
import { observable, action } from "mobx";

export default class ModalStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable.shallow modal = {
    open: false,
    body: null,
    size: undefined,
    basic: false,
    closeIcon: true,
  };

  @action openModal = (
    content: any,
    size: any,
    basic: boolean,
    closeIcon: boolean
  ) => {
    this.modal.open = true;
    this.modal.body = content;
    this.modal.size = size;
    this.modal.basic = basic;
    this.modal.closeIcon = closeIcon;
  };

  @action closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
  };
}
