import React from "react";
import { FieldRenderProps } from "react-final-form";
import { Form, FormFieldProps, Label } from "semantic-ui-react";

interface IProps
  extends FieldRenderProps<string, HTMLElement>,
    FormFieldProps {}

const CheckboxInput: React.FC<IProps> = ({
  input,
  width,
  type,
  placeholder,
  title,
  disabled,
  contentText,
  meta: { touched, error },
}) => {
  return (
    <Form.Field error={touched && !!error} type={type} width={width}>
      {title && <label>{title}</label>}
      <div className="ui checkbox">
        <input
          type="checkbox"
          {...input}
          placeholder={placeholder}
          disabled={disabled && true}
        />
        <label>{contentText}</label>
      </div>
      {touched && error && (
        <Label basic color="red">
          {error}
        </Label>
      )}
    </Form.Field>
  );
};

export default CheckboxInput;
