import UserStore from "./userStore";
import { createContext } from "react";
import { configure } from "mobx";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import BreadcrumbStore from "./breadcrumbStore";
import CategoryStore from "./categoryStore";
import ProfileStore from "./profileStore";
import SubcategoryStore from "./subcategoryStore";
import QuestionStore from "./questionStore";
import AnswerStore from "./answerStore";
import CommentStore from "./commentStore";
import StudentDashboardStore from "./studentDashboardStore";
import StudentProgressByCategoryStore from "./studentProgressByCategoryStore";
import StudentProgressBySubcategoryStore from "./studentProgressBySubcategoryStore";
import ExamStore from "./examStore";
import ExamCategoryStore from "./examCategoryStore";
import ExamSubcategoryStore from "./examSubcategoryStore";
import ExamUsersStore from "./examUsersStore";
import ExamUserAnswerStore from "./examUserAnswerStore";
import CheckoutStore from "./checkoutStore";
import PlanStore from "./planStore";
import VoucherStore from "./voucherStore";
import UserPlanStore from "./userPlanStore";
import CourseStore from "./courseStore";
import ModuleStore from "./moduleStore";
import LessonStore from "./lessonStore";
import UserLessonStore from "./userLessonStore";
import LessonCommentStore from "./lessonCommentStore";
import UserAnswerStore from "./userAnswerStore";
import ExaminingBoardStore from "./examiningBoardStore";
import StudentProgressByExaminingBoardStore from "./studentProgressByExaminingBoardStore"

configure({ enforceActions: "always" });

export class RootStore {
  userStore: UserStore;
  profileStore: ProfileStore;
  commonStore: CommonStore;
  modalStore: ModalStore;
  breadcrumbStore: BreadcrumbStore;
  categoryStore: CategoryStore;
  subcategoryStore: SubcategoryStore;
  questionStore: QuestionStore;
  answerStore: AnswerStore;
  userAnswerStore: UserAnswerStore;
  commentStore: CommentStore;
  studentDashboardStore: StudentDashboardStore;
  studentProgressByCategoryStore: StudentProgressByCategoryStore;
  studentProgressBySubcategoryStore: StudentProgressBySubcategoryStore;
  examStore: ExamStore;
  examCategoryStore: ExamCategoryStore;
  examSubcategoryStore: ExamSubcategoryStore;
  examUsersStore: ExamUsersStore;
  examUserAnswerStore: ExamUserAnswerStore;
  checkoutStore: CheckoutStore;
  planStore: PlanStore;
  voucherStore: VoucherStore;
  userPlanStore: UserPlanStore;
  courseStore: CourseStore;
  moduleStore: ModuleStore;
  lessonStore: LessonStore;
  userLessonStore: UserLessonStore;
  lessonCommentStore: LessonCommentStore;
  examiningBoardStore: ExaminingBoardStore;
  studentProgressByExaminingBoardStore: StudentProgressByExaminingBoardStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.profileStore = new ProfileStore(this);
    this.commonStore = new CommonStore(this);
    this.modalStore = new ModalStore(this);
    this.breadcrumbStore = new BreadcrumbStore(this);
    this.categoryStore = new CategoryStore(this);
    this.subcategoryStore = new SubcategoryStore(this);
    this.questionStore = new QuestionStore(this);
    this.answerStore = new AnswerStore(this);
    this.userAnswerStore = new UserAnswerStore(this);
    this.commentStore = new CommentStore(this);
    this.studentDashboardStore = new StudentDashboardStore(this);
    this.studentProgressByCategoryStore = new StudentProgressByCategoryStore(this);
    this.studentProgressBySubcategoryStore = new StudentProgressBySubcategoryStore(this);
    this.examStore = new ExamStore(this);
    this.examCategoryStore = new ExamCategoryStore(this);
    this.examSubcategoryStore = new ExamSubcategoryStore(this);
    this.examUsersStore = new ExamUsersStore(this);
    this.examUserAnswerStore = new ExamUserAnswerStore(this);
    this.checkoutStore = new CheckoutStore(this);
    this.planStore = new PlanStore(this);
    this.voucherStore = new VoucherStore(this);
    this.userPlanStore = new UserPlanStore(this);
    this.courseStore = new CourseStore(this);
    this.moduleStore = new ModuleStore(this);
    this.lessonStore = new LessonStore(this);
    this.userLessonStore = new UserLessonStore(this);
    this.lessonCommentStore = new LessonCommentStore(this);
    this.examiningBoardStore = new ExaminingBoardStore(this);
    this.studentProgressByExaminingBoardStore = new StudentProgressByExaminingBoardStore(this);
  }
}

export const RootStoreContext = createContext(new RootStore());