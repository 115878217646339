import { observer } from "mobx-react-lite";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Grid, Message, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";

const QuestionUserAnswers: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { question } = rootStore.questionStore;

  const [chartVisible, setChartVisible] = useState(true);

  useEffect(() => {
    if (question!.successPercentage > 0 || question!.failPercentage > 0) {
      const successChartCreate = () => {
        let successChart = am4core.create(
          "successchartdiv",
          am4charts.PieChart
        );

        let pieSeries = successChart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "percentage";
        pieSeries.dataFields.category = "type";

        successChart.innerRadius = am4core.percent(30);

        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.slices.template.cursorOverStyle = [
          {
            property: "cursor",
            value: "pointer",
          },
        ];

        pieSeries.alignLabels = false;
        pieSeries.labels.template.bent = true;
        pieSeries.labels.template.radius = 3;
        pieSeries.labels.template.padding(0, 0, 0, 0);

        pieSeries.ticks.template.disabled = true;

        let shadow = pieSeries.slices.template.filters.push(
          new am4core.DropShadowFilter()
        );
        shadow.opacity = 0;

        successChart.legend = new am4charts.Legend();

        successChart.data = [
          {
            type: "Acerto",
            percentage: question!.successPercentage,
          },
          {
            type: "Erro",
            percentage: question!.failPercentage,
          },
        ];
      };

      const answerOptionsChartCreate = () => {
        let chart = am4core.create("chartdiv", am4charts.XYChart);

        // Add data
        chart.data = question!.questionAnswersQuantities;

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "identifier";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "quantity";
        series.dataFields.categoryX = "identifier";
        series.name = "Quantidade";
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = 0.8;

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
      };

      if (question!.successPercentage > 0 || question!.failPercentage > 0) {
        setChartVisible(true);
        successChartCreate();
        answerOptionsChartCreate();
      } else {
        setChartVisible(false);
      }
    }
  }, [question, setChartVisible]);

  return (
    <Segment>
      <h3>Estatística</h3>

      <Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <h4 style={{ textAlign: "center" }}>
                Percentual de erro x acerto (todos os usuários)
              </h4>
              {chartVisible === false && (
                <Message
                  icon="warning circle"
                  header="Oops"
                  content="Ainda não há dados suficientes"
                />
              )}
              <div
                id="successchartdiv"
                style={{ width: "100%", height: "350px" }}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <h4 style={{ textAlign: "center" }}>
                Quantitativo respondido por alternativa (todos os usuários)
              </h4>
              {chartVisible === false && (
                <Message
                  icon="warning circle"
                  header="Oops"
                  content="Ainda não há dados suficientes"
                />
              )}
              <div id="chartdiv" style={{ width: "100%", height: "350px" }} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    </Segment>
  );
};

export default observer(QuestionUserAnswers);
