import { action, computed, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { IPlan } from "../models/plan";
import { RootStore } from "./rootStore";

const LIMIT = 10;

export default class PlanStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loadingInitial = false;
  @observable plan: IPlan | null = null;
  @observable planRegistry = new Map();
  @observable planCount = 0;
  @observable page = 1;
  @observable predicate = new Map();

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page > 1 ? (this.page - 1) * LIMIT : 0}`);
    
    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.planCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @computed get plansByName() {
    const plans = Array.from(this.planRegistry.values());
    return plans;
  }

  @action loadPlans = async () => {
    this.loadingInitial = true;
    try {
      this.planRegistry.clear();
      const plansEnvelope = await agent.Plans.list(this.axiosParams);
      const { dataCollection, dataCount } = plansEnvelope;
      runInAction("loading plans", () => {
        dataCollection.forEach((plan) => {
          this.planRegistry.set(plan.id, plan);
        });
        this.planCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load plans error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar planos");
    }
  };

  @action loadPlan = async (code: string) => {
    this.loadingInitial = true;
    try {
      var plan = await agent.Plans.detailsByCode(code);
      runInAction("loading plan", () => {
        this.plan = plan;
        this.loadingInitial = false;
      });
      
      return plan;
    } catch (error) {
      runInAction("load plan error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar plano");
    }
  };
}
