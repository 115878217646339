import { action, observable, runInAction } from "mobx";
import { SyntheticEvent } from "react";
import { toast } from "react-toastify";
import agent from "../api/agent";
import ExamSubcategoryFormValues, {
  IExamSubcategory,
} from "../models/examSubcategory";
import { RootStore } from "./rootStore";

export default class ExamSubcategoryStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable examSubcategoryRegistry = new Map();
  @observable submitting = false;
  @observable examSubcategory: IExamSubcategory | null = null;
  @observable target = "";

  @action upsertExamSubcategory = async (
    examSubcategory: ExamSubcategoryFormValues
  ) => {
    this.submitting = true;
    this.examSubcategory = null;
    try {
      await agent.ExamSubcategories.upsert(examSubcategory);

      runInAction("creating exam subcategory", () => {
        this.submitting = false;
      });
    } catch (error) {
      runInAction("create exam subcategory error", () => {
        this.submitting = false;
      });
      toast.error(error.data.errors.totalQuestions);
    }
  };

  @action deleteExamSubcategory = async (
    event: SyntheticEvent<HTMLButtonElement>,
    id: string
  ) => {
    this.submitting = true;
    this.target = event.currentTarget.name;
    try {
      await agent.ExamSubcategories.delete(id);
      runInAction("deleting exam subcategory", () => {
        this.examSubcategoryRegistry.delete(id);
        this.submitting = false;
        this.target = "";
      });
    } catch (error) {
      runInAction("delete exam subcategory error", () => {
        this.submitting = false;
        this.target = "";
      });
    }
  };
}
