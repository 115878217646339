import { action, computed, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { history } from "../..";
import { IExamUserResult } from "../models/examUser";
import { format } from "date-fns";

const LIMIT = 10;

export default class ExamUsersStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable examUserRegistry = new Map();
  @observable examUserSummaryRegistry = new Map();
  @observable initialLoading = false;
  @observable submitting = false;
  @observable examUserResult: IExamUserResult | null = null;
  @observable examUserCount = 0;
  @observable page = 1;
  @observable predicate = new Map();
  @observable totalQuestions = 0;
  @observable currentQuestion = 0;

  @action setPredicate = () => {
    this.predicate.clear();
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page > 1 ? (this.page - 1) * LIMIT : 0}`);

    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.examUserCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @computed get examUserHistoryByStartDate() {
    const examUserHistory = Array.from(this.examUserRegistry.values());
    return examUserHistory;
  }

  @computed get examUserSummaryByExamCount() {
    const examUserSummary = Array.from(this.examUserSummaryRegistry.values());
    return examUserSummary;
  }

  @action increaseCurrentQuestion = () => {
    this.currentQuestion = this.currentQuestion + 1;
  };

  @action loadExamHistory = async () => {
    this.initialLoading = true;
    try {
      this.examUserRegistry.clear();
      const examUserHistoryEnvelope = await agent.ExamUsers.getExamHistory(
        this.axiosParams
      );
      const { dataCollection, dataCount } = examUserHistoryEnvelope;
      runInAction("loading examuser history", () => {
        dataCollection.forEach((examUser) => {
          this.examUserRegistry.set(examUser.id, examUser);
        });
        this.examUserCount = dataCount;
        this.initialLoading = false;
      });
    } catch (error) {
      runInAction("load examuser history error", () => {
        this.initialLoading = false;
      });
    }
  };

  @action startExam = async (examId: string) => {
    this.submitting = true;
    try {
      let startDate = new Date();
      let examUser = await agent.ExamUsers.startExam(
        examId,
        format(startDate, "yyyy-MM-dd HH:mm:ss")
      );
      let exam = await agent.Exams.details(examUser.examId);

      runInAction("starting exam", () => {
        this.totalQuestions = exam.totalQuestions;
        this.currentQuestion = 1;
        this.submitting = false;
      });
      history.push(`/aluno/simulado/${examUser.id}`);
    } catch (error) {
      if (error && error.data && error.data.errors.subscription) {
        toast.error(error.data.errors.subscription);
      } else {
        toast.error(error.data.errors.code);
      }
      runInAction("start exam error", () => {
        this.submitting = false;
      });
    }
  };

  @action stopExam = async (id: string) => {
    try {
      let endDate = new Date();
      await agent.ExamUsers.stopExam(
        id,
        format(endDate, "yyyy-MM-dd HH:mm:ss")
      );
      history.push(`/aluno/simulado/resultado/${id}`);
    } catch (error) {
      toast.error("Erro ao finalizar simulado");
    }
  };

  @action getResult = async (id: string) => {
    this.initialLoading = true;
    try {
      let result = await agent.ExamUsers.getResult(id);
      runInAction(() => {
        this.examUserResult = result;
        this.initialLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.initialLoading = false;
      });
      toast.error("Erro ao carregar resultado");
    }
  };

  @action isUserReachPlanCap = async () => {
    try {
      return await agent.ExamUsers.isUserReachPlanCap();
    } catch (error) {
      console.log(error);
    }
  };

  @action getSummaryByExam = async (username: string) => {
    try {
      this.examUserSummaryRegistry.clear();
      var params = this.axiosParams;
      params.append("username", username);
      const { dataCollection, dataCount } = await agent.ExamUsers.summaryByExam(
        params
      );
      runInAction("loading examuser summary", () => {
        dataCollection.forEach((item) => {
          this.examUserSummaryRegistry.set(item.examId, item);
        });
        this.examUserCount = dataCount;
        this.initialLoading = false;
      });
    } catch (error) {
      toast.error("Erro ao carregar simulados");
    }
  };

  @action deleteAllExamsByStudent = async () => {
    this.submitting = true;
    try {
      await agent.ExamUsers.deleteAllByStudent();
      runInAction("deleting all examusers by student", () => {
        this.submitting = false;
      });
      toast.success("Simulados excluidos com sucesso!");
    } catch (error) {
      runInAction("delete all examusers by student error", () => {
        this.submitting = false;
      });
      toast.error("Erro ao deletar simulados");
    }
  };
}
