import { IAnswer } from "./answer";
import { IComment } from "./comment";
import { IUserAnswer } from "./userAnswer";

export interface IQuestionsEnvelope {
  dataCollection: IQuestion[];
  dataCount: number;
}

export interface IQuestion {
  id: string;
  code: string;
  subcategoryId: string;
  subcategoryCode: string;
  subcategoryName: string;
  categoryId: string;
  categoryCode: string;
  categoryName: string;
  examiningBoardId: string;
  examiningBoardCode: string;
  year?: number;
  text: string;
  comment: string;
  images: IImage[];
  answers: IAnswer[];
  correctAnswer: string;
  comments: IComment[];
  commentsCount: number;
  userAnswers: IUserAnswer[];
  successPercentage: number;
  failPercentage: number;
  questionAnswersQuantities: IQuestionAnswersQuantity[];
  todayQttAnsweredQuestions: number;
}

export interface IImage {
  id: string;
  url: string;
}

export interface IQuestionAnswersQuantity {
  identifier: string;
  quantity: number;
}

export class QuestionFilter {
  text?: string = "";
  categoryId?: string = "";
  subcategoryId?: string = "";
  examiningBoardId?: string = "";
}

export class QuestionStudentFilter {
  categoryId?: any = "";
  subcategoryId?: any = "";
  answerStatus: any = "";
  examiningBoardId?: string = "";
}

export class QuestionFormValues {
  id?: string = undefined;
  code: string = "";
  categoryId: string = "";
  subcategoryId: string = "";
  examiningBoardId?: string = undefined;
  year?: number = undefined;
  text: string = "";
  comment: string = "";

  constructor(init?: QuestionFormValues) {
    Object.assign(this, init);
  }
}
