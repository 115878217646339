import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Confirm,
  Dropdown,
  Header,
  Message,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import UserProfileCancelSubscription from "./UserProfileCancelSubscription";

const UserProfileSubscription: React.FC<{ username: string }> = ({
  username,
}) => {
  var rootContext = useContext(RootStoreContext);
  const {
    subscribeUserToPlan,
    loadUserPlans,
    userPlansByStartDate,
    page,
    setPage,
    totalPages,
  } = rootContext.userPlanStore;
  const { openModal } = rootContext.modalStore;

  useEffect(() => {
    loadUserPlans(username);
  }, [loadUserPlans, username]);

  const [state, setState] = useState({ open: false });
  const open = () => setState({ open: true });
  const close = () => setState({ open: false });
  const [selectedPlan, setSelectedPlan] = useState("");

  const onPageChange = (e: any, pageInfo: any) => {
    setPage(pageInfo.activePage);
    loadUserPlans(username);
  };

  const plansOptions = [
    { key: "M3R", text: "Método 3R", value: "M3R" },
    { key: "SEM", text: "Semestral", value: "SEM" },
    { key: "ANO", text: "Anual", value: "ANO" },
    { key: "SUB", text: "Assinatura", value: "SUB" },
    { key: "CCC", text: "Comunidade do Contador Concursado", value: "CCC" },
    { key: "EXP", text: "CFC Express", value: "EXP" },
  ];

  const onChange = (planCode: string | any) => {
    setSelectedPlan(planCode);
  };

  return (
    <Fragment>
      <Segment basic floated="left">
        <Header as="h2">Inscrições</Header>
      </Segment>
      <Segment
        basic
        floated="right"
        style={{ paddingRight: "0px", marginRight: "0px" }}
      >
        <Dropdown
          placeholder="Plano"
          floating
          selection
          options={plansOptions}
          onChange={(e, data) => onChange(data.value)}
        />
        <Button
          floated="right"
          content="Conceder acesso"
          primary
          onClick={() => open()}
        />
      </Segment>
      {userPlansByStartDate.length > 0 ? (
        <Table columns={16} striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={6}>Plano</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Início</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Fim</Table.HeaderCell>
              <Table.HeaderCell textAlign="center"></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {userPlansByStartDate.map((userPlan) => (
              <Table.Row
                key={userPlan!.id}
                style={{ color: userPlan!.cancelled ? "red" : "black" }}
              >
                <Table.Cell>{`Plano ${userPlan!.planName}`}</Table.Cell>
                <Table.Cell textAlign="center">
                  {format(new Date(userPlan!.startDate), "dd/MM/yyyy")}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {format(new Date(userPlan!.endDate), "dd/MM/yyyy")}
                </Table.Cell>
                <Table.Cell>
                  {new Date(userPlan!.startDate) <= new Date() &&
                    new Date(userPlan!.endDate) >= new Date() && (
                      <Button
                        content="Cancelar"
                        color="red"
                        onClick={() =>
                          openModal(
                            <UserProfileCancelSubscription
                              id={userPlan!.id}
                              username={username}
                            />,
                            "small",
                            false,
                            true
                          )
                        }
                      />
                    )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="16">
                <Segment basic clearing floated="right">
                  <Pagination
                    defaultActivePage={page}
                    onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
                    totalPages={totalPages}
                  />
                </Segment>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      ) : (
        <Message
          icon="warning circle"
          header="Oops"
          content="Ainda não há dados suficientes"
        />
      )}
      <Confirm
        open={state.open}
        header="Aviso"
        content="DESEJA REALMENTE CONCEDER ACESSO AO PLANO SELECIONADO?"
        cancelButton="Nāo"
        confirmButton="Sim"
        onConfirm={() => {
          if (selectedPlan !== "") {
            subscribeUserToPlan(username, selectedPlan);
          } else {
            toast.error("Por favor selecione um plano");
          }
          close();
        }}
        onCancel={close}
      />
    </Fragment>
  );
};

export default observer(UserProfileSubscription);
