import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Confirm, Popup, Table } from "semantic-ui-react";
import { IModule } from "../../app/models/module";
import { RootStoreContext } from "../../app/stores/rootStore";
import ModuleForm from "./form/ModuleForm";

const ModuleListItem: React.FC<{ module: IModule }> = ({ module }) => {
  const rootStore = useContext(RootStoreContext);
  const { deleteModule } = rootStore.moduleStore;
  const { openModal } = rootStore.modalStore;

  const [moduleId, setModuleId] = useState("");
  const [state, setState] = useState({ open: false });
  const open = () => setState({ open: true });
  const close = () => setState({ open: false });

  const handleDeleteCourse = (id: string) => {
    setModuleId(id);
    open();
  };

  return (
    <Fragment>
      <Confirm
        open={state.open}
        header="Deseja realmente excluir o módulo?"
        content="Isso excluirá automaticamente todas as aulas associados a ele."
        cancelButton="Nāo"
        confirmButton="Sim"
        onConfirm={() => {
          deleteModule(moduleId);
          close();
        }}
        onCancel={close}
      />
      {module && (
        <Table.Row>
          <Table.Cell>
            <Link
              onClick={() =>
                openModal(
                  <ModuleForm id={module.id} courseId={module.courseId} />,
                  "small",
                  false,
                  true
                )
              }
              to={`/admin/cursos/${module.courseId}/#`}
            >
              {module.name}
            </Link>
          </Table.Cell>
          <Table.Cell>{module.order}</Table.Cell>
          <Table.Cell textAlign="right">
            <Popup
              trigger={
                <Button
                  icon="video play"
                  as={Link}
                  to={`/admin/cursos/${module.courseId}/modulos/${module.id}`}
                  basic
                />
              }
              content="Aulas"
              position="top center"
            />
            <Popup
              trigger={
                <Button
                  icon="delete"
                  basic
                  onClick={() => handleDeleteCourse(module.id)}
                />
              }
              content="Excluir"
              position="top center"
            />
          </Table.Cell>
        </Table.Row>
      )}
    </Fragment>
  );
};

export default observer(ModuleListItem);
