import React, { useContext, useState, useEffect, Fragment } from "react";
import { combineValidators, isRequired } from "revalidate";
import { Link, RouteComponentProps } from "react-router-dom";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { ExaminingBoardFormValues } from "../../../app/models/examiningBoard";
import { v4 as uuid } from "uuid";
import { Segment, Form, Button, Grid } from "semantic-ui-react";
import TextInput from "../../../app/common/form/TextInput";
import { Form as FinalForm, Field } from "react-final-form";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import { observer } from "mobx-react-lite";

const validate = combineValidators({
  code: isRequired({ message: "Sigla é obrigatório" }),
  name: isRequired({ message: "Nome é obrigatório" }),
});

interface DetailParams {
  id: string;
}

const ExaminingBoardForm: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    createExaminingBoard,
    editExaminingBoard,
    submitting,
    loadExaminingBoard,
    deleteExaminingBoard,
    target,
  } = rootStore.examiningBoardStore;

  const [examiningBoard, setExaminingBoard] = useState(new ExaminingBoardFormValues());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (match.params.id) {
      setLoading(true);
      loadExaminingBoard(match.params.id)
        .then((examiningBoard) => setExaminingBoard(new ExaminingBoardFormValues(examiningBoard)))
        .finally(() => setLoading(false));
    }
  }, [loadExaminingBoard, match.params.id]);

  const handleFinalFormSubmit = (values: any) => {
    const { ...examiningBoard } = values;
    if (!examiningBoard.id) {
      let newExaminingBoard = {
        ...examiningBoard,
        id: uuid(),
      };
      createExaminingBoard(newExaminingBoard);
    } else {
      editExaminingBoard(examiningBoard);
    }
  };

  return (
    <Fragment>
      {examiningBoard.id ? <h1>Editar Banca</h1> : <h1>Nova Banca</h1>}
      <Segment clearing>
        <FinalForm
          validate={validate}
          initialValues={examiningBoard}
          onSubmit={handleFinalFormSubmit}
          render={({ handleSubmit, invalid, pristine }) => (
            <Form onSubmit={handleSubmit} loading={loading}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Field
                      name="code"
                      title="Sigla"
                      placeholder="Sigla"
                      value={examiningBoard.code}
                      component={TextInput}
                      disabled={examiningBoard.id ? true : false}
                    />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Field
                      name="name"
                      title="Nome"
                      placeholder="Nome"
                      value={examiningBoard.name}
                      component={TextInput}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Field
                      name="description"
                      title="Descrição"
                      placeholder="Descrição"
                      value={examiningBoard.description}
                      rows={4}
                      component={TextAreaInput}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Button
                      loading={submitting}
                      disabled={loading || invalid || pristine}
                      floated="right"
                      positive
                      type="submit"
                      content="Salvar"
                    />
                    <Button
                      as={Link}
                      to="/admin/bancas"
                      disabled={loading}
                      floated="right"
                      type="button"
                      content="Cancelar"
                    />
                    {examiningBoard.id && (
                      <Button
                        loading={target === examiningBoard.id && submitting}
                        disabled={loading}
                        onClick={(e) => {
                          deleteExaminingBoard(e, examiningBoard.id!);
                          setExaminingBoard(new ExaminingBoardFormValues());
                        }}
                        floated="right"
                        color="red"
                        content="Excluir"
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        />
      </Segment>
    </Fragment>
  );
};

export default observer(ExaminingBoardForm);
