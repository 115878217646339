import React from "react";
import { IExaminingBoard } from "../../../app/models/examiningBoard";
import { Table } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

const ExaminingBoardListItem: React.FC<{ examiningBoard: IExaminingBoard }> = ({
  examiningBoard,
}) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Link to={`/admin/bancas/${examiningBoard.id}`}>
          {examiningBoard.code}
        </Link>
      </Table.Cell>
      <Table.Cell>{examiningBoard.name}</Table.Cell>
      <Table.Cell>{examiningBoard.description}</Table.Cell>
      <Table.Cell>{examiningBoard.questionCount}</Table.Cell>
    </Table.Row>
  );
};

export default observer(ExaminingBoardListItem);
