import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";

const QuestionProfComment: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { question } = rootStore.questionStore;

  let correctAnswer = "";
  question!.answers.forEach((answer) => {
    if (answer.isCorrect) {
      correctAnswer = answer.identifier;
    }
  });

  return (
    <Segment>
      <h3>Comentário do Professor</h3>
      <div>
        <div dangerouslySetInnerHTML={{ __html: question!.comment }} />
      </div>
      <br />
      <div>
        <strong>Resposta correta: {correctAnswer}</strong>
      </div>
    </Segment>
  );
};

export default observer(QuestionProfComment);
