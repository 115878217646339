import React, { useContext, useState } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { Form, Button, Header, Segment, List } from "semantic-ui-react";
import TextInput from "../../app/common/form/TextInput";
import { RootStoreContext } from "../../app/stores/rootStore";
import { IUserFormValues } from "../../app/models/user";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired, matchesField } from "revalidate";
import ErrorMessage from "../../app/common/form/ErrorMessage";
import { observer } from "mobx-react-lite";
import CheckboxInput from "../../app/common/form/CheckboxInput";
import SelectInput from "../../app/common/form/SelectInput";

const validate = combineValidators({
  displayName: isRequired({ message: "Name é obrigatório" }),
  email: isRequired({ message: "Email é obrigatório" }),
  password: isRequired({ message: "Senha é obrigatório" }),
  confirmPassword: matchesField(
    "password",
    "password"
  )({ message: "Confirmação de senha errada" }),
  gender: isRequired({ message: "Sexo é obrigatório" }),
  terms: isRequired({ message: "É necessário aceitar os termos de uso" }),
});

const RegisterForm = () => {
  const rootStore = useContext(RootStoreContext);
  const { register } = rootStore.userStore;

  const [showCompletedMsg, setShowCompletedMsg] = useState(false);

  const genderOptions = [
    { key: "", text: "", value: "" },
    { key: "M", text: "Masculino", value: "M" },
    { key: "F", text: "Feminino", value: "F" },
  ];

  const cleanFields = (values: IUserFormValues) => {
    if (values.terms) {
      setShowCompletedMsg(true);
      values.displayName = "";
      values.email = "";
      values.password = "";
      values.confirmPassword = "";
      values.terms = false;
      values.gender = "";
    }
  };

  return (
    <FinalForm
      onSubmit={(values: IUserFormValues) =>
        register(values)
          .then(() => cleanFields(values))
          .catch((error) => ({
            [FORM_ERROR]: error,
          }))
      }
      validate={validate}
      render={({
        handleSubmit,
        submitting,
        submitError,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (
        <Form onSubmit={handleSubmit} error>
          <Header as="h2" content="Registrar" color="teal" textAlign="center" />
          {submitError && !dirtySinceLastSubmit && (
            <ErrorMessage error={submitError} />
          )}
          <Field name="displayName" placeholder="Nome" component={TextInput} />
          <Field
            name="gender"
            placeholder="Sexo"
            component={SelectInput}
            options={genderOptions}
          />
          <Field name="email" placeholder="Email" component={TextInput} />
          <Field
            name="password"
            placeholder="Senha"
            component={TextInput}
            type="password"
          />
          <Segment style={{ fontSize: "9pt" }}>
            <h4>Sua senha deve conter:</h4>
            <List as="ul">
              <List.Item as="li">Mínimo 6 caracteres</List.Item>
              <List.Item as="li">Mínimo 1 letra maiúscula</List.Item>
              <List.Item as="li">Mínimo 1 letra minúscula</List.Item>
              <List.Item as="li">Mínimo 1 número</List.Item>
              <List.Item as="li">Mínimo 1 caracter especial</List.Item>
            </List>
          </Segment>
          <Field
            name="confirmPassword"
            placeholder="Confirmar Senha"
            component={TextInput}
            type="password"
          />
          <Field
            name="terms"
            component={CheckboxInput}
            type="checkbox"
            contentText={
              <div>
                Aceitar
                <a
                  href="http://cfcacademy.com.br/termos"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Termos de Uso
                </a>
              </div>
            }
          />
          <Button
            disabled={(invalid && !dirtySinceLastSubmit) || pristine}
            loading={submitting}
            color="teal"
            content="Registrar"
            type="submit"
            fluid
          />
          {showCompletedMsg && (
            <Segment clearing style={{ color: "Red", fontSize: "12pt" }}>
              Verifique sua caixa de email para completar o cadastro.
            </Segment>
          )}
        </Form>
      )}
    />
  );
};

export default observer(RegisterForm);
