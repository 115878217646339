import React from "react";
import { Container, Header, Icon, Segment } from "semantic-ui-react";

const MobileUnderMaintenance = () => {
  return (
    <Segment inverted textAlign="center" vertical className="masthead">
      <Container>
        <Segment clearing basic style={{marginLeft: "25px"}}>
          <Header icon inverted>
            <Icon name="cogs" />
          </Header>
          <Header as="h1" inverted>
            CFC Academy
          </Header>
          <Header as="h2" inverted content="Site em manutenção" />
        </Segment>
      </Container>
    </Segment>
  );
};

export default MobileUnderMaintenance;
