import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import LoadComponent from "../../../../app/layout/LoadComponent";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import ComparizonTile from "../ComparizonTile";
import GeneralProgress from "../GeneralProgress";
import PlanTile from "../PlanTile";
import ProgressByCategory from "../ProgressByCategory";
import SolvedQuestionsTile from "../SolvedQuestionsTile";
import WinFailTile from "../WinFailTile";

const StudentDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.userStore;
  const { loadData, loadingInitial } = rootStore.studentDashboardStore;

  useEffect(() => {
    loadData("0");
  }, [loadData]);

  if (loadingInitial)
    return <LoadComponent content="Carregando informações..." />;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={10}>
          <h1>Página Inicial</h1>
        </Grid.Column>
        <Grid.Column width={6} textAlign="right" verticalAlign="bottom">
          <h2>Bem vindo {user!.displayName}</h2>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Grid columns={4}>
            <Grid.Row>
              <Grid.Column>
                <PlanTile />
              </Grid.Column>
              <Grid.Column>
                <SolvedQuestionsTile />
              </Grid.Column>
              <Grid.Column>
                <WinFailTile />
              </Grid.Column>
              <Grid.Column>
                <ComparizonTile />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <ProgressByCategory />
        </Grid.Column>
        <Grid.Column width={8}>
          <GeneralProgress />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(StudentDashboard);
