import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Grid, Segment, Form, Button } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { v4 as uuid } from "uuid";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { LessonFormValues } from "../../../app/models/lesson";
import TextInput from "../../../app/common/form/TextInput";
import { combineValidators, isRequired } from "revalidate";
import { history } from "../../..";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import { RouteComponentProps } from "react-router-dom";

const validate = combineValidators({
  name: isRequired({ message: "Nome é obrigatório" }),
  order: isRequired({ message: "Ordem é obrigatório" }),
});

interface DetailParams {
  id: string;
}

const LessonForm: React.FC<RouteComponentProps<DetailParams>> = ({ match }) => {
  const rootStore = useContext(RootStoreContext);
  const { loading, submitting, createLesson, editLesson, loadLesson } =
    rootStore.lessonStore;

  const [lesson, setLesson] = useState(new LessonFormValues());

  useEffect(() => {
    if (match.params.id) {
      loadLesson(match.params.id).then((lesson) =>
        setLesson(new LessonFormValues(lesson))
      );
    }
  }, [loadLesson, match.params.id]);

  const handleFinalFormSubmit = (values: any) => {
    const { ...lesson } = values;

    if (!lesson.id) {
      var url = new URL(window.location.href);
      const moduleId = url.searchParams.get("moduleid");
      const courseId = url.searchParams.get("courseid");
      let newLesson = {
        ...lesson,
        id: uuid(),
        moduleId: moduleId,
      };

      createLesson(newLesson).then(() => {
        history.push(`/admin/cursos/${courseId}/modulos/${moduleId}`);
      });
    } else {
      editLesson(lesson).then((response: any) => {
        history.push(
          `/admin/cursos/${response.courseId}/modulos/${response.moduleId}`
        );
      });
    }
  };

  return (
    <Fragment>
      <h1>Aula</h1>
      <Segment>
        <FinalForm
          validate={validate}
          initialValues={lesson}
          onSubmit={handleFinalFormSubmit}
          render={({ handleSubmit, invalid, pristine }) => (
            <Form onSubmit={handleSubmit}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Field
                      name="name"
                      title="Nome"
                      placeholder="Nome"
                      value={lesson.name}
                      component={TextInput}
                    />
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Field
                      name="videoUrl"
                      title="Vídeo ID"
                      placeholder="Vídeo ID"
                      value={lesson.videoUrl}
                      component={TextInput}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Field
                      name="order"
                      title="Ordem"
                      placeholder="Ordem"
                      value={lesson.order.toString()}
                      component={TextInput}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Field
                      name="documentUrls"
                      title="Documentos"
                      placeholder="Documentos"
                      value={lesson.documentUrls}
                      component={TextAreaInput}
                      rows={4}
                    />
                  </Grid.Column>
                  <Grid.Column width={8}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Button
                      loading={submitting}
                      disabled={loading || invalid || pristine}
                      floated="left"
                      positive
                      type="submit"
                      content="Salvar"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        />
      </Segment>
    </Fragment>
  );
};

export default observer(LessonForm);
