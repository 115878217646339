import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { Comment, Icon, Message } from "semantic-ui-react";
import { IComment } from "../../../../../app/models/comment";

const AnsweredQuestionComments: React.FC<{ comments: IComment[] }> = ({
  comments,
}) => {
  return (
    <Fragment>
      <h4>Comentários</h4>
      {comments.length === 0 && (
        <Message
          icon="warning circle"
          header="Oops"
          content="Ainda não existem comentários para essa questão."
        />
      )}
      <Comment.Group>
        {comments &&
          comments.map((comment) => (
            <Comment key={comment.id}>
              <Comment.Avatar
                as="a"
                src={
                  comment.isProfessorAnswer
                    ? "/assets/professor.jpg"
                    : comment.gender === "F"
                    ? "/assets/female.jpg"
                    : "/assets/male.jpg"
                }
              />
              <Comment.Content>
                <Comment.Author>
                  {comment.isProfessorAnswer ? (
                    <Fragment>
                      Professor <Icon name="graduation cap" />
                    </Fragment>
                  ) : (
                    comment.displayName
                  )}
                </Comment.Author>
                <Comment.Text>
                  {comment.isProfessorAnswer ? (
                    <div dangerouslySetInnerHTML={{ __html: comment.text }} />
                  ) : (
                    <div>
                      {comment.text.split("\n").map((i, key) => {
                        return <p key={key}>{i}</p>;
                      })}
                    </div>
                  )}
                </Comment.Text>
                <Comment.Metadata>
                  <div>{comment.postedDate}</div>
                </Comment.Metadata>
              </Comment.Content>
              {comment.replys.length > 0 && (
                <Comment.Group>
                  {comment.replys.map((reply) => (
                    <Comment key={reply.id}>
                      <Comment.Avatar
                        as="a"
                        src={
                          reply.isProfessorAnswer
                            ? "/assets/professor.jpg"
                            : reply.gender === "F"
                            ? "/assets/female.jpg"
                            : "/assets/male.jpg"
                        }
                      />
                      <Comment.Content>
                        <Comment.Author>
                          {reply.isProfessorAnswer ? (
                            <Fragment>
                              Professor <Icon name="graduation cap" />
                            </Fragment>
                          ) : (
                            reply.displayName
                          )}
                        </Comment.Author>
                        <Comment.Text>
                          {reply.isProfessorAnswer ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: reply.text }}
                            />
                          ) : (
                            <div>
                              {reply.text.split("\n").map((i, key) => {
                                return <p key={key}>{i}</p>;
                              })}
                            </div>
                          )}
                        </Comment.Text>
                        <Comment.Metadata>
                          <div>{reply.postedDate}</div>
                        </Comment.Metadata>
                      </Comment.Content>
                    </Comment>
                  ))}
                </Comment.Group>
              )}
            </Comment>
          ))}
      </Comment.Group>
    </Fragment>
  );
};

export default observer(AnsweredQuestionComments);
