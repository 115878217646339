import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useState } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { Button, Form, Header, Segment } from "semantic-ui-react";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import { RootStoreContext } from "../../../app/stores/rootStore";

const validate = combineValidators({
  cancellationReason: isRequired({ message: "Motivo é obrigatório" }),
});

const UserProfileCancelSubscription: React.FC<{
  id: string;
  username: string;
}> = ({ id, username }) => {
  const rootStore = useContext(RootStoreContext);
  const { cancelSubscription, loadUserPlans } = rootStore.userPlanStore;
  const { closeModal } = rootStore.modalStore;

  const [reason] = useState("");

  const handleFinalFormSubmit = (values: any) => {
    cancelSubscription(id, values.cancellationReason).then(() =>
      loadUserPlans(username)
    );
    closeModal();
  };

  return (
    <Fragment>
      <Header as="h2" content="Cancelar plano" />
      <Segment clearing basic>
        <FinalForm
          onSubmit={handleFinalFormSubmit}
          validate={validate}
          render={({
            handleSubmit,
            invalid,
            pristine,
            submitting,
            submitSucceeded,
          }) => (
            <Form onSubmit={handleSubmit} error>
              <Field
                name="cancellationReason"
                title="Motivo"
                component={TextAreaInput}
                rows={3}
                placeholder="Motivo"
                value={reason}
              />
              <Button
                loading={submitting}
                floated="right"
                disabled={invalid || pristine || submitSucceeded}
                positive
                content="Salvar"
              />
            </Form>
          )}
        />
      </Segment>
    </Fragment>
  );
};

export default observer(UserProfileCancelSubscription);
