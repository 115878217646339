import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Button, Grid } from "semantic-ui-react";
import LoadComponent from "../../app/layout/LoadComponent";
import { RootStoreContext } from "../../app/stores/rootStore";
import CourseList from "./CourseList";
import CourseForm from "./form/CourseForm";

const CourseDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadCourses, loadingInitial } = rootStore.courseStore;
  const { openModal } = rootStore.modalStore;

  useEffect(() => {
    loadCourses();
  }, [loadCourses]);

  if (loadingInitial) return <LoadComponent content="Carregando cursos..." />;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={14}>
          <h1>Cursos</h1>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            active
            positive
            content="Novo"
            floated="right"
            style={{ marginTop: "12px" }}
            onClick={() => openModal(<CourseForm id="" />, "small", false, true)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <CourseList />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(CourseDashboard);
