import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Grid, Icon } from "semantic-ui-react";
import LoadComponent from "../../app/layout/LoadComponent";
import { RootStoreContext } from "../../app/stores/rootStore";
import LessonList from "./LessonList";

interface DetailParams {
  id: string;
}

const LessonDashboard: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { loadModule, module } = rootStore.moduleStore;
  const { loadLessons, loadingInitial } = rootStore.lessonStore;

  useEffect(() => {
    loadModule(match.params.id);
    loadLessons("", match.params.id);
  }, [loadModule, loadLessons, match.params.id]);

  if (loadingInitial) return <LoadComponent content="Carregando aulas..." />;

  return (
    <Fragment>
      {module && (
        <Grid>
          <Grid.Row>
            <Grid.Column width={14}>
              <h1>{module!.name}</h1>
              <Link to={`/admin/cursos/${module.courseId}/${module.id}`}>
                <Icon name="chevron circle left" /> Voltar para Módulos
              </Link>
            </Grid.Column>
            <Grid.Column width={2}>
              <Button
                as={Link}
                to={`/admin/novaaula?courseid=${module.courseId}&moduleid=${match.params.id}`}
                active
                positive
                content="Nova Aula"
                floated="right"
                style={{ marginTop: "12px" }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <LessonList id={match.params.id} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Fragment>
  );
};

export default observer(LessonDashboard);
