import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Confirm, Popup, Table } from "semantic-ui-react";
import { ILesson } from "../../app/models/lesson";
import { RootStoreContext } from "../../app/stores/rootStore";

const LessonListItem: React.FC<{ lesson: ILesson }> = ({ lesson }) => {
  const rootStore = useContext(RootStoreContext);
  const { deleteLesson } = rootStore.lessonStore;

  const [lessonId, setLessonId] = useState("");

  const [state, setState] = useState({ open: false });
  const open = () => setState({ open: true });
  const close = () => setState({ open: false });

  const handleDeleteLesson = (id: string) => {
    setLessonId(id);
    open();
  };

  return (
    <Fragment>
      <Confirm
        open={state.open}
        header="Aviso"
        content="Deseja realmente excluir a aula?"
        cancelButton="Nāo"
        confirmButton="Sim"
        onConfirm={() => {
          deleteLesson(lessonId);
          close();
        }}
        onCancel={close}
      />
      <Table.Row>
        <Table.Cell>
          <Link to={`/admin/aulas/${lesson.id}`}>{lesson.name}</Link>
        </Table.Cell>
        <Table.Cell>{lesson.order}</Table.Cell>
        <Table.Cell textAlign="right">
          <Popup
            trigger={
              <Button
                icon="delete"
                basic
                onClick={() => handleDeleteLesson(lesson.id)}
              />
            }
            content="Excluir"
            position="top center"
          />
        </Table.Cell>
      </Table.Row>
    </Fragment>
  );
};

export default observer(LessonListItem);
