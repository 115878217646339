import { action, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { IStudentDashboard } from "../models/studentDashboard";
import { RootStore } from "./rootStore";

export default class StudentDashboardStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable loadingInitial = false;
  @observable studentDashboard: IStudentDashboard | null = null;

  @action loadData = async (userName: string) => {
    this.loadingInitial = true;
    try {
      const result = await agent.StudentDashboard.details(userName);
      runInAction(() => {
        this.loadingInitial = false;
        this.studentDashboard = result;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingInitial = false;
        this.studentDashboard = null;
      });
      toast.error("Erro ao carregar informações");
    }
  };
}
