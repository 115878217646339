import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Form, Segment } from "semantic-ui-react";
import { IAnswer } from "../../../app/models/answer";
import { RootStoreContext } from "../../../app/stores/rootStore";
import AnswerForm from "./form/AnswerForm";

interface IProps {
  answer: IAnswer;
  answerEditMode: boolean;
}

const AnswerListItem: React.FC<IProps> = ({ answer, answerEditMode }) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const { setCorrectAnswer } = rootStore.answerStore;
  return (
    <Segment color={answer.isCorrect ? "green" : undefined}>
      <Form>
        <Form.Group inline>
          <Form.Field>
            {answerEditMode && (
              <Button
                onClick={() =>
                  openModal(
                    <AnswerForm
                      id={answer.id}
                    />,
                    "large",
                    false,
                    true
                  )
                }
                negative
                content="Editar"
                floated="right"
              />
            )}
          </Form.Field>
          <Form.Field>
            <Button
              onClick={() => {
                setCorrectAnswer(answer.questionId, answer.id);
              }}
              circular
              content={answer.identifier}
              disabled={answer.isCorrect}
              color={answer.isCorrect ? "green" : undefined}
              title="Setar resposta correta"
            />
          </Form.Field>
          <Form.Field>
            <span style={{ paddingLeft: 4 }}>{answer.text}</span>
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  );
};

export default observer(AnswerListItem);
