import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Button, Comment, Grid, Pagination, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import LessonCommentListItem from "./LessonCommentListItem";

const LessonCommentList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    commentsByPostedDate,
    loadComments,
    page,
    setPage,
    totalPages,
    deleteComments,
    submitting,
    selectedComments,
  } = rootStore.lessonCommentStore;

  const onPageChange = (e: any, pageInfo: any) => {
    setPage(pageInfo.activePage);
    loadComments();
  };
  return (
    <Segment>
      <h3>Selecione os comentários que deseja excluir:</h3>
      <Comment.Group>
        {commentsByPostedDate.map((comment) => (
          <LessonCommentListItem key={comment.id} comment={comment} />
        ))}
      </Comment.Group>
      <Grid>
        <Grid.Row>
          <Grid.Column width={12}>
            <Pagination
              defaultActivePage={page}
              onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
              totalPages={totalPages}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Button
              floated="right"
              color="red"
              content="Excluir selecionados"
              onClick={(e) => deleteComments(e)}
              disabled={selectedComments.length === 0}
              loading={submitting}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default observer(LessonCommentList);
