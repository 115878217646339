import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Pagination, Segment, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import QuestionListItem from "./QuestionListItem";

const QuestionList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { questionsByName, loadQuestions, page, setPage, totalPages } = rootStore.questionStore;

  const onPageChange = (e: any, pageInfo: any) => {
    setPage(pageInfo.activePage);
    loadQuestions();
  };

  return (
    <Fragment>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Codigo</Table.HeaderCell>
            <Table.HeaderCell width={7}>Questāo</Table.HeaderCell>
            <Table.HeaderCell width={1}>Resposta</Table.HeaderCell>
            <Table.HeaderCell width={2}>Categoria</Table.HeaderCell>
            <Table.HeaderCell width={2}>Subcategoria</Table.HeaderCell>
            <Table.HeaderCell width={2}>Banca</Table.HeaderCell>
            <Table.HeaderCell width={1}>Ano</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {questionsByName.map((question) => (
           <QuestionListItem key={question.id} question={question} />
        ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="7">
            <Segment basic clearing floated="right">
                <Pagination
                  defaultActivePage={page}
                  onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
                  totalPages={totalPages}
                />
              </Segment>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Fragment>
  );
};

export default observer(QuestionList);
