import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Pagination, Segment, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import ExamHistoryListItem from "./ExamHistoryListItem";

const ExamHistoryList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    examUserHistoryByStartDate,
    loadExamHistory,
    page,
    setPage,
    totalPages,
  } = rootStore.examUsersStore;

  const onPageChange = (e: any, pageInfo: any) => {
    setPage(pageInfo.activePage);
    loadExamHistory();
  };

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={8}>Simulado</Table.HeaderCell>
          <Table.HeaderCell>Data</Table.HeaderCell>
          <Table.HeaderCell>Sucesso</Table.HeaderCell>
          <Table.HeaderCell>Eficácia</Table.HeaderCell>
          <Table.HeaderCell>Opções</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {examUserHistoryByStartDate.map((examUser) => (
          <ExamHistoryListItem key={examUser.id} examUser={examUser} />
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="5">
            <Segment basic clearing floated="right">
              <Pagination
                defaultActivePage={page}
                onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
                totalPages={totalPages}
              />
            </Segment>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default observer(ExamHistoryList);
