export interface ICategoriesEnvelope {
  dataCollection: ICategory[];
  dataCount: number;
}

export interface ICategory {
  id: string;
  code: string;
  name: string;
  description: string;
  questionCount: number;
}

export class CategoryFilter {
  name?: string = "";
  sortBy: string = "";
}

export class CategoryFormValues {
  id?: string = undefined;
  code: string = "";
  name: string = "";
  description: string = "";

  constructor(init?: CategoryFormValues) {
    Object.assign(this, init);
  }
}

export class CategoryForSelect {
  key: string = "";
  text: string = "";
  value: string = "";

  constructor(key: string, text: string, value: string) {
    this.key = key;
    this.text = text;
    this.value = value;
  }
}
