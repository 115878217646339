import { IExamSubcategory } from "./examSubcategory";

export interface IExamCategory {
  id: string;
  examId: string;
  categoryId: string;
  categoryName: string;
  totalQuestions: number;
  examSubcategories: IExamSubcategory[];
}

export class ExamCategoryFormValues {
  id: string = "";
  examId: string = "";
  categoryId: string = "";
  totalQuestions: number = 0;
  examSubcategories = [];

  constructor(init?: ExamCategoryFormValues) {
    Object.assign(this, init);
  }
}