import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Button, Container, Image, Segment } from "semantic-ui-react";
import IExamUserAnswer from "../../../../app/models/examUserAnswer";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import ExamQuestionOptions from "./ExamQuestionOptions";
import { history } from "../../../..";

const ExamQuestionContent: React.FC<{ examUserAnswer: IExamUserAnswer }> = ({
  examUserAnswer,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { answerId, clearSelectedAnswer, setAnswered, getQuestion } =
    rootStore.examUserAnswerStore;
  const { currentQuestion, increaseCurrentQuestion, totalQuestions } =
    rootStore.examUsersStore;

  const handlerCopy = (e: any) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();

    alert("Não é permitido copiar");
  };

  const handleSetAnswered = (id: string, answerId: string) => {
    setAnswered(id, answerId).then(() => {
      clearSelectedAnswer();
      getQuestion(examUserAnswer.examUserId)
        .then(() => increaseCurrentQuestion())
        .catch((e) => {
          if (e.data.errors.examUserAnswer === "Não encontrado") {
            history.push(
              `/aluno/simulado/resultado/${examUserAnswer.examUserId}`
            );
          }
        });
    });
  };

  return (
    <Segment>
      {examUserAnswer && (
        <Segment padded>
          <Container
            fluid
            textAlign="left"
            style={{ fontSize: "11pt", paddingTop: "20px" }}
          >
            {examUserAnswer!.questionText.split("\n").map((i, key) => {
              return (
                <p onCopy={handlerCopy} key={key}>
                  {i}
                </p>
              );
            })}
            {examUserAnswer!.images.length > 0 && (
              <Fragment>
                <Image src={examUserAnswer!.images[0].url} size="big" />
              </Fragment>
            )}
          </Container>
          <ExamQuestionOptions
            questionAnswers={examUserAnswer!.questionAnswers}
          />
          <Segment basic clearing style={{ padding: "0 0 0 0" }}>
            <Button
              content="Responder"
              color="green"
              floated="left"
              onClick={() => handleSetAnswered(examUserAnswer.id, answerId)}
              disabled={answerId === ""}
            />
          </Segment>
        </Segment>
      )}
      <Segment basic textAlign="center">
        <strong>
          {currentQuestion}/{totalQuestions} Questões
        </strong>
      </Segment>
    </Segment>
  );
};

export default observer(ExamQuestionContent);
