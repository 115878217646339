import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Header, Icon, Segment } from "semantic-ui-react";
import LoadComponent from "../../../../app/layout/LoadComponent";
import { IUserPlan } from "../../../../app/models/userPlan";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import StudentProgressBySubcategoryList from "./StudentProgressBySubcategoryList";
import StudentProgressBySubcategoryFilter from "./StudentProgressBySubcategoryFilter";

const StudentProgressBySubcategory: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadStudentProgressBySubcategory, loadingInitialProgressBySubCategory } =
    rootStore.studentProgressBySubcategoryStore;
  const { loadCategoriesOptions } = rootStore.categoryStore;
  const { currentPlan } = rootStore.userPlanStore;

  const [userPlan, setUserPlan] = useState<IUserPlan>();

  useEffect(() => {
    currentPlan().then((result) => setUserPlan(result));
    loadCategoriesOptions(false);
    loadStudentProgressBySubcategory();
  }, [currentPlan, loadCategoriesOptions, loadStudentProgressBySubcategory]);

  if (loadingInitialProgressBySubCategory)
    return <LoadComponent content="Carregando inforrmações..." />;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <h1>Progresso por Subcategoria</h1>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <StudentProgressBySubcategoryFilter />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          {userPlan && (userPlan.planCode === "M3R" || userPlan.planCode === "SUB" || userPlan.planCode === "SEM" || userPlan.planCode === "ANO" || userPlan.planCode === "CCC" || userPlan.planCode === "EXP") ? (
            <StudentProgressBySubcategoryList />
          ) : (
            <Segment placeholder>
              <Header icon>
                <br />
                <Icon name="warning circle" />
                Oops
              </Header>
              <Segment basic style={{ textAlign: "center" }}>
                <p style={{ fontSize: "13pt" }}>
                  Para ter acesso a essa funcionalidade e a milhares de questões
                  para o Exame do CFC, CNAI e CNPC clique no link abaixo para
                  assinar o CFC Questões
                </p>
                <br />
                <Button
                  content="Assinar agora"
                  positive
                  href="https://cfcacademy.com.br/cfc-questoes-pv-5/"
                />
              </Segment>
            </Segment>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(StudentProgressBySubcategory);
