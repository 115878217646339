import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import LoadComponent from "../../../../app/layout/LoadComponent";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import ComparizonTile from "../ComparizonTile";
import GeneralProgress from "../GeneralProgress";
import PlanTile from "../PlanTile";
import SolvedQuestionsTile from "../SolvedQuestionsTile";
import WinFailTile from "../WinFailTile";

const MobileStudentDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { user } = rootStore.userStore;
  const { loadData, loadingInitial } = rootStore.studentDashboardStore;

  useEffect(() => {
    loadData("0");
  }, [loadData]);

  if (loadingInitial)
    return <LoadComponent content="Carregando informações..." />;

  return (
    <Fragment>
      <h1>Página Inicial</h1>
      <div style={{ textAlign: "right" }}>
        <h2>Bem vindo {user!.displayName.split(" ")[0]}</h2>
      </div>
      <PlanTile />
      <GeneralProgress />
      <SolvedQuestionsTile />
      <WinFailTile />
      <ComparizonTile />
    </Fragment>
  );
};

export default observer(MobileStudentDashboard);
