import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useState } from "react";
import {
  Button,
  Confirm,
  Container,
  Image,
  Label,
  Segment,
} from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import QuestionOptions from "./QuestionOptions";
import QuestionRate from "./QuestionRate";

const QuestionContent: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadRandomQuestion, question } = rootStore.questionStore;
  const {
    setAnswered,
    answerId,
    setSelectedAnswer,
    answerResponse,
    clearAnswer,
  } = rootStore.answerStore;

  const handleSetAnswered = (id: string) => {
    setAnswered(id);
  };

  const [state, setState] = useState({ open: false });
  const open = () => setState({ open: true });
  const close = () => setState({ open: false });

  const handleLoadRandomQuestion = () => {
    if (answerId !== "" && answerResponse == null) {
      open();
    } else {
      randomQuestion();
    }
  };

  const randomQuestion = () => {
    loadRandomQuestion();
    setSelectedAnswer("");
    clearAnswer();
  };

  const handlerCopy = (e: any) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();

    alert("Não é permitido copiar");
  };

  return (
    <Segment>
      <Confirm
        open={state.open}
        header="Aviso"
        content="DESEJA PROSSEGUIR PARA A PRÓXIMA QUESTĀO SEM RESPONDER A QUESTĀO ATUAL?"
        cancelButton="Nāo"
        confirmButton="Sim"
        onConfirm={() => {
          randomQuestion();
          close();
        }}
        onCancel={close}
      />
      <Segment padded style={{ paddingTop: "45px" }}>
        <Label attached="top" size="big">
          <span
            style={{
              paddingRight: "10px",
              fontStyle: "italic",
              fontSize: "11pt",
              fontWeight: 100,
            }}
          >
            {question!.code}
          </span>
          <span style={{ fontStyle: "bold", fontSize: "11pt" }}>
            {question!.subcategoryCode} - {question!.subcategoryName}
          </span>
          {question!.examiningBoardCode && (
            <Fragment>
              <br />
              <span
                style={{
                  paddingRight: "10px",
                  fontSize: "11pt",
                  fontWeight: 100,
                }}
              >
                {" "}
                Banca: {question!.examiningBoardCode}
              </span>
            </Fragment>
          )}
        </Label>
        <Container
          fluid
          textAlign="left"
          style={{ fontSize: "11pt", paddingTop: "20px" }}
        >
          {question!.text.split("\n").map((i, key) => {
            return (
              <p onCopy={handlerCopy} key={key}>
                {i}
              </p>
            );
          })}
          {question!.images.length > 0 && (
            <Fragment>
              <Image src={question!.images[0].url} size="big" />
            </Fragment>
          )}
        </Container>
        <QuestionOptions answers={question!.answers} />
        {!window.location.href.endsWith("/view") && (
          <Segment basic clearing style={{ padding: "0 0 0 0" }}>
            <Button
              content="Responder"
              color="green"
              floated="left"
              onClick={() => handleSetAnswered(answerId)}
              disabled={answerId === ""}
            />
            {answerResponse != null && (
              <Label
                content={
                  answerResponse ? "Resposta Correta" : "Resposta Errada"
                }
                size="large"
                color={answerResponse ? "green" : "red"}
                basic
                icon={
                  answerResponse ? "thumbs up outline" : "thumbs down outline"
                }
                pointing="left"
              />
            )}
            <Button
              icon="right arrow"
              labelPosition="right"
              content="Próxima Questão"
              color="blue"
              floated="right"
              onClick={() => handleLoadRandomQuestion()}
            />
          </Segment>
        )}
      </Segment>
      <QuestionRate questionId={question!.id} />
    </Segment>
  );
};

export default observer(QuestionContent);
