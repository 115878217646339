import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Button, Grid, Header, Icon, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import ReleasedExamsList from "./ReleasedExamsList";

const ReleasedExams: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadReleasedExams } = rootStore.examStore;
  const { isUserReachPlanCap } = rootStore.examUsersStore;
  const { openModal, closeModal } = rootStore.modalStore;

  useEffect(() => {
    const blockPageContent = (
      <Segment basic style={{ textAlign: "center" }}>
        <Header icon inverted>
          <Icon name="warning circle" />
          Oops
        </Header>
        <Segment basic>
          <p style={{ fontSize: "13pt" }}>
            Você atingiu o limite de simulados do seu plano.
          </p>
          <p style={{ fontSize: "13pt" }}>
            Quer liberar mais simulados para garantir sua aprovação?
          </p>
          <br />
          <Button
            content="Assinar agora"
            primary
            href="https://cfcacademy.com.br/cfc-questoes-pv-2/"
          />
        </Segment>
      </Segment>
    );

    loadReleasedExams();
    isUserReachPlanCap().then((response) => {
      if (response) {
        openModal(blockPageContent, "small", true, false);
      }
    });
    return () => {
      closeModal();
    };
  }, [loadReleasedExams, isUserReachPlanCap, openModal, closeModal]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <h1>Simulados</h1>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <ReleasedExamsList />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(ReleasedExams);
