import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Pagination, Segment, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import ModuleListItem from "./ModuleListItem";

const ModuleList: React.FC<{ id: string }> = ({ id }) => {
  const rootStore = useContext(RootStoreContext);
  const { moduleByName, page, totalPages, setPage, setPredicate, loadModules } =
    rootStore.moduleStore;

  const onPageChange = (e: any, pageInfo: any) => {
    setPage(pageInfo.activePage);
    setPredicate(id);
    loadModules();
  };

  return (
    <Table unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={10}>Módulo</Table.HeaderCell>
          <Table.HeaderCell width={2}>Ordem</Table.HeaderCell>
          <Table.HeaderCell width={4}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {moduleByName.map((module) => (
          <ModuleListItem key={module.id} module={module} />
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="3">
            <Segment basic clearing floated="right">
              <Pagination
                defaultActivePage={page}
                onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
                totalPages={totalPages}
              />
            </Segment>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default observer(ModuleList);
