import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { Button, Form, Grid } from "semantic-ui-react";
import TextInput from "../../../app/common/form/TextInput";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { ExamFormValues } from "../../../app/models/exam";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import SelectInput from "../../../app/common/form/SelectInput";
import { Link } from "react-router-dom";
import DropdownInput from "../../../app/common/form/DropdownInput";
import { ExaminingBoardForSelect } from "../../../app/models/examiningBoard";

const validate = combineValidators({
  name: isRequired({ message: "Nome é obrigatório" }),
});

const ExamForm: React.FC<{ id: string }> = ({ id }) => {
  const rootStore = useContext(RootStoreContext);
  const { createExam, loadExam, submitting, editExam, deleteExam, target } =
    rootStore.examStore;
  const { loadExaminingBoardsForSelect } = rootStore.examiningBoardStore;

  const [examiningBoardsForSelect, setExaminingBoardsForSelect] = useState<
    ExaminingBoardForSelect[]
  >([]);

  useEffect(() => {
    if (id) {
      loadExam(id).then((exam) => setExam(new ExamFormValues(exam)));
    } else {
      setExam(new ExamFormValues());
    }

    loadExaminingBoardsForSelect().then((examiningBoards: any) => {
      let examiningBoardList: ExaminingBoardForSelect[] = [];
      examiningBoardList.push(new ExaminingBoardForSelect('', '', ''));

      examiningBoards?.forEach((exBoard: any) => {
        let examiningBoard = new ExaminingBoardForSelect(
          exBoard.id,
          exBoard.code,
          exBoard.id
        );
        examiningBoardList.push(examiningBoard);
      });

      examiningBoardList.sort((a, b) => {
        return a.text.localeCompare(b.text);
      });

      setExaminingBoardsForSelect(examiningBoardList);
    });
  }, [loadExam, id, loadExaminingBoardsForSelect]);

  const [exam, setExam] = useState(new ExamFormValues());

  const releasedOptions = [
    { key: false, text: "Não", value: false },
    { key: true, text: "Sim", value: true },
  ];

  const handleFinalFormSubmit = (values: any) => {
    if (!values.id) {
      createExam(values);
    } else {
      editExam(values);
    }
  };

  return (
    <FinalForm
      validate={validate}
      initialValues={exam}
      onSubmit={handleFinalFormSubmit}
      render={({ handleSubmit, invalid, pristine }) => (
        <Form onSubmit={handleSubmit}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={14}>
                <Field
                  name="name"
                  title="Nome"
                  placeholder="Nome"
                  value={exam.name}
                  component={TextInput}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Field
                  name="released"
                  title="Liberado para Aluno"
                  placeholder="Liberado"
                  component={SelectInput}
                  options={releasedOptions}
                  value={exam.released.toString()}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={12}>
                <Field
                  component={DropdownInput}
                  options={examiningBoardsForSelect}
                  name="examiningBoardId"
                  title="Banca"
                  placeholder="Banca"
                  value={exam.examiningBoardId}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Field
                  name="durationTime"
                  title="Duração (em minutos)"
                  placeholder="Duração"
                  value={exam.durationTime.toString()}
                  component={TextInput}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Field
                  name="totalQuestions"
                  title="Quantidade de Questões"
                  placeholder="Quantidade de Questões"
                  value={exam.totalQuestions.toString()}
                  component={TextInput}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Field
                  name="description"
                  title="Descrição"
                  placeholder="Descrição"
                  value={exam.description}
                  rows={4}
                  component={TextAreaInput}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Button
                  loading={submitting}
                  disabled={invalid || pristine}
                  floated="right"
                  positive
                  type="submit"
                  content="Salvar"
                />
                <Button
                  as={Link}
                  to="/admin/simulados"
                  floated="right"
                  type="button"
                  content="Cancelar"
                />
                {exam.id && (
                  <Button
                    loading={target === exam.id && submitting}
                    onClick={(e) => {
                      deleteExam(e, exam.id!);
                      setExam(new ExamFormValues());
                    }}
                    floated="right"
                    color="red"
                    content="Excluir"
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    />
  );
};

export default observer(ExamForm);
