import { action, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { RootStore } from "./rootStore";

export default class UserAnswerStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable submitting = false;

  @action deleteAllAnswersByStudent = async () => {
    this.submitting = true;
    try {
      await agent.UserAnswers.deleteAllByStudent();
      runInAction("deleting all answers by student", () => {
        this.submitting = false;
      });
      toast.success("Respostas das questões excluidas com sucesso!");
    } catch (error) {
      runInAction("delete all answers by student error", () => {
        this.submitting = false;
      });
      toast.error("Erro ao deletar respostas das questões");
    }
  };
}
