import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import IExamUserAnswer from "../../../../../app/models/examUserAnswer";
import { Message } from "semantic-ui-react";

const AnsweredQuestionProfComment: React.FC<{ question: IExamUserAnswer }> = ({
  question,
}) => {
  return (
    <Fragment>
      <h4>Comentário do Professor</h4>
      {question.comment.length === 0 && (
        <Message
          icon="warning circle"
          header="Oops"
          content="O Professor ainda não comentou essa questão."
        />
      )}
      <div>
        <div dangerouslySetInnerHTML={{ __html: question!.comment }} />
      </div>
    </Fragment>
  );
};

export default observer(AnsweredQuestionProfComment);
