import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";

const CountDown: React.FC<{
  startDate: Date;
  initMinutes: number;
  examUserId: string;
}> = ({ startDate, initMinutes, examUserId }) => {
  const rootStore = useContext(RootStoreContext);
  const { stopExam } = rootStore.examUsersStore;

  const [num, setNum] = useState(initMinutes);
  let intervalRef = useRef<any>();

  useEffect(() => {
    let now = new Date();
    let sDate = new Date(startDate);
    var diff = Math.abs(now.getTime() - sDate.getTime());
    var minutes = Math.floor(diff / 1000 / 60);
    setNum(initMinutes - minutes);
    const decreaseNum = () => {
      if (num <= 0) {
        stopExam(examUserId);
      }
      setNum((prev) => prev - 1);
    };
    intervalRef.current = setInterval(() => decreaseNum(), 60000);

    return () => clearInterval(intervalRef.current);
  }, [startDate, initMinutes, stopExam, examUserId, num]);

  return (
    <Segment textAlign="center">
      <strong>Tempo restante: {num} minuto(s)</strong>
    </Segment>
  );
};

export default observer(CountDown);
