import { observer } from "mobx-react-lite";
import _ from 'lodash';
import React, { Fragment, useContext } from "react";
import { Message, Pagination, Segment, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import StudentProgressByExaminingBoardListItem from "./StudentProgressByExaminingBoardListItem";

const StudentProgressByExaminingBoardList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadStudentProgressByExaminingBoard,
    studentProgressByExaminingBoard,
    studentProgressByExaminingBoardPage,
    studentProgressByExaminingBoardTotalPages,
    studentProgressByExaminingBoardSetPage
  } = rootStore.studentProgressByExaminingBoardStore;

  const [state, dispatch] = React.useReducer(exampleReducer, {
    column: null,
    data: studentProgressByExaminingBoard,
    direction: null,
  });
  const { column, data, direction } = state;

  function exampleReducer(state: any, action: any) {
    switch (action.type) {
      case "CHANGE_SORT":
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction:
              state.direction === "ascending" ? "descending" : "ascending",
          };
        }

        return {
          column: action.column,
          data: _.sortBy(state.data, [action.column]),
          direction: "ascending",
        };
      default:
        throw new Error();
    }
  }

  const onPageChange = (e: any, pageInfo: any) => {
    studentProgressByExaminingBoardSetPage(pageInfo.activePage);
    loadStudentProgressByExaminingBoard();
  };

  return (
    <Fragment>
      {studentProgressByExaminingBoard.length > 0 ? (
        <Table columns={16} striped sortable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                width={6}
                sorted={column === "examiningBoardName" ? direction : null}
                onClick={() =>
                  dispatch({ type: "CHANGE_SORT", column: "examiningBoardName" })
                }
              >
                Banca
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="center"
                sorted={column === "totalAnsweredQuestions" ? direction : null}
                onClick={() =>
                  dispatch({ type: "CHANGE_SORT", column: "totalAnsweredQuestions" })
                }
              >
                Respondidas
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="center"
                sorted={column === "totalWinAnsweredQuestions" ? direction : null}
                onClick={() =>
                  dispatch({ type: "CHANGE_SORT", column: "totalWinAnsweredQuestions" })
                }
              >
                Acertos
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign="center"
                sorted={column === "totalFailAnsweredQuestions" ? direction : null}
                onClick={() =>
                  dispatch({ type: "CHANGE_SORT", column: "totalFailAnsweredQuestions" })
                }
              >
                Erros
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Sucesso</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.map((item: any) => (
              <StudentProgressByExaminingBoardListItem
                key={item.examiningBoardId}
                item={item}
              />
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="16">
                <Segment basic clearing floated="right">
                  <Pagination
                    defaultActivePage={studentProgressByExaminingBoardPage}
                    onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
                    totalPages={studentProgressByExaminingBoardTotalPages}
                  />
                </Segment>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      ) : (
        <Message
          icon="warning circle"
          header="Oops"
          content="Ainda não há dados suficientes"
        />
      )}
    </Fragment>
  );
};

export default observer(StudentProgressByExaminingBoardList);
