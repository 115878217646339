import { action, computed, observable, runInAction } from "mobx";
import { SyntheticEvent } from "react";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { ICourse } from "../models/course";
import { RootStore } from "./rootStore";

const LIMIT = 10;

export default class CourseStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable courseRegistry = new Map();
  @observable course: ICourse | null = null;
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";
  @observable loading = false;
  @observable courseCount = 0;
  @observable page = 1;
  @observable predicate = new Map();

  @action setPredicate = () => {
    this.predicate.clear();
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page > 1 ? (this.page - 1) * LIMIT : 0}`);

    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.courseCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @computed get courseByName() {
    const courses = Array.from(this.courseRegistry.values());
    return courses;
  }

  @action loadCourses = async () => {
    this.loadingInitial = true;
    try {
      this.courseRegistry.clear();
      const coursesEnvelope = await agent.Courses.list(this.axiosParams);
      const { dataCollection, dataCount } = coursesEnvelope;
      runInAction("loading courses", () => {
        dataCollection.forEach((course) => {
          this.courseRegistry.set(course.id, course);
        });
        this.courseCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load courses error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar cursos");
    }
  };

  @action loadCourse = async (id: string) => {
    this.loadingInitial = true;
    try {
      let course = await agent.Courses.details(id);
      runInAction("loading course", () => {
        this.course = course;
        this.courseRegistry.set(course.id, course);
        this.loadingInitial = false;
      });
      return course;
    } catch (error) {
      runInAction("load course error", () => {
        this.loadingInitial = false;
      });
    }
  };

  @action createCourse = async (course: ICourse) => {
    this.submitting = true;
    try {
      await agent.Courses.create(course);
      runInAction("creating course", () => {
        this.submitting = false;
      });
    } catch (error) {
      runInAction("create course error", () => {
        this.submitting = false;
      });
      toast.error("Erro ao criar curso");
    }
  };

  @action editCourse = async (course: ICourse) => {
    this.submitting = true;
    try {
      await agent.Courses.update(course);
      runInAction("editing course", () => {
        this.courseRegistry.set(course.id, course);
        this.course = course;
        this.submitting = false;
      });
    } catch (error) {
      runInAction("edit course error", () => {
        this.submitting = false;
      });
      toast.error("Problem submitting data");
    }
  };

  @action deleteCourse = async (
    event: SyntheticEvent<HTMLButtonElement>,
    id: string
  ) => {
    this.submitting = true;
    this.target = event.currentTarget.name;
    try {
      await agent.Courses.delete(id);
      runInAction("deleting course", () => {
        this.courseRegistry.delete(id);
        this.submitting = false;
        this.target = "";
      });
    } catch (error) {
      runInAction("delete course error", () => {
        this.submitting = false;
        this.target = "";
      });
    }
  };
}
