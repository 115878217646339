import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Card } from "semantic-ui-react";
import { IExam } from "../../../../app/models/exam";

const MobileReleasedExamsListItem: React.FC<{ exam: IExam }> = ({ exam }) => {
  return (
    <Card centered fluid>
      <Card.Content>
        <Card.Header>{exam.name}</Card.Header>
        <Card.Meta>
          {exam.totalQuestions} Questões / {exam.durationTime} Minutos
        </Card.Meta>
        <Card.Description>
          <div>{exam.description}</div>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button
            basic
            color="green"
            content="Detalhes"
            as={Link}
            to={`/aluno/simulados/${exam.id}`}
          />
        </div>
      </Card.Content>
    </Card>
  );
};

export default observer(MobileReleasedExamsListItem);
