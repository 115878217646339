import { action, computed, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { ILesson } from "../models/lesson";
import { RootStore } from "./rootStore";

const LIMIT = 10;

export default class LessonStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable lessonRegistry = new Map();
  @observable lesson: ILesson | null = null;
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";
  @observable loading = false;
  @observable lessonCount = 0;
  @observable page = 1;
  @observable predicate = new Map();

  @action setPredicate = () => {
    this.predicate.clear();
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page > 1 ? (this.page - 1) * LIMIT : 0}`);

    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.lessonCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @computed get lessonByName() {
    const lessons = Array.from(this.lessonRegistry.values());
    return lessons;
  }

  @action loadLessons = async (courseId: string, moduleId: string) => {
    this.loadingInitial = true;
    this.lessonRegistry.clear();
    try {
      const params = new URLSearchParams();

      if (courseId !== "") {
        params.append("courseId", courseId);
      }

      if (moduleId !== "") {
        params.append("moduleId", moduleId);
      }

      const lessonEnvelope = await agent.Lessons.list(params);
      const { dataCollection, dataCount } = lessonEnvelope;
      runInAction("loading lessons", () => {
        dataCollection.forEach((lesson) => {
          this.lessonRegistry.set(lesson.id, lesson);
        });
        this.lessonCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load lessons error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar aulas");
    }
  };

  @action loadLesson = async (id: string) => {
    this.loadingInitial = true;
    let lesson = this.getLesson(id);
    if (lesson) {
      this.lesson = lesson;
      this.loadingInitial = false;
      return lesson;
    } else {
      try {
        lesson = await agent.Lessons.details(id);
        runInAction("loading lesson", () => {
          this.lesson = lesson;
          this.loadingInitial = false;
        });
        return lesson;
      } catch (error) {
        runInAction("load exam error", () => {
          this.loadingInitial = false;
        });
        toast.error("Erro ao carregar aula");
      }
    }
  };

  getLesson = (id: string) => {
    return this.lessonRegistry.get(id);
  };

  @action createLesson = async (lesson: ILesson) => {
    this.submitting = true;
    try {
      if (lesson.documentUrls === "" && lesson.videoUrl === "") {
        toast.error("Vídeo é obrigatório ou adicione pelo menos um documento");
        this.submitting = false;
        return;
      }

      lesson.order = Number(lesson.order);
      await agent.Lessons.create(lesson);
      runInAction("creating lesson", () => {
        this.submitting = false;
      });
    } catch (error) {
      runInAction("create lesson error", () => {
        this.submitting = false;
      });
      toast.error("Erro ao criar aula");
    }
  };

  @action editLesson = async (lesson: ILesson) => {
    this.submitting = true;
    try {
      lesson.order = Number(lesson.order);
      await agent.Lessons.update(lesson);
      runInAction("editing lesson", () => {
        this.lessonRegistry.set(lesson.id, lesson);
        this.lesson = lesson;
        this.submitting = false;
      });
      return lesson;
    } catch (error) {
      runInAction("edit category error", () => {
        this.submitting = false;
      });
      toast.error("Problem submitting data");
    }
  };

  @action deleteLesson = async (id: string) => {
    this.submitting = true;
    try {
      await agent.Lessons.delete(id);
      runInAction("deleting lesson", () => {
        this.lessonRegistry.delete(id);
        this.submitting = false;
      });
    } catch (error) {
      runInAction("delete lesson error", () => {
        this.submitting = false;
      });
    }
  };
}
