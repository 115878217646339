import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  Comment,
  Form,
  Grid,
  Label,
  Segment,
} from "semantic-ui-react";
import { Form as FinalForm } from "react-final-form";
import {
  ILessonComment,
  LessonCommentReplyFormValues,
} from "../../app/models/lessonComment";
import { RootStoreContext } from "../../app/stores/rootStore";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";

const LessonCommentListItem: React.FC<{ comment: ILessonComment }> = ({
  comment,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { selectComment, removeSelectedComment, loadComments, replyCreate } =
    rootStore.lessonCommentStore;

  const [checked, setChecked] = useState(false);
  const [replyVisible, setReplyVisible] = useState(false);
  const [newComment] = useState(new LessonCommentReplyFormValues());
  const [value, setValue] = useState("");

  const onCheckboxChange = (comment: ILessonComment) => {
    if (checked) {
      removeSelectedComment(comment.id);
    } else {
      selectComment(comment);
    }
    setChecked(!checked);
  };

  const handleFinalFormSubmit = (values: any) => {
    if (value === "" || value === undefined) {
      toast.error("Comentário é obrigatório");
    } else {
      replyCreate(comment!.lessonId, comment!.id, value).then(() =>
        loadComments()
      );
      setValue("");
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        {
          color: ["red", "blue", "green", "orange", "violet", "yellow", "grey"],
        },
        {
          background: [
            "red",
            "blue",
            "green",
            "orange",
            "violet",
            "yellow",
            "grey",
          ],
        },
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "color",
    "background",
    "list",
    "bullet",
    "link",
  ];

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={1}>
          <Checkbox
            style={{ paddingTop: "3px" }}
            onChange={() => onCheckboxChange(comment)}
            checked={checked}
          />
        </Grid.Column>
        <Grid.Column width={15}>
          <Comment>
            <Comment.Avatar
              src={
                comment.isProfessorAnswer
                  ? "/assets/professor.jpg"
                  : comment.gender === "F"
                  ? "/assets/female.jpg"
                  : "/assets/male.jpg"
              }
            />
            <Comment.Content>
              <div>
                <strong>{comment.displayName}</strong> ({comment.username}){" "}
                comentou em <strong>{comment.lessonName}</strong>{" "}
                {comment.answeredByProfessor && (
                  <Label as="a" content="Respondido" icon="check" />
                )}
              </div>
              <Comment.Metadata>
                <div>{comment.postedDate}</div>
              </Comment.Metadata>
              <Comment.Text>
                {comment.isProfessorAnswer ? (
                  <div dangerouslySetInnerHTML={{ __html: comment.text }} />
                ) : (
                  <div>
                    {comment.text.split("\n").map((i, key) => {
                      return <p key={key}>{i}</p>;
                    })}
                  </div>
                )}
              </Comment.Text>
              <Comment.Actions>
                <Link to="#" onClick={() => setReplyVisible(!replyVisible)}>
                  Responder
                </Link>
              </Comment.Actions>
            </Comment.Content>
          </Comment>
          {replyVisible && (
            <FinalForm
              initialValues={newComment}
              onSubmit={handleFinalFormSubmit}
              render={({ handleSubmit }) => (
                <Form reply onSubmit={handleSubmit}>
                  <br />
                  <div className="text-editor">
                    <ReactQuill
                      theme="snow"
                      value={value}
                      onChange={setValue}
                      modules={modules}
                      formats={formats}
                    />
                  </div>
                  <br />
                  <Button
                    content="Responder"
                    labelPosition="left"
                    icon="edit"
                    primary
                  />
                </Form>
              )}
            />
          )}
          {comment.parentLessonCommentId && (
            <Segment secondary>
              <h4>Comentário citado:</h4>
              <div>
                <strong>{comment.parentLessonCommentDisplayName}</strong> (
                {comment.parentLessonCommentUsername}) comentou em{" "}
                {comment.parentLessonCommentPostedDate}:
                <div>
                  {comment.isProfessorAnswer ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: comment.parentLessonCommentText,
                      }}
                    />
                  ) : (
                    <div>
                      {comment.parentLessonCommentText
                        .split("\n")
                        .map((i, key) => {
                          return <p key={key}>{i}</p>;
                        })}
                    </div>
                  )}
                </div>
              </div>
            </Segment>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(LessonCommentListItem);
