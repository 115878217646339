import { action, computed, observable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { ISubcategory, SubcategoryFilter } from "../models/subcategory";
import { RootStore } from "./rootStore";
import { history } from "../..";
import { SyntheticEvent } from "react";

const LIMIT = 10;

export default class SubcategoryStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.subcategoryRegistry.clear();
        this.loadSubcategories();
      }
    );
  }

  @observable subcategoryRegistry = new Map();
  @observable subcategory: ISubcategory | null = null;
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";
  @observable loading = false;
  @observable subcategoryCount = 0;
  @observable page = 1;
  @observable predicate = new Map();
  @observable subcategoryFilter = new SubcategoryFilter();

  @action setPredicate = () => {
    this.predicate.clear();

    if (this.subcategoryFilter.categoryId) {
      this.predicate.set("categoryId", this.subcategoryFilter.categoryId);
    }

    if (this.subcategoryFilter.name) {
      this.predicate.set("name", this.subcategoryFilter!.name);
    }

    if (this.subcategoryFilter.sortBy) {
      this.predicate.set("sortby", this.subcategoryFilter!.sortBy);
    }
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page > 1 ? (this.page - 1) * LIMIT : 0}`);
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });

    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.subcategoryCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @computed get subcategoriesByName() {
    const subcategories = Array.from(this.subcategoryRegistry.values());
    return subcategories;
  }

  @computed get subcategoriesOptions() {
    const subcategories = Array.from(this.subcategoryRegistry.values());
    return subcategories.map((x) => ({ key: x.id, value: x.id, text: x.name }));
  }

  @action loadSubcategories = async () => {
    this.loadingInitial = true;
    try {
      const subcategoriesEnvelope = await agent.Subcategories.list(
        this.axiosParams
      );
      const { dataCollection, dataCount } = subcategoriesEnvelope;
      runInAction("loading subcategories", () => {
        this.subcategoryRegistry.clear();
        dataCollection.forEach((subcategory) => {
          this.subcategoryRegistry.set(subcategory.id, subcategory);
        });
        this.subcategoryCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load subcategories error", () => {
        this.loadingInitial = false;
      });
    }
  };

  @action loadSubcategoriesForSelect = async (categoryId?: string) => {
    try {
      const params = new URLSearchParams();
      params.append("limit", "1000");
      if (categoryId) {
        params.append("categoryId", categoryId);
      }
      const subcategoriesEnvelope = await agent.Subcategories.list(params);
      const { dataCollection } = subcategoriesEnvelope;
      return dataCollection;
    } catch (error) {
    }
  };

  @action loadSubcategoriesOptions = async (onlyWithQuestions: boolean, categoryId?: string) => {
    try {
      this.subcategoryRegistry.clear();
      const subcategoriesOptions = await agent.Subcategories.listForSelect(onlyWithQuestions, categoryId);
      runInAction(() => {
        subcategoriesOptions.forEach((subcategory) => {
          this.subcategoryRegistry.set(subcategory.id, subcategory);
        });
      });
    } catch (error) {
      toast.error("Erro ao carregar subcategorias");
    }
  };

  @action loadSubcategory = async (id: string) => {
    let subcategory = this.getSubcategory(id);
    if (subcategory) {
      this.subcategory = subcategory;
      return subcategory;
    } else {
      this.loadingInitial = true;
      try {
        subcategory = await agent.Subcategories.details(id);
        runInAction("loading subcategory", () => {
          this.subcategory = subcategory;
          this.subcategoryRegistry.set(subcategory.id, subcategory);
          this.loadingInitial = false;
        });
        return subcategory;
      } catch (error) {
        runInAction("load subcategory error", () => {
          this.loadingInitial = false;
        });
      }
    }
  };

  @action clearSubcategory = () => {
    this.subcategory = null;
  };

  getSubcategory = (id: string) => {
    return this.subcategoryRegistry.get(id);
  };

  @action createSubcategory = async (subcategory: ISubcategory) => {
    this.submitting = true;
    try {
      await agent.Subcategories.create(subcategory);
      runInAction("creating subcategory", () => {
        this.submitting = false;
      });
      history.push("/admin/subcategorias/");
    } catch (error) {
      runInAction("create subcategory error", () => {
        this.submitting = false;
      });
      toast.error(error.data.errors.code);
    }
  };

  @action editSubcategory = async (subcategory: ISubcategory) => {
    this.submitting = true;
    try {
      await agent.Subcategories.update(subcategory);
      runInAction("editing subcategory", () => {
        this.subcategoryRegistry.set(subcategory.id, subcategory);
        this.subcategory = subcategory;
        this.submitting = false;
      });
      history.push("/admin/subcategorias/");
    } catch (error) {
      runInAction("edit subcategory error", () => {
        this.submitting = false;
      });
      toast.error("Problem submitting data");
    }
  };

  @action deleteSubcategory = async (
    event: SyntheticEvent<HTMLButtonElement>,
    id: string
  ) => {
    this.submitting = true;
    this.target = event.currentTarget.name;
    try {
      await agent.Subcategories.delete(id);
      runInAction("deleting subcategory", () => {
        this.subcategoryRegistry.delete(id);
        this.submitting = false;
        this.target = "";
      });
      history.push("/admin/subcategorias");
    } catch (error) {
      runInAction("delete subcategory error", () => {
        this.submitting = false;
        this.target = "";
      });
    }
  };
}
