import { action, computed, observable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { RootStore } from "./rootStore";

const LIMIT = 10;

export default class StudentProgressByCategoryStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.studentProgressByCategoryPredicate.keys(),
      () => {
        this.studentProgressByCategoryPage = 0;
        this.studentProgressByCategoryRegistry.clear();
        this.loadStudentProgressByCategory();
      }
    );
  }

  @observable loadingInitialProgressByCategory = false;

  @observable studentUsername = "";
  @observable categoryId = "";
  @observable sortBy = "";

  @observable studentProgressByCategoryRegistry = new Map();
  @observable studentProgressByCategoryCount = 0;
  @observable studentProgressByCategoryPage = 1;
  @observable studentProgressByCategoryPredicate = new Map();

  @computed get studentProgressByCategoryAxiosParams() {
    const params = new URLSearchParams();
    params.append(
      "username",
      this.studentUsername
        ? this.studentUsername
        : this.rootStore.userStore.user!.username
    );
    params.append("sortBy", this.sortBy);
    params.append("limit", String(LIMIT));
    params.append(
      "offset",
      `${
        this.studentProgressByCategoryPage > 1
          ? (this.studentProgressByCategoryPage - 1) * LIMIT
          : 0
      }`
    );

    return params;
  }

  @computed get studentProgressByCategoryTotalPages() {
    return Math.ceil(this.studentProgressByCategoryCount / LIMIT);
  }

  @action setStudentUsernameForProgressBycategory = (username: string) => {
    this.studentUsername = username;
  };

  @action setSortByForProgressBycategory = (sortBy: string) => {
    this.sortBy = sortBy;
  }

  @action studentProgressByCategorySetPage = (page: number) => {
    this.studentProgressByCategoryPage = page;
  };

  @computed get studentProgressByCategory() {
    const spByCat = Array.from(this.studentProgressByCategoryRegistry.values());
    return spByCat;
  }

  @action loadStudentProgressByCategory = async () => {
    this.loadingInitialProgressByCategory = true;
    try {
      this.studentProgressByCategoryRegistry.clear();
      const studentProgressEnvelope =
        await agent.StudentProgress.detailsByCategory(
          this.studentProgressByCategoryAxiosParams
        );
      const { dataCollection, dataCount } = studentProgressEnvelope;

      runInAction(() => {
        dataCollection.forEach((item) => {
          this.studentProgressByCategoryRegistry.set(item.categoryId, item);
        });
        this.studentProgressByCategoryCount = dataCount;
        this.loadingInitialProgressByCategory = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingInitialProgressByCategory = false;
      });
      toast.error(
        "Não foi possível carregar informações do progresso por categoria"
      );
    }
  };
}
