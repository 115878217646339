import React, { Fragment, useContext, useEffect } from "react";
import { Container } from "semantic-ui-react";
import NavBar from "../../features/nav/NavBar";
import { observer } from "mobx-react-lite";
import "mobx-react-lite/batchingForReactDom";
import {
  Route,
  withRouter,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import NotFound from "./NotFound";
import { ToastContainer } from "react-toastify";
import ModalContainer from "../common/modals/ModalContainer";
import CategoryDashboard from "../../features/categories/dashboard/CategoryDashboard";
import CategoryForm from "../../features/categories/form/CategoryForm";
import SubcategoryDashboard from "../../features/subcategories/SubcategoryDashboard";
import SubcategoryForm from "../../features/subcategories/form/SubcategoryForm";
import QuestionDashboard from "../../features/questions/QuestionDashboard";
import QuestionItem from "../../features/questions/QuestionItem";
import { RootStoreContext } from "../stores/rootStore";
import LoadComponent from "./LoadComponent";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../../features/dashboard/Dashboard";
import ResetPasswordForm from "../../features/user/ResetPasswordForm";
import Question from "../../features/student/questions/Question";
import EmailConfirmation from "../../features/user/EmailConfirmation";
import CommentDashboard from "../../features/comments/CommentDashboard";
import { history } from "../..";
import Footer from "./Footer";
import UnderMaintenance from "./UnderMaintenance";
import ResendWelcomeEmail from "../../features/user/ResendWelcomeEmail";
import StudentDashboard from "../../features/student/dashboard/desktop/StudentDashboard";
import StudentProgressByCategory from "../../features/student/progress/byCategory/StudentProgressByCategory";
import StudentProgressBySubcategory from "../../features/student/progress/bySubcategory/StudentProgressBySubcategory";
import ExamDashboard from "../../features/exam/ExamDashboard";
import ExamItem from "../../features/exam/ExamItem";
import ReleasedExamItem from "../../features/student/exams/ReleasedExamItem";
import ExamQuestionDashboard from "../../features/student/exams/examQuestion/ExamQuestionDashboard";
import ExamResult from "../../features/student/exams/examQuestion/Result/ExamResult";
import ExamHistory from "../../features/student/exams/examHistory/ExamHistory";
import AnsweredQuestionList from "../../features/student/exams/examHistory/answeredQuestion/AnsweredQuestionList";
import PlansDashboard from "../../features/student/plans/PlansDashboard";
import UserDashboard from "../../features/user/dashboard/UserDashboard";
import UserProfile from "../../features/user/dashboard/UserProfile";
import QuestionView from "../../features/student/questions/QuestionView";
import LessonForm from "../../features/lessons/form/LessonForm";
import CourseDashboard from "../../features/courses/CourseDashboard";
import ModuleDashboard from "../../features/modules/ModuleDashboard";
import LessonDashboard from "../../features/lessons/LessonDashboard";
import LessonCommentDashboard from "../../features/lessonComments/LessonCommentDashboard";
import ChangePasswordForm from "../../features/user/ChangePasswordForm";
import MobileHomePage from "../../features/home/mobile/MobileHomePage";
import MobileUnderMaintenance from "./MobileUnderMaintenance";
import MobileNavBar from "../../features/nav/MobileNavBar";
import HomePage from "../../features/home/desktop/HomePage";
import MobileStudentDashboard from "../../features/student/dashboard/mobile/MobileStudentDashboard";
import MobileQuestion from "../../features/student/questions/mobile/MobileQuestion";
import MobileReleasedExams from "../../features/student/exams/mobile/MobileReleasedExams";
import ReleasedExams from "../../features/student/exams/ReleasedExams";
import ExaminingBoardDashboard from "../../features/examiningBoards/dashboard/ExaminingBoardDashboard";
import ExaminingBoardForm from "../../features/examiningBoards/form/ExaminingBoardForm";
import StudentProgressByExaminingBoard from "../../features/student/progress/byExaminingBoard/StudentProgressByExaminingBoard";

const internalContainerStyle = {
  width: "100%",
  marginTop: "5em",
  paddingLeft: "2%",
  paddingRight: "2%",
  paddingBottom: "15%",
};

const App: React.FC<RouteComponentProps> = ({ location }) => {
  const rootStore = useContext(RootStoreContext);
  const { setAppLoaded, token, appLoaded } = rootStore.commonStore;
  const { getUser, logout, user } = rootStore.userStore;
  const { openModal } = rootStore.modalStore;

  useEffect(() => {
    if (token) {
      getUser()
        .catch(() => logout())
        .finally(() => setAppLoaded());
    } else {
      setAppLoaded();
    }
  }, [getUser, logout, setAppLoaded, token]);

  if (!appLoaded) return <LoadComponent content="Carregando aplicativo..." />;

  if (user) {
    if (user.hasTempPassword) {
      openModal(<ChangePasswordForm />, "small", false, true);
    }

    if (user.role === "Student" && location.pathname.startsWith("/admin/")) {
      history.push("/");
    }
  }

  if (window.innerWidth <= 760) {
    return (
      <Fragment>
        <ModalContainer />
        <ToastContainer position="bottom-right" />
        <Route exact path="/" component={MobileHomePage} />
        <Route exact path="/manutencao" component={MobileUnderMaintenance} />
        <Route path="/redefinirsenha" component={ResetPasswordForm} />
        <Route exact path="/confirmaremail" component={EmailConfirmation} />
        <Route path="/reenviaremailboasvindas" component={ResendWelcomeEmail} />
        <Route
          path={"/aluno/(.+)"}
          render={() => (
            <Fragment>
              <MobileNavBar isAdmin={false} />
              <Container style={internalContainerStyle}>
                <Switch>
                  <PrivateRoute
                    exact
                    path="/aluno/dashboard"
                    component={MobileStudentDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/aluno/questoes"
                    component={MobileQuestion}
                  />
                  <PrivateRoute
                    exact
                    path="/aluno/simulados"
                    component={MobileReleasedExams}
                  />
                  <PrivateRoute
                    key={location.key}
                    path={"/aluno/simulados/:id"}
                    component={ReleasedExamItem}
                  />
                  <PrivateRoute
                    key={location.key}
                    path={"/aluno/simulado/resultado/:examuserid"}
                    component={ExamResult}
                  />
                  <PrivateRoute
                    key={location.key}
                    path={"/aluno/simulado/:examuserid"}
                    component={ExamQuestionDashboard}
                  />
                </Switch>
              </Container>
              <Footer />
            </Fragment>
          )}
        />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <ModalContainer />
        <ToastContainer position="bottom-right" />
        <Route exact path="/" component={HomePage} />
        <Route exact path="/manutencao" component={UnderMaintenance} />
        <Route path="/redefinirsenha" component={ResetPasswordForm} />
        <Route exact path="/confirmaremail" component={EmailConfirmation} />
        <Route path="/reenviaremailboasvindas" component={ResendWelcomeEmail} />
        <Route
          path={"/aluno/(.+)"}
          render={() => (
            <Fragment>
              <NavBar isAdmin={false} />
              <Container style={internalContainerStyle}>
                <Switch>
                  <PrivateRoute
                    exact
                    path="/aluno/dashboard"
                    component={StudentDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/aluno/questoes"
                    component={Question}
                  />
                  <PrivateRoute
                    exact
                    path="/aluno/questoes/:id/view"
                    component={QuestionView}
                  />
                  <PrivateRoute
                    exact
                    path="/aluno/progresso/categoria"
                    component={StudentProgressByCategory}
                  />
                  <PrivateRoute
                    exact
                    path="/aluno/progresso/subcategoria"
                    component={StudentProgressBySubcategory}
                  />
                  <PrivateRoute
                    exact
                    path="/aluno/progresso/banca"
                    component={StudentProgressByExaminingBoard}
                  />
                  <PrivateRoute
                    exact
                    path="/aluno/simulados"
                    component={ReleasedExams}
                  />
                  <PrivateRoute
                    exact
                    path="/aluno/resultadosimulados"
                    component={ExamHistory}
                  />
                  <PrivateRoute
                    exact
                    path="/aluno/resultadosimulados/:examuserid"
                    component={AnsweredQuestionList}
                  />
                  <PrivateRoute
                    key={location.key}
                    path={"/aluno/simulados/:id"}
                    component={ReleasedExamItem}
                  />
                  <PrivateRoute
                    key={location.key}
                    path={"/aluno/simulado/resultado/:examuserid"}
                    component={ExamResult}
                  />
                  <PrivateRoute
                    key={location.key}
                    path={"/aluno/simulado/:examuserid"}
                    component={ExamQuestionDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/aluno/planos"
                    component={PlansDashboard}
                  />
                </Switch>
              </Container>
              <Footer />
            </Fragment>
          )}
        />
        <Route
          path={"/admin/(.+)"}
          render={() => (
            <Fragment>
              <NavBar isAdmin={true} />
              <Container style={internalContainerStyle}>
                <Switch>
                  <PrivateRoute
                    exact
                    path="/admin/dashboard"
                    component={Dashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/categorias"
                    component={CategoryDashboard}
                  />
                  <PrivateRoute
                    key={location.key}
                    path={["/admin/novacategoria", "/admin/categorias/:id"]}
                    component={CategoryForm}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/subcategorias"
                    component={SubcategoryDashboard}
                  />
                  <PrivateRoute
                    key={location.key}
                    path={[
                      "/admin/novasubcategoria",
                      "/admin/subcategorias/:id",
                    ]}
                    component={SubcategoryForm}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/bancas"
                    component={ExaminingBoardDashboard}
                  />
                  <PrivateRoute
                    key={location.key}
                    path={["/admin/novabanca", "/admin/bancas/:id"]}
                    component={ExaminingBoardForm}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/questoes"
                    component={QuestionDashboard}
                  />
                  <PrivateRoute
                    key={location.key}
                    path={["/admin/novaquestao", "/admin/questoes/:id"]}
                    component={QuestionItem}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/comentarios/questoes"
                    component={CommentDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/comentarios/aulas"
                    component={LessonCommentDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/simulados"
                    component={ExamDashboard}
                  />
                  <PrivateRoute
                    exact
                    path={["/admin/novosimulado", "/admin/simulados/:id"]}
                    component={ExamItem}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/usuarios"
                    component={UserDashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/usuarios/:id"
                    component={UserProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/cursos"
                    component={CourseDashboard}
                  />
                  <PrivateRoute
                    path="/admin/cursos/:courseid/modulos/:id"
                    component={LessonDashboard}
                  />
                  <PrivateRoute
                    path="/admin/cursos/:id"
                    component={ModuleDashboard}
                  />
                  <PrivateRoute
                    exact
                    path={["/admin/novaaula", "/admin/aulas/:id"]}
                    component={LessonForm}
                  />
                  <PrivateRoute component={NotFound} />
                </Switch>
              </Container>
              <Footer />
            </Fragment>
          )}
        />
      </Fragment>
    );
  }
};

export default withRouter(observer(App));
