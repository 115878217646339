import React, { useContext } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import {
  Form,
  Button,
  Header,
  Segment,
  Container,
  List,
} from "semantic-ui-react";
import TextInput from "../../app/common/form/TextInput";
import { RootStoreContext } from "../../app/stores/rootStore";
import { IUserFormValues } from "../../app/models/user";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired, matchesField } from "revalidate";
import ErrorMessage from "../../app/common/form/ErrorMessage";

const validate = combineValidators({
  email: isRequired({ message: "Email é obrigatório" }),
  password: isRequired({ message: "Senha é obrigatória" }),
  confirmPassword: matchesField(
    "password",
    "password"
  )({ message: "Confirmação de senha errada" }),
});

const ResetPasswordForm = () => {
  const rootStore = useContext(RootStoreContext);
  const { resetPassword } = rootStore.userStore;

  return (
    <FinalForm
      onSubmit={(values: IUserFormValues) =>
        resetPassword(values).catch((error) => ({
          [FORM_ERROR]: error,
        }))
      }
      validate={validate}
      render={({
        handleSubmit,
        submitting,
        submitError,
        invalid,
        pristine,
        dirtySinceLastSubmit,
      }) => (
        <Segment className="resetpassword">
          <Container style={{ width: "600px" }}>
            <Segment>
              <Header
                as="h1"
                content="CFC Academy"
                color="grey"
                textAlign="center"
              />
              <Form onSubmit={handleSubmit} error>
                <Header
                  as="h2"
                  content="Redefinir Senha"
                  color="teal"
                  textAlign="center"
                />
                {submitError && !dirtySinceLastSubmit && (
                  <ErrorMessage error={submitError} />
                )}
                <Field
                  name="email"
                  title="Email"
                  placeholder="Email"
                  component={TextInput}
                />
                <Segment style={{ fontSize: "9pt" }}>
                  <h4>Sua nova senha deve conter:</h4>
                  <List as="ul">
                    <List.Item as="li">Mínimo 6 caracteres</List.Item>
                    <List.Item as="li">Mínimo 1 letra maiúscula</List.Item>
                    <List.Item as="li">Mínimo 1 letra minúscula</List.Item>
                    <List.Item as="li">Mínimo 1 número</List.Item>
                    <List.Item as="li">Mínimo 1 caracter especial</List.Item>
                  </List>
                </Segment>
                <Field
                  name="password"
                  title="Senha"
                  placeholder="Senha"
                  component={TextInput}
                  type="password"
                />
                <Field
                  name="confirmPassword"
                  title="Confirmar Senha"
                  placeholder="Confirmar Senha"
                  component={TextInput}
                  type="password"
                />
                <Button
                  disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                  loading={submitting}
                  color="teal"
                  content="Alterar"
                  fluid
                />
              </Form>
            </Segment>
          </Container>
        </Segment>
      )}
    />
  );
};

export default ResetPasswordForm;
