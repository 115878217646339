import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Pagination, Segment, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import LessonListItem from "./LessonListItem";

const LessonList: React.FC<{ id: string }> = ({ id }) => {
  const rootStore = useContext(RootStoreContext);
  const { lessonByName, page, totalPages, setPage, loadLessons } =
    rootStore.lessonStore;

  const onPageChange = (e: any, pageInfo: any) => {
    setPage(pageInfo.activePage);
    loadLessons("", id);
  };

  return (
    <Table unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={10}>Aula</Table.HeaderCell>
          <Table.HeaderCell width={2}>Ordem</Table.HeaderCell>
          <Table.HeaderCell width={4}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {lessonByName.map((lesson) => (
          <LessonListItem key={lesson.id} lesson={lesson} />
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="3">
            <Segment basic clearing floated="right">
              <Pagination
                defaultActivePage={page}
                onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
                totalPages={totalPages}
              />
            </Segment>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default observer(LessonList);
