import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Button, Form, Segment } from "semantic-ui-react";
import { IAnswer } from "../../../app/models/answer";
import { RootStoreContext } from "../../../app/stores/rootStore";

const QuestionOptions: React.FC<{ answers: IAnswer[] }> = ({ answers }) => {
  const rootStore = useContext(RootStoreContext);
  const { setSelectedAnswer, answerId, setAnswerResponseForView } =
    rootStore.answerStore;

  useEffect(() => {
    setSelectedAnswer("");
    setAnswerResponseForView(null);
    if (window.location.href.endsWith("/view")) {
      answers.forEach((answer: IAnswer) => {
        if (answer.isCorrect) {
          setSelectedAnswer(answer.id);
          setAnswerResponseForView(true);
        }
      });
    }
  }, [answers, setSelectedAnswer, setAnswerResponseForView]);

  const handlerCopy = (e: any) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();

    alert("Não é permitido copiar");
  };

  return (
    <Fragment>
      <Segment clearing basic style={{ padding: "20px 0 0 0" }}>
        {answers.map((answer: IAnswer) => (
          <Segment
            key={answer.id}
            color={answer.id === answerId ? "green" : undefined}
            onClick={() => setSelectedAnswer(answer.id)}
          >
            <Form>
              <Form.Group inline>
                <Form.Field>
                  <Button
                    circular
                    content={answer.identifier}
                    color={answer.id === answerId ? "green" : undefined}
                    onClick={() => setSelectedAnswer(answer.id)}
                  />
                </Form.Field>
                <Form.Field>
                  <span onCopy={handlerCopy}>{answer.text}</span>
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
        ))}
      </Segment>
    </Fragment>
  );
};

export default observer(QuestionOptions);
