import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Card,
  Header,
  Statistic,
  List,
  Button,
  Segment,
} from "semantic-ui-react";
import { IPlan } from "../../../app/models/plan";
import { RootStoreContext } from "../../../app/stores/rootStore";

const PlansItems: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadPlan } = rootStore.planStore;

  const [vipPlan, setVipPlan] = useState<IPlan | any>(null);

  useEffect(() => {
    loadPlan("M3R").then((plan) => setVipPlan(plan));
  }, [setVipPlan, loadPlan]);

  return (
    <Segment basic>
      <h1 style={{ textAlign: "center" }}>O melhor método para sua aprovação</h1>
      <br />
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column width={5} />
          {vipPlan && (
            <Grid.Column width={6}>
              <Card centered fluid style={{ width: "80%" }}>
                <Card.Header>
                  <Header
                    content={vipPlan.name}
                    as="h1"
                    style={{ marginTop: "15px", marginBottom: "15px" }}
                    textAlign="center"
                  />
                </Card.Header>
                <Card.Content textAlign="center">
                  <div>
                    De <span className="textCrossed">R$ 399,00</span> por
                  </div>
                  <Statistic size="small">
                    <Statistic.Value style={{ fontWeight: "bold" }}>
                      <span style={{ fontSize: "13pt" }}>12x</span>{" "}
                      {`R$ ${(vipPlan.price / 12)
                        .toFixed(2)
                        .replace(".", ",")}`}
                    </Statistic.Value>
                    <Statistic.Label>
                      ou R$ {vipPlan.price.toFixed(2).replace(".", ",")} à vista
                    </Statistic.Label>
                  </Statistic>
                  <div className="planBadge blink-me">
                    <div className="planBadgeText">
                      <div>50%</div>
                      <div style={{ paddingTop: "5px" }}>OFF</div>
                    </div>
                  </div>
                  <List size="big">
                    <List.Item
                      className="planItem"
                      style={{ fontSize: "20pt" }}
                    >
                      {vipPlan.planDuration} Meses de acesso
                    </List.Item>
                    <List.Item className="planItem">
                      Acesso ilimitado as questões
                    </List.Item>
                    <List.Item className="planItem">
                      Acesso ilimitado aos simulados
                    </List.Item>
                    <List.Item className="planItem">Mapas Mentais</List.Item>
                    <List.Item className="planItem">
                      Apostilas completas
                    </List.Item>
                    <List.Item className="planItem">
                      <strong>Tira dúvidas direto com o professor</strong>
                    </List.Item>
                    <List.Item className="planItem">
                      <strong>Análise de desempenho</strong>
                    </List.Item>
                    <List.Item className="planItem">
                      <strong>
                        Cronograma exclusivo para quem tem pouco tempo para
                        estudar
                      </strong>
                    </List.Item>
                  </List>
                </Card.Content>
                <Card.Content extra textAlign="center">
                  <Button
                    content="Assinar Agora"
                    size="big"
                    positive
                    as={Link}
                    to={`/checkout?plan=${vipPlan.code}`}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
          )}
          <Grid.Column width={5} />
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default observer(PlansItems);
