import axios, { AxiosResponse } from "axios";
import { history } from "../..";
import { toast } from "react-toastify";
import { IStudentEnvelope, IUser, IUserFormValues } from "../models/user";
import { ICategoriesEnvelope, ICategory } from "../models/category";
import { IProfile } from "../models/profile";
import { ISubcategoriesEnvelope, ISubcategory } from "../models/subcategory";
import { IImage, IQuestion, IQuestionsEnvelope } from "../models/question";
import { IAnswer, IAnswerEnvelope } from "../models/answer";
import { ICommentsEnvelope } from "../models/comment";
import { IStudentDashboard } from "../models/studentDashboard";
import {
  IStudentProgressBySubcategoryEnvelope,
  IStudentProgressByCategoryEnvelope,
  IStudentProgressByExaminingBoardEnvelope,
} from "../models/studentProgress";
import { IExam, IExamsEnvelope } from "../models/exam";
import { ExamCategoryFormValues, IExamCategory } from "../models/examCategory";
import ExamSubcategoryFormValues, {
  IExamSubcategory,
} from "../models/examSubcategory";
import {
  IExamUser,
  IExamUserHistoryEnvelope,
  IExamUserResult,
  IExamUserSummaryByExamEnveope,
} from "../models/examUser";
import IExamUserAnswer from "../models/examUserAnswer";
import { IPlansEnvelope } from "../models/plan";
import { IUserPlanEnvelope } from "../models/userPlan";
import { ICourse, ICoursesEnvelope } from "../models/course";
import { IModule, IModulesEnvelope } from "../models/module";
import { ILesson, ILessonEnvelope } from "../models/lesson";
import { ILessonCommentsEnvelope } from "../models/lessonComment";
import { IExaminingBoard } from "../models/examiningBoard";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("jwt");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  if (error.message === "Network Error" && !error.response) {
    toast.error("Network error!");
  }

  const { status, data, config, headers } = error.response;

  if (status === 404) {
    history.push("/notfound");
  }

  var re = /Bearer error="invalid_token"/gi;
  if (status === 401 && headers["www-authenticate"].search(re) === 0) {
    window.localStorage.removeItem("jwt");
    history.push("/");
    toast.info("Sua sessāo expirou, por favor faça o login novamente");
  }

  if (status === 400) {
    if (config.method === "get" && data.errors.hasOwnProperty("id")) {
      history.push("/notfound");
    }
  }

  if (status === 500) {
    toast.error("Server error!");
  }

  throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
  postForm: (url: string, file: Blob) => {
    let formData = new FormData();
    formData.append("File", file);
    return axios
      .post(url, formData, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody);
  },
};

const User = {
  current: (): Promise<IUser> => requests.get("/user"),
  login: (user: IUserFormValues): Promise<IUser> =>
    requests.post(`/user/login`, user),
  register: (user: IUserFormValues): Promise<IUser> =>
    requests.post(`/user/register`, user),
  forgotPassword: (email: string) =>
    requests.post("/user/forgotpassword", {
      email: email,
    }),
  resetPassword: (
    email: string,
    password: string,
    resetToken: string
  ): Promise<IUser> =>
    requests.post("/user/resetpassword", {
      email: email,
      password: password,
      resetToken: resetToken,
    }),
  changePassword: (
    oldPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ): Promise<IUser> =>
    requests.post("/user/changepassword", {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
    }),
  emailConfirmation: (email: string, confirmationToken: string) =>
    requests.post("/user/emailconfirmation", {
      email: email,
      confirmationToken: confirmationToken,
    }),
  resendWelcomeEmail: (email: string) => {
    requests.post("/user/resendwelcomeemail", { email: email });
  },
  list: (params: URLSearchParams): Promise<IStudentEnvelope> =>
    axios.get("/user/list", { params: params }).then(responseBody),
  details: (id: string) => requests.get(`/user/${id}`),
  listReport: () => requests.get("/user/listreport"),
};

const Profiles = {
  get: (username: string): Promise<IProfile> =>
    requests.get(`/profiles/${username}`),
  update: (profile: Partial<IProfile>): Promise<boolean> =>
    requests.put(`/profiles`, profile),
};

const Categories = {
  list: (params: URLSearchParams): Promise<ICategoriesEnvelope> =>
    axios.get("/categories", { params: params }).then(responseBody),
  details: (id: string) => requests.get(`/categories/${id}`),
  create: (category: ICategory) => requests.post("/categories", category),
  update: (category: ICategory) =>
    requests.put(`/categories/${category.id}`, category),
  delete: (id: string) => requests.del(`/categories/${id}`),
  listPerQuestions: (): Promise<ICategory[]> =>
    requests.get("/categories/listperquestioncount"),
  listForSelect: (onlyWithQuestion: boolean): Promise<ICategory[]> =>
    requests.get(
      `/categories/listforselect?onlywithquestions=${onlyWithQuestion}`
    ),
};

const Subcategories = {
  list: (params: URLSearchParams): Promise<ISubcategoriesEnvelope> =>
    axios.get("/subcategories", { params: params }).then(responseBody),
  details: (id: string) => requests.get(`/subcategories/${id}`),
  create: (subcategory: ISubcategory) =>
    requests.post("/subcategories", subcategory),
  update: (subcategory: ISubcategory) =>
    requests.put(`/subcategories/${subcategory.id}`, subcategory),
  delete: (id: string) => requests.del(`/subcategories/${id}`),
  listForSelect: (
    onlyWithQuestion: boolean,
    categoryId?: string
  ): Promise<ISubcategory[]> =>
    requests.get(
      `/subcategories/listforselect?onlywithquestions=${onlyWithQuestion}&categoryid=${categoryId}`
    ),
};

const Questions = {
  list: (params: URLSearchParams): Promise<IQuestionsEnvelope> =>
    axios.get("/questions", { params: params }).then(responseBody),
  details: (id: string) => requests.get(`/questions/${id}`),
  random: (params: URLSearchParams): Promise<IQuestion> =>
    axios.get("/questions/random", { params: params }).then(responseBody),
  questionView: (id: string): Promise<IQuestion> =>
    requests.get(`/questions/${id}/view`),
  create: (question: IQuestion) => requests.post("/questions", question),
  update: (question: IQuestion) =>
    requests.put(`/questions/${question.id}`, question),
  delete: (id: string) => requests.del(`/questions/${id}`),
  answerList: (id: string, params: URLSearchParams): Promise<IAnswerEnvelope> =>
    axios
      .get(`/questions/${id}/answers`, { params: params })
      .then(responseBody),
  answerCreate: (id: string, answer: IAnswer) =>
    requests.post(`/questions/${id}/answers`, answer),
  answerSetCorrect: (id: string, answerId: string) =>
    requests.post(`/questions/${id}/answers/${answerId}/setCorrect`, []),
  uploadImage: (image: Blob, questionId: string): Promise<IImage> =>
    requests.postForm(`/images/${questionId}`, image),
  deleteImage: (questionId: string, id: string) =>
    requests.del(`/images/${questionId}/${id}`),
  rate: (id: string, rate: number) =>
    requests.post(`/questions/${id}/rate`, {
      questionid: id,
      questionrate: rate,
    }),
  getRate: (id: string) => requests.get(`/questions/${id}/rate`),
  commentCreate: (id: string, text: string) =>
    requests.post(`/questions/${id}/comment`, { questionId: id, text: text }),
  commentReplyCreate: (id: string, parentCommentId: string, text: string) =>
    requests.post(`/questions/${id}/commentreply`, {
      questionId: id,
      parentCommentId: parentCommentId,
      text: text,
    }),
  reportCreate: (id: string, text: string) =>
    requests.post(`/questions/${id}/report`, { questionId: id, text: text }),
};

const Answers = {
  details: (id: string) => requests.get(`/answers/${id}`),
  update: (answer: IAnswer) => requests.put(`/answers/${answer.id}`, answer),
  delete: (id: string) => requests.del(`answers/${id}`),
  setAnswered: (id: string) =>
    requests.post(`answers/${id}/setanswered`, { answerId: id }),
};

const UserAnswers = {
  deleteAllByStudent: () => requests.del("/useranswers"),
};

const Comments = {
  list: (params: URLSearchParams): Promise<ICommentsEnvelope> =>
    axios.get("/comments", { params: params }).then(responseBody),
  delete: (id: string) => requests.del(`/comments/${id}`),
};

const StudentDashboard = {
  details: (userName: string): Promise<IStudentDashboard> =>
    requests.get(`/studentdashboard/${userName}`),
};

const StudentProgress = {
  detailsByCategory: (
    params: URLSearchParams
  ): Promise<IStudentProgressByCategoryEnvelope> =>
    axios
      .get("/studentprogress/bycategory", { params: params })
      .then(responseBody),
  detailsBySubcategory: (
    params: URLSearchParams
  ): Promise<IStudentProgressBySubcategoryEnvelope> =>
    axios
      .get("/studentprogress/bysubcategory", { params: params })
      .then(responseBody),
  detailsByExaminingBoard: (
        params: URLSearchParams
      ): Promise<IStudentProgressByExaminingBoardEnvelope> =>
        axios
          .get("/studentprogress/byexaminingboard", { params: params })
          .then(responseBody),
};

const Exams = {
  list: (params: URLSearchParams): Promise<IExamsEnvelope> =>
    axios.get("/exams", { params: params }).then(responseBody),
  listReleased: (params: URLSearchParams): Promise<IExamsEnvelope> =>
    axios.get("/exams/released", { params: params }).then(responseBody),
  details: (id: string) => requests.get(`/exams/${id}`),
  create: (exam: IExam) => requests.post("/exams", exam),
  update: (exam: IExam) => requests.put(`/exams/${exam.id}`, exam),
  delete: (id: string) => requests.del(`/exams/${id}`),
};

const ExamCategories = {
  list: (examId: string): Promise<IExamCategory[]> =>
    requests.get(`/examcategories?examid=${examId}`),
  upsert: (examCategory: ExamCategoryFormValues): Promise<IExamCategory> =>
    requests.post("/examcategories", examCategory),
  delete: (id: string) => requests.del(`/examcategories/${id}`),
};

const ExamSubcategories = {
  upsert: (
    examSubcategory: ExamSubcategoryFormValues
  ): Promise<IExamSubcategory> =>
    requests.post("/examsubcategories", examSubcategory),
  delete: (id: string) => requests.del(`/examsubcategories/${id}`),
};

const ExamUsers = {
  startExam: (examId: string, startDate: string): Promise<IExamUser> =>
    requests.post("/examusers", {
      examId: examId,
      startDate: startDate,
    }),
  getResult: (id: string): Promise<IExamUserResult> =>
    requests.get(`/examusers/${id}/result`),
  stopExam: (id: string, endDate: string) =>
    requests.put(`/examusers/${id}/stop`, { id: id, endDate: endDate }),
  getExamHistory: (
    params: URLSearchParams
  ): Promise<IExamUserHistoryEnvelope> =>
    axios.get("/examusers", { params: params }).then(responseBody),
  isUserReachPlanCap: () => requests.get("/examusers/isuserreachplancap"),
  summaryByExam: (
    params: URLSearchParams
  ): Promise<IExamUserSummaryByExamEnveope> =>
    axios
      .get("/examusers/summarybyexam", { params: params })
      .then(responseBody),
  deleteAllByStudent: () => requests.del("/examusers"),
};

const ExamUserAnswers = {
  getQuestion: (
    examUserId: string,
    nowDate: string
  ): Promise<IExamUserAnswer> =>
    requests.get(`/examuseranswers/${examUserId}?nowdate=${nowDate}`),
  getAnsweredQuestions: (examUserId: string): Promise<IExamUserAnswer[]> =>
    requests.get(`/examuseranswers/${examUserId}/answers`),
  setAnswered: (id: string, answerId: string, answerDate: string) =>
    requests.put(`/examuseranswers/${id}/setanswered`, {
      id: id,
      answerId: answerId,
      answerDate: answerDate,
    }),
};

const Plans = {
  list: (params: URLSearchParams): Promise<IPlansEnvelope> =>
    axios.get("/plans", { params: params }).then(responseBody),
  detailsByCode: (code: string) => requests.get(`/plans/${code}`),
};

const Checkout = {
  completeOrder: (pagarMeOrderId: string, today: string) =>
    requests.post(`/checkout/${pagarMeOrderId}/completeorder`, {
      pagarMeOrderId: pagarMeOrderId,
      startDate: today,
    }),
};

const Voucher = {
  getValidVoucherDetails: (code: string) =>
    requests.get(`/vouchers/${code}/valid`),
};

const UserPlans = {
  list: (params: URLSearchParams): Promise<IUserPlanEnvelope> =>
    axios.get(`/userplans`, { params: params }).then(responseBody),
  currentPlan: () => requests.get("/userplans/currentplan"),
  set7DaysTrialToStudent: (username: string, planCode: string) =>
    requests.post("/userplans/set7daystrialtostudent", {
      username: username,
      planCode: planCode,
    }),
  subscribeUserToPlan: (username: string, planCode: string, today: string) =>
    requests.post("/userplans/subscribeusertoplan", {
      username: username,
      planCode: planCode,
      startDate: today,
    }),
  cancelSubscription: (
    id: string,
    cancelledDate: string,
    cancellationReason: string
  ) =>
    requests.post("/userplans/cancelsubscription", {
      id: id,
      cancelledDate: cancelledDate,
      cancellationReason: cancellationReason,
    }),
};

const Courses = {
  list: (params: URLSearchParams): Promise<ICoursesEnvelope> =>
    axios.get(`/courses`, { params: params }).then(responseBody),
  details: (id: string) => requests.get(`/courses/${id}`),
  create: (course: ICourse) => requests.post("/courses", course),
  update: (course: ICourse) => requests.put(`/courses/${course.id}`, course),
  delete: (id: string) => requests.del(`/courses/${id}`),
};

const Modules = {
  list: (params: URLSearchParams): Promise<IModulesEnvelope> =>
    axios.get(`/modules`, { params: params }).then(responseBody),
  listForStudent: (): Promise<IModulesEnvelope> =>
    requests.get(`/modules/forstudent`),
  details: (id: string) => requests.get(`/modules/${id}`),
  create: (module: IModule) => requests.post("/modules", module),
  update: (module: IModule) => requests.put(`/modules/${module.id}`, module),
  delete: (id: string) => requests.del(`/modules/${id}`),
};

const Lessons = {
  list: (params: URLSearchParams): Promise<ILessonEnvelope> =>
    axios.get(`/lessons`, { params: params }).then(responseBody),
  details: (id: string) => requests.get(`/lessons/${id}`),
  create: (lesson: ILesson) => requests.post("/lessons", lesson),
  update: (lesson: ILesson) => requests.put(`/lessons/${lesson.id}`, lesson),
  delete: (id: string) => requests.del(`/lessons/${id}`),
};

const UserLessons = {
  create: (lessonId: string) =>
    requests.post("/userlessons", { lessonId: lessonId }),
  delete: (lessonId: string) => requests.del(`/userlessons/${lessonId}`),
};

const LessonComments = {
  list: (params: URLSearchParams): Promise<ILessonCommentsEnvelope> =>
    axios.get("/lessoncomments", { params: params }).then(responseBody),
  create: (lessonId: string, text: string) =>
    requests.post(`/lessoncomments`, { lessonId: lessonId, text: text }),
  replyCreate: (lessonId: string, parentCommentId: string, text: string) =>
    requests.post(`/lessoncomments/reply`, {
      lessonId: lessonId,
      parentCommentId: parentCommentId,
      text: text,
    }),
  delete: (id: string) => requests.del(`/lessoncomments/${id}`),
};

const ExaminingBoards = {
  list: (params: URLSearchParams): Promise<ICategoriesEnvelope> =>
    axios.get("/examiningboards", { params: params }).then(responseBody),
  details: (id: string) => requests.get(`/examiningboards/${id}`),
  create: (examiningBoard: IExaminingBoard) =>
    requests.post("/examiningboards", examiningBoard),
  update: (examiningBoard: IExaminingBoard) =>
    requests.put(`/examiningboards/${examiningBoard.id}`, examiningBoard),
  delete: (id: string) => requests.del(`/examiningboards/${id}`),
  listForSelect: (): Promise<IExaminingBoard[]> =>
    requests.get("/examiningboards/listforselect"),
};

const defaultObjectResult = {
  User,
  Profiles,
  Categories,
  Subcategories,
  Questions,
  Answers,
  UserAnswers,
  Comments,
  StudentDashboard,
  StudentProgress,
  Exams,
  ExamCategories,
  ExamSubcategories,
  ExamUsers,
  ExamUserAnswers,
  Plans,
  Checkout,
  Voucher,
  UserPlans,
  Courses,
  Modules,
  Lessons,
  UserLessons,
  LessonComments,
  ExaminingBoards,
};

export default defaultObjectResult;
