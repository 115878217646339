import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { combineValidators, isRequired } from "revalidate";
import { QuestionFormValues } from "../../../app/models/question";
import { SubcategoryForSelect } from "../../../app/models/subcategory";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { v4 as uuid } from "uuid";
import { Button, Form, Grid } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import TextInput from "../../../app/common/form/TextInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import DropdownInput from "../../../app/common/form/DropdownInput";
import ReactQuill from "react-quill";
import { ExaminingBoardForSelect } from "../../../app/models/examiningBoard";

const validate = combineValidators({
  subcategoryId: isRequired({ message: "A subcategoria é obrigatória" }),
  text: isRequired({ message: "A questāo é obrigatória" }),
});

const QuestionForm: React.FC<{ id: string }> = ({ id }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    createQuestion,
    editQuestion,
    submitting,
    loadQuestion,
    deleteQuestion,
    target,
    previousExaminingBoardId,
    previousSubcategoryId,
    previousYear,
  } = rootStore.questionStore;
  const { loadSubcategoriesForSelect } = rootStore.subcategoryStore;
  const { loadExaminingBoardsForSelect } = rootStore.examiningBoardStore;

  const [question, setQuestion] = useState(new QuestionFormValues());
  const [loading, setLoading] = useState(false);
  const [subcategoriesForSelect, setSubcategoriesForSelect] = useState<
    SubcategoryForSelect[]
  >([]);
  const [examiningBoardsForSelect, setExaminingBoardsForSelect] = useState<
    ExaminingBoardForSelect[]
  >([]);
  const [profComment, setProfComment] = useState("");

  useEffect(() => {
    if (previousExaminingBoardId || previousSubcategoryId || previousYear) {
      var q = new QuestionFormValues();
      q.examiningBoardId = previousExaminingBoardId;
      q.subcategoryId = previousSubcategoryId;
      q.year = previousYear === 0 ? undefined : previousYear;
      setQuestion(q);
    }

    loadSubcategoriesForSelect().then((subcategories) => {
      let subcategoryList: SubcategoryForSelect[] = [];
      subcategories?.forEach((subcat) => {
        let subcategory = new SubcategoryForSelect(
          subcat.id,
          subcat.name,
          subcat.id
        );
        subcategoryList.push(subcategory);
      });

      subcategoryList.sort((a, b) => {
        return a.text.localeCompare(b.text);
      });

      setSubcategoriesForSelect(subcategoryList);
    });

    loadExaminingBoardsForSelect().then((examiningBoards: any) => {
      let examiningBoardList: ExaminingBoardForSelect[] = [];
      examiningBoardList.push(new ExaminingBoardForSelect("", "", ""));

      examiningBoards?.forEach((exBoard: any) => {
        let examiningBoard = new ExaminingBoardForSelect(
          exBoard.id,
          exBoard.code,
          exBoard.id
        );
        examiningBoardList.push(examiningBoard);
      });

      examiningBoardList.sort((a, b) => {
        return a.text.localeCompare(b.text);
      });

      setExaminingBoardsForSelect(examiningBoardList);
    });

    if (id) {
      setLoading(true);

      loadQuestion(id)
        .then((question) => {
          setQuestion(new QuestionFormValues(question));
          setProfComment(question.comment);
        })
        .finally(() => setLoading(false));
    }
  }, [
    loadQuestion,
    id,
    loadSubcategoriesForSelect,
    setSubcategoriesForSelect,
    loadExaminingBoardsForSelect,
    setExaminingBoardsForSelect,
    previousExaminingBoardId,
    previousSubcategoryId,
    previousYear,
  ]);

  const handleFinalFormSubmit = (values: any) => {
    const { ...question } = values;
    if (!question.id) {
      let newQuestion = {
        ...question,
        id: uuid(),
        comment: profComment,
      };
      createQuestion(newQuestion);
    } else {
      question.comment = profComment;
      editQuestion(question);
    }
  };

  const modules = {
    clipboard: {
      matchVisual: false,
    },
    toolbar: [
      [{ header: [1, 2, false] }],
      [
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        {
          color: [
            "#ff0100",
            "#1100ff",
            "#008001",
            "#ffa500",
            "#ee82ed",
            "#feff00",
            "#808080",
          ],
        },
        {
          background: [
            "#ff0100",
            "#1100ff",
            "#008001",
            "#ffa500",
            "#ee82ed",
            "#feff00",
            "#808080",
          ],
        },
      ],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "color",
    "background",
    "list",
    "bullet",
    "link",
  ];

  return (
    <FinalForm
      validate={validate}
      initialValues={question}
      onSubmit={handleFinalFormSubmit}
      render={({ handleSubmit, invalid, pristine }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={2}>
                <Field
                  name="code"
                  title="Código"
                  placeholder="Código"
                  value={question.code}
                  component={TextInput}
                  disabled={true}
                />
              </Grid.Column>
              <Grid.Column width={7}>
                <Field
                  component={DropdownInput}
                  options={subcategoriesForSelect}
                  name="subcategoryId"
                  title="Subcategoria"
                  placeholder="Subcategoria"
                  value={question.subcategoryId}
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Field
                  component={DropdownInput}
                  options={examiningBoardsForSelect}
                  name="examiningBoardId"
                  title="Banca"
                  placeholder="Banca"
                  value={question.examiningBoardId}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Field
                  name="year"
                  title="Ano"
                  placeholder="Ano"
                  value={question.year ?? ""}
                  component={TextInput}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Field
                  name="text"
                  title="Questāo"
                  placeholder="Questāo"
                  value={question.text}
                  component={TextAreaInput}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Field>
                  <label>
                    <strong>Comentário do professor</strong>
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={profComment}
                    onChange={setProfComment}
                    modules={modules}
                    formats={formats}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Button
                  loading={submitting}
                  disabled={
                    profComment === question.comment &&
                    (loading || invalid || pristine)
                  }
                  floated="right"
                  positive
                  type="submit"
                  content="Salvar"
                />
                <Button
                  as={Link}
                  to="/admin/questoes"
                  disabled={loading}
                  floated="right"
                  type="button"
                  content="Cancelar"
                />
                {question.id && (
                  <Button
                    loading={target === question.id && submitting}
                    disabled={loading}
                    onClick={(e) => {
                      deleteQuestion(e, question.id!);
                      setQuestion(new QuestionFormValues());
                    }}
                    floated="right"
                    color="red"
                    content="Excluir"
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    />
  );
};

export default observer(QuestionForm);
