import { observer } from "mobx-react-lite";
import React from "react";
import { Grid, Header, Icon, Segment } from "semantic-ui-react";

const SiteStatistics: React.FC = () => {
  return (
    <Segment textAlign="center" padded="very" className="site-statistics">
      <Grid>
        <Grid.Row>
          <Grid.Column width={6} verticalAlign="middle">
            <Header
              as="h1"
              content="CFC Academy em números"
              style={{ textAlign: "left", fontSize: "25pt" }}
            />
            <div
              style={{ textAlign: "left", fontSize: "12pt", marginTop: "50px" }}
            >
              <p>
                Maior plataforma especializada em certificações contábeis (CRC,
                CNAI e CNPC).
              </p>
              <p>Especialistas em Contabilidade para concursos públicos.</p>
              <p>Questões para 100% dos editais.</p>
            </div>
          </Grid.Column>
          <Grid.Column width={10}>
            <Grid columns={2}>
              <Grid.Row style={{ marginTop: "50px", marginBottom: "50px" }}>
                <Grid.Column textAlign="center">
                  <div className="site-statistics-icon">
                    <Icon name="tasks" />
                  </div>
                  <div className="site-statistics-title">+ de 10 mil</div>
                  <div className="site-statistics-description">
                    Questoes cadastradas
                  </div>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <div className="site-statistics-icon">
                    <Icon name="checkmark" />
                  </div>
                  <div className="site-statistics-title">80%</div>
                  <div className="site-statistics-description">
                    Taxa de aprovação
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ marginTop: "50px", marginBottom: "50px" }}>
                <Grid.Column textAlign="center">
                  <div className="site-statistics-icon">
                    <Icon name="user outline" />
                  </div>
                  <div className="site-statistics-title">+ de 1000</div>
                  <div className="site-statistics-description">
                    Alunos ativos
                  </div>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <div className="site-statistics-icon">
                    <Icon name="thumbs up outline" />
                  </div>
                  <div className="site-statistics-title">100%</div>
                  <div className="site-statistics-description">
                    Alunos satisfeitos
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default observer(SiteStatistics);
