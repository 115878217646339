import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Header, Segment, Statistic } from "semantic-ui-react";
import { format } from "date-fns";
import { IUserPlan } from "../../../app/models/userPlan";
import { RootStoreContext } from "../../../app/stores/rootStore";

const PlanTile: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { currentPlan } = rootStore.userPlanStore;

  const [userPlan, setUserPlan] = useState<IUserPlan>();

  useEffect(() => {
    currentPlan().then((response) => setUserPlan(response));
  }, [currentPlan]);
  return (
    <Segment clearing style={{ height: "100%" }}>
      <Header as="h3" content="Plano Ativo" />
      <Segment basic clearing floated="right" style={{ marginTop: "54px" }}>
        {window.innerWidth <= 760 ? (
          <Statistic floated="right" size="tiny">
            {userPlan ? (
              <Fragment>
                <Statistic.Value>{userPlan.planName}</Statistic.Value>
                <Statistic.Label
                  style={{ textAlign: "right", color: "#4a98df" }}
                >
                  Válido até {format(new Date(userPlan.endDate), "dd/MM/yyyy")}
                </Statistic.Label>
              </Fragment>
            ) : (
              <Fragment>
                <Statistic.Value style={{ textAlign: "right" }}>
                  Básico
                </Statistic.Value>
                <Statistic.Label style={{ textAlign: "right" }}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "green" }}
                    href="https://cfcacademy.com.br/cfc-questoes-pv-1/"
                  >
                    Assinar agora
                  </a>
                </Statistic.Label>
              </Fragment>
            )}
          </Statistic>
        ) : (
          <Statistic floated="right" size="small">
            {userPlan ? (
              <Fragment>
                <Statistic.Value>{userPlan.planName}</Statistic.Value>
                <Statistic.Label
                  style={{ textAlign: "right", color: "#4a98df" }}
                >
                  Válido até {format(new Date(userPlan.endDate), "dd/MM/yyyy")}
                </Statistic.Label>
              </Fragment>
            ) : (
              <Fragment>
                <Statistic.Value style={{ textAlign: "right" }}>
                  Básico
                </Statistic.Value>
                <Statistic.Label style={{ textAlign: "right" }}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "green" }}
                    href="https://cfcacademy.com.br/cfc-questoes-pv-1/"
                  >
                    Assinar agora
                  </a>
                </Statistic.Label>
              </Fragment>
            )}
          </Statistic>
        )}
      </Segment>
    </Segment>
  );
};

export default observer(PlanTile);
