import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { v4 as uuid } from "uuid";
import { AnswerFormValues } from "../../../../app/models/answer";
import TextInput from "../../../../app/common/form/TextInput";
import TextAreaInput from "../../../../app/common/form/TextAreaInput";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import { FORM_ERROR } from "final-form";
import ErrorMessage from "../../../../app/common/form/ErrorMessage";

const validate = combineValidators({
  identifier: isRequired({ message: "O identificador é obrigatório" }),
  text: isRequired({ message: "A resposta é obrigatória" }),
});

interface IParams {
  id?: string;
}

const AnswerForm: React.FC<IParams> = ({ id }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    createAnswer,
    editAnswer,
    loadAnswer,
    deleteAnswer,
    answersByIdentifier,
  } = rootStore.answerStore;
  const { question } = rootStore.questionStore;
  const { closeModal } = rootStore.modalStore;

  const [answer, setAnswer] = useState(new AnswerFormValues());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);

      loadAnswer(id)
        .then((answer) => setAnswer(new AnswerFormValues(answer)))
        .finally(() => setLoading(false));
    } else {
      const identifiers = "ABCDEFGHIJLMNOPQRSTUVXZ".split("");
      let newAnswer: AnswerFormValues = new AnswerFormValues();
      newAnswer.identifier = identifiers[answersByIdentifier.length];
      setAnswer(new AnswerFormValues(newAnswer));
    }
  }, [setLoading, loadAnswer, id, answersByIdentifier]);

  const handleFinalFormSubmit = (values: any) => {
    const { ...answer } = values;
    if (!answer.id) {
      let newAnswer = {
        ...answer,
        id: uuid(),
        questionId: question!.id,
      };
      return createAnswer(newAnswer)
        .catch((error) => ({
          [FORM_ERROR]: error,
        }))
        .finally(() => {
          closeModal();
        });
    } else {
      return editAnswer(answer)
        .catch((error) => ({
          [FORM_ERROR]: error,
        }))
        .finally(() => closeModal());
    }
  };

  const handleDeleteAnswer = (
    e: React.SyntheticEvent<HTMLButtonElement, Event>,
    id: string
  ) => {
    deleteAnswer(e, id);
    closeModal();
  };

  return (
    <Segment clearing>
      <h2>Resposta</h2>
      <FinalForm
        validate={validate}
        initialValues={answer}
        onSubmit={handleFinalFormSubmit}
        render={({
          handleSubmit,
          invalid,
          pristine,
          submitError,
          dirtySinceLastSubmit,
        }) => (
          <Form onSubmit={handleSubmit} loading={loading} error>
            <Grid>
              {submitError && !dirtySinceLastSubmit && (
                <Grid.Row>
                  <Grid.Column width={16}>
                    <ErrorMessage error={submitError} />
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column width={16}>
                  <Field
                    name="identifier"
                    title="Identificador"
                    placeholder="Identificador"
                    value={answer.identifier}
                    component={TextInput}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Field
                    name="text"
                    title="Resposta"
                    placeholder="Resposta"
                    value={answer.text}
                    component={TextAreaInput}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Button
                    disabled={loading || invalid || pristine}
                    floated="right"
                    positive
                    type="submit"
                    content="Salvar"
                  />
                  <Button
                    disabled={loading}
                    floated="right"
                    type="button"
                    content="Cancelar"
                    onClick={() => closeModal()}
                  />
                  {answer.id && (
                    <Button
                      disabled={loading}
                      onClick={(e) => handleDeleteAnswer(e, answer.id!)}
                      floated="right"
                      color="red"
                      content="Excluir"
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      />
    </Segment>
  );
};

export default observer(AnswerForm);
