import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { IExam } from "../../app/models/exam";

const ExamListItem: React.FC<{ exam: IExam }> = ({ exam }) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Link to={`/admin/simulados/${exam.id}`}>{exam.name}</Link>
      </Table.Cell>
      <Table.Cell>{exam.totalQuestions}</Table.Cell>
      <Table.Cell>{exam.durationTime}</Table.Cell>
      <Table.Cell>{exam.released ? "Sim" : "Não"}</Table.Cell>
      <Table.Cell>{exam.examiningBoardCode}</Table.Cell>
    </Table.Row>
  );
};

export default observer(ExamListItem);
