import { observable, computed, action, runInAction, reaction } from "mobx";
import {
  IStudentUser,
  IUser,
  IUserFormValues,
  StudentFilter,
} from "../models/user";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { history } from "../..";
import { toast } from "react-toastify";

const LIMIT = 10;

export default class UserStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.loadStudents();
      }
    );
  }

  @observable user: IUser | null = null;
  @observable studentUser: IStudentUser | null = null;
  @observable submitting = false;
  @observable loadingInitial = false;
  @observable studentRegistry = new Map();
  @observable studentCount = 0;
  @observable page = 1;
  @observable predicate = new Map();
  @observable studentFilter = new StudentFilter();

  @action setPredicate = () => {
    this.predicate.clear();
    this.predicate.set("onlyActive", this.studentFilter.onlyActive);
    this.predicate.set("paidStatus", this.studentFilter.paidStatus);
    if (this.studentFilter.name) {
      this.predicate.set("name", this.studentFilter!.name);
    }
  };

  @computed get isLoggedIn() {
    return !!this.user;
  }

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page > 1 ? (this.page - 1) * LIMIT : 0}`);
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });

    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.studentCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @computed get studentsByName() {
    const students = Array.from(this.studentRegistry.values());
    return students;
  }

  @action login = async (values: IUserFormValues) => {
    try {
      var url = new URL(window.location.href);
      values.email = values.email.trim();
      const user = await agent.User.login(values);
      runInAction(() => {
        this.user = user;
      });
      this.rootStore.commonStore.setToken(user.token);
      this.rootStore.modalStore.closeModal();
      if (user.role === "Admin" || user.role === "Manager") {
        history.push("/admin/dashboard");
      } else {
        if (url.searchParams.get("redirect")) {
          var redirectUrl = `/${url.searchParams.get("redirect")}`;
          if (url.searchParams.get("plan")) {
            redirectUrl = redirectUrl + `?plan=${url.searchParams.get("plan")}`;
          }
          history.push(redirectUrl);
        } else {
          history.push("/aluno/dashboard");
        }
      }
    } catch (error) {
      throw error;
    }
  };

  @action register = async (values: IUserFormValues) => {
    try {
      if (values.terms) {
        if (values.password === values.confirmPassword) {
          try {
            values.email = values.email.trim();
            await agent.User.register(values);
          } catch (error) {
            if (error) {
              if (error.data) {
                if (error.data.errors && error.data.errors.EmailConfirmed) {
                  runInAction(() => {
                    this.rootStore.modalStore.closeModal();
                  });
                  history.push(
                    `/reenviaremailboasvindas?email=${values.email}`
                  );
                } else {
                  throw error;
                }
              }
            }
          }
        } else {
          toast.warning("Confirmaçāo de senha errada");
        }
      } else {
        toast.error("É necessário aceitar os termos de uso");
      }
    } catch (error) {
      throw error;
    }
  };

  @action getUser = async () => {
    try {
      const user = await agent.User.current();
      runInAction(() => {
        this.user = user;
      });
      return user;
    } catch (error) {}
  };

  @action logout = () => {
    this.rootStore.commonStore.setToken(null);
    this.user = null;
    history.push("/");
  };

  @action forgotPassword = async (email: string) => {
    try {
      this.submitting = true;
      await agent.User.forgotPassword(email);
      runInAction(() => {
        this.submitting = false;
      });
      toast.info("Email enviado com link para redefinir sua senha");
    } catch (error) {
      runInAction(() => {
        this.submitting = false;
      });
      toast.error("Erro ao enviar email");
    }
  };

  @action resetPassword = async (values: IUserFormValues) => {
    try {
      if (values.password === values.confirmPassword) {
        var url = new URL(window.location.href);
        const resetToken = url.searchParams.get("token");
        if (resetToken) {
          await agent.User.resetPassword(
            values.email,
            values.password,
            resetToken
          );
          toast.info("Senha alterada com sucesso");
          history.push("/");
        } else {
          toast.error("Erro ao alterar senha");
        }
      } else {
        toast.warning("Confirmaçāo de senha errada");
      }
    } catch (error) {
      throw error;
    }
  };

  @action changePassword = async (values: IUserFormValues) => {
    try {
      if (values.password === values.confirmPassword) {
        await agent.User.changePassword(
          values.oldPassword,
          values.password,
          values.confirmPassword
        );
        runInAction(() => {
          this.user!.hasTempPassword = false;
        });
        toast.info("Senha alterada com sucesso");
        this.rootStore.modalStore.closeModal();
      } else {
        toast.warning("Confirmaçāo de senha errada");
      }
    } catch (error) {
      throw error;
    }
  };

  @action emailConfirmation = async () => {
    try {
      var url = new URL(window.location.href);
      const email = url.searchParams.get("email");
      const confirmationToken = url.searchParams.get("token");

      if (email && confirmationToken) {
        await agent.User.emailConfirmation(email, confirmationToken);
      } else {
        toast.error("Erro ao verificar email");
      }
    } catch (error) {
      toast.error("Erro ao confirmar email");
    }
  };

  @action resendWelcomeEmail = async () => {
    try {
      var url = new URL(window.location.href);
      const email = url.searchParams.get("email");

      if (email) {
        await agent.User.resendWelcomeEmail(email);
        toast.success("Email enviado com sucesso");
        history.push("/");
      } else {
        toast.error("Email não encontrado");
      }
    } catch (error) {
      toast.error("Erro ao reenviar email");
    }
  };

  @action loadStudents = async () => {
    this.loadingInitial = true;
    try {
      this.studentRegistry.clear();
      const studentsEnvelope = await agent.User.list(this.axiosParams);
      const { dataCollection, dataCount } = studentsEnvelope;
      runInAction("loading students", () => {
        dataCollection.forEach((user) => {
          this.studentRegistry.set(user.id, user);
        });
        this.studentCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load students error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar usuarios");
    }
  };

  @action loadUser = async (id: string) => {
    this.loadingInitial = true;
    try {
      var user = await agent.User.details(id);
      runInAction("loading user", () => {
        this.studentUser = user;
        this.loadingInitial = false;
      });
      return user;
    } catch (error) {
      runInAction("load user error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar usuário");
    }
  };

  @action downloadUserReport = async () => {
    try {
      var reportData = await agent.User.listReport();
      return reportData;
    } catch (error) {
      toast.error("Erro ao carregar relatório");
    }
  };
}
