export interface ICoursesEnvelope {
  dataCollection: ICourse[];
  dataCount: number;
}

export interface ICourse {
  id: string;
  name: string;
}

export class CourseFormValues {
  id?: string = undefined;
  name: string = "";

  constructor(init?: CourseFormValues) {
    Object.assign(this, init);
  }
}