import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import LoadComponent from "../../app/layout/LoadComponent";
import { RootStoreContext } from "../../app/stores/rootStore";
import CommentList from "./CommentList";

const CommentDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadComments, setPredicate, loadingInitial } = rootStore.commentStore;

  useEffect(() => {
    setPredicate();
    loadComments();
  }, [setPredicate, loadComments]);

  if (loadingInitial)
    return <LoadComponent content="Carregando comentários..." />;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={14}>
          <h1>Comentários das Questões</h1>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <CommentList />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(CommentDashboard);
