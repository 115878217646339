import React, { Fragment, useEffect, useState } from "react";
import { Button, Grid, Icon, Menu } from "semantic-ui-react";

const Footer = () => {
  const [cookiesMessageVisible, setCookiesMessageVisible] =
    useState<boolean>(true);

  useEffect(() => {
    setCookiesMessageVisible(window.localStorage.getItem("consent") !== "true");
  }, [setCookiesMessageVisible]);

  return (
    <Fragment>
      {cookiesMessageVisible && window.innerWidth <= 760 && (
        <Menu fixed="bottom">
          <div className="MobileCookiesMessage">
            <span>
              Este site faz o uso de cookies e ao continuar navegando você
              consente da utilzação dos mesmos.
            </span>
            <Button
              content="OK"
              circular
              color="google plus"
              fluid
              onClick={() => {
                window.localStorage.setItem("consent", "true");
                setCookiesMessageVisible(false);
              }}
            />
          </div>
        </Menu>
      )}
      {cookiesMessageVisible && window.innerWidth > 760 && (
        <Menu fixed="bottom">
          <div className="CookiesMessage">
            Este site faz o uso de cookies e ao continuar navegando você
            consente da utilzação dos mesmos.
            <Button
              style={{ marginRight: "10px" }}
              content="OK"
              circular
              color="google plus"
              floated="right"
              onClick={() => {
                window.localStorage.setItem("consent", "true");
                setCookiesMessageVisible(false);
              }}
            />
          </div>
        </Menu>
      )}
      <Menu fixed="bottom">
        <div className="Footer">
          <Grid>
            <Grid.Column width={2}>{process.env.REACT_APP_VERSION}</Grid.Column>
            <Grid.Column width={14} textAlign="right">
              Desenvolvido por{" "}
              <a
                href="https://www.linkedin.com/in/igorabilio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Igor Santos{"  "}
                <Icon name="linkedin" />
              </a>
            </Grid.Column>
          </Grid>
        </div>
      </Menu>
    </Fragment>
  );
};

export default Footer;
