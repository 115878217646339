import { action, computed, observable, runInAction } from "mobx";
import { SyntheticEvent } from "react";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { IAnswer } from "../models/answer";
import { RootStore } from "./rootStore";

const LIMIT = 5;

export default class AnswerStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable answerRegistry = new Map();
  @observable answer: IAnswer | null = null;
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";
  @observable loading = false;
  @observable answerCount = 0;
  @observable page = 0;
  @observable answerId = "";
  @observable answerResponse: Boolean | null = null;

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page ? this.page * LIMIT : 0}`);

    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.answerCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @action setSelectedAnswer = (id: string) => {
    this.answerId = id;
  };

  @action setAnswerResponseForView = (ansered: any) => {
    this.answerResponse = ansered;
  };

  @computed get answersByIdentifier() {
    return Array.from(this.answerRegistry.values()).sort((a) => a.identifier);
  }

  @action loadAnswers = async (id: string) => {
    this.loadingInitial = true;
    this.answerRegistry.clear();
    try {
      const answersEnvelope = await agent.Questions.answerList(
        id,
        this.axiosParams
      );
      const { dataCollection } = answersEnvelope;
      runInAction("loading answers", () => {
        dataCollection.forEach((answer) => {
          this.answerRegistry.set(answer.id, answer);
        });
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load answers error", () => {
        this.loadingInitial = false;
      });
    }
  };

  @action loadAnswer = async (id: string) => {
    let answer = this.getAnswer(id);
    if (answer) {
      this.answer = answer;
      return answer;
    } else {
      this.loadingInitial = true;
      try {
        answer = await agent.Answers.details(id);
        runInAction("loading answer", () => {
          this.answer = answer;
          this.answerRegistry.set(answer.id, answer);
          this.loadingInitial = false;
        });
        return answer;
      } catch (error) {
        runInAction("load answer error", () => {
          this.loadingInitial = false;
        });
      }
    }
  };

  @action clearAnswer = () => {
    this.answer = null;
    this.answerResponse = null;
  };

  getAnswer = (id: string) => {
    return this.answerRegistry.get(id);
  };

  @action createAnswer = async (answer: IAnswer) => {
    this.submitting = true;
    try {
      let newAnswer = await agent.Questions.answerCreate(
        answer.questionId,
        answer
      );
      runInAction("creating answer", () => {
        this.submitting = false;
        this.answerRegistry.set(newAnswer.id, newAnswer);
      });
    } catch (error) {
      runInAction("create answer error", () => {
        this.submitting = false;
      });
      throw error;
    }
  };

  @action editAnswer = async (answer: IAnswer) => {
    this.submitting = true;
    try {
      await agent.Answers.update(answer);
      runInAction("editing answer", () => {
        this.answerRegistry.set(answer.id, answer);
        this.answer = answer;
        this.submitting = false;
      });
    } catch (error) {
      runInAction("edit questions error", () => {
        this.submitting = false;
      });
      toast.error("Problem submitting data");
    }
  };

  @action deleteAnswer = async (
    event: SyntheticEvent<HTMLButtonElement>,
    id: string
  ) => {
    this.submitting = true;
    this.target = event.currentTarget.name;
    try {
      await agent.Answers.delete(id);
      runInAction("deleting answer", () => {
        this.answerRegistry.delete(id);
        this.submitting = false;
        this.target = "";
      });
    } catch (error) {
      runInAction("delete answer error", () => {
        this.submitting = false;
        this.target = "";
      });
    }
  };

  @action setCorrectAnswer = async (questionId: string, id: string) => {
    this.submitting = true;
    try {
      await agent.Questions.answerSetCorrect(questionId, id);
      runInAction("Setting correct answer", () => {
        this.answerRegistry.forEach((answer) => {
          answer.isCorrect = false;
        });

        let correctAnswer = this.getAnswer(id);
        correctAnswer.isCorrect = true;

        this.submitting = false;
      });
    } catch (error) {
      runInAction("Set correct answer error", () => {
        this.submitting = false;
      });
    }
  };

  @action setAnswered = async (id: string) => {
    this.submitting = true;
    try {
      let resp = await agent.Answers.setAnswered(id);
      runInAction("Set answered", () => {
        this.submitting = false;
        this.answerResponse = resp;
      });
      return resp;
    } catch (error) {
      runInAction("Set answered error", () => {
        this.submitting = false;
        this.answerResponse = null;
      });
    }
  };
}
