import { action, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { IProfile } from "../models/profile";
import { RootStore } from "./rootStore";
import { format } from "date-fns";

export default class ProfileStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable profile: IProfile | null = null;
  @observable loadingProfile = true;
  @observable submitting = false;

  @action loadProfile = async (username: string) => {
    this.loadingProfile = true;
    try {
      const profile = await agent.Profiles.get(username);
      runInAction(() => {
        this.profile = profile;
        this.loadingProfile = false;
      });
      return profile;
    } catch (error) {
      runInAction(() => {
        this.loadingProfile = false;
      });
    }
  };

  @action updateProfile = async (profile: IProfile) => {
    this.submitting = true;
    try {
      profile.birthDate = format(Date.parse(profile.birthDate), "yyyy-MM-dd");
      profile.number = profile.number === undefined ? "" : profile.number;
      profile.street = profile.street === undefined ? "" : profile.street;
      profile.neighborhood =
        profile.neighborhood === undefined ? "" : profile.neighborhood;
      profile.city = profile.city === undefined ? "" : profile.city;
      profile.state = profile.state === undefined ? "" : profile.state;
      profile.cep = profile.cep === undefined ? "" : profile.cep;

      await agent.Profiles.update(profile);
      runInAction(() => {
        if (
          profile.displayName !== this.rootStore.userStore.user!.displayName
        ) {
          this.rootStore.userStore.user!.displayName = profile.displayName;
          this.profile = { ...this.profile!, ...profile };
          this.submitting = false;
        }
      });
      return true;
    } catch (error) {
      runInAction(() => {
        this.submitting = false;
      });
      if (error.email) {
        toast.error(error.email);
      } else {
        toast.error("Erro ao atualizar usuário");
      }
      return false;
    }
  };
}
