import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Message, Pagination, Segment, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";

const UserProfileExamSummary: React.FC<{ username: string }> = ({
  username,
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    getSummaryByExam,
    examUserSummaryByExamCount,
    page,
    setPage,
    totalPages,
  } = rootStore.examUsersStore;

  useEffect(() => {
    getSummaryByExam(username);
  }, [getSummaryByExam, username]);

  const onPageChange = (e: any, pageInfo: any) => {
    setPage(pageInfo.activePage);
    getSummaryByExam(username);
  };

  return (
    <Fragment>
      {examUserSummaryByExamCount.length > 0 ? (
        <Table columns={16} striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={6}>Simulado</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Respondidos
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {examUserSummaryByExamCount.map((item) => (
              <Table.Row key={item.examId}>
                <Table.Cell>{item.examName}</Table.Cell>
                <Table.Cell textAlign="center">{item.examUserCount}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="16">
                <Segment basic clearing floated="right">
                  <Pagination
                    defaultActivePage={page}
                    onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
                    totalPages={totalPages}
                  />
                </Segment>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      ) : (
        <Message
          icon="warning circle"
          header="Oops"
          content="Ainda não há dados suficientes"
        />
      )}
    </Fragment>
  );
};

export default observer(UserProfileExamSummary);
