import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Header, Segment, Statistic } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";

const SolvedQuestionsTile: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { studentDashboard } = rootStore.studentDashboardStore;

  return (
    <Segment clearing style={{height: "100%"}}>
      <Header as="h3" content="Total de questões resolvidas" />
      <Segment basic clearing floated="right" style={{ marginTop: "40px" }}>
        <Statistic floated="right">
          <Statistic.Value>
            {studentDashboard && studentDashboard.totalAnsweredQuestions}
          </Statistic.Value>
          <Statistic.Label>Questões</Statistic.Label>
        </Statistic>
      </Segment>
    </Segment>
  );
};

export default observer(SolvedQuestionsTile);
