import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useState } from "react";
import { Button, Comment, Form, Icon, Segment } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import { CommentFormValues } from "../../../app/models/comment";
import { RootStoreContext } from "../../../app/stores/rootStore";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import { toast } from "react-toastify";

const QuestionComments: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { question, commentCreate, loading } = rootStore.questionStore;
  const { comments } = question!;

  const [newComment] = useState(new CommentFormValues());

  const handleFinalFormSubmit = (values: any) => {
    if (values.text === "" || values.text === undefined) {
      toast.error("Comentário é obrigatório");
    } else {
      commentCreate(question!.id, values.text);
      values.text = "";
    }
  };

  return (
    <Segment>
      <h3>Comentários</h3>
      <Comment.Group>
        {question &&
          question.comments &&
          comments.map((comment) => (
            <Comment key={comment.id}>
              <Comment.Avatar
                as="a"
                src={
                  comment.isProfessorAnswer
                    ? "/assets/professor.jpg"
                    : comment.gender === "F"
                    ? "/assets/female.jpg"
                    : "/assets/male.jpg"
                }
              />
              <Comment.Content>
                <Comment.Author>
                  {comment.isProfessorAnswer ? (
                    <Fragment>
                      Professor <Icon name="graduation cap" />
                    </Fragment>
                  ) : (
                    comment.displayName
                  )}
                </Comment.Author>
                <Comment.Text>
                  {comment.isProfessorAnswer ? (
                    <div dangerouslySetInnerHTML={{ __html: comment.text }} />
                  ) : (
                    <div>
                      {comment.text.split("\n").map((i, key) => {
                        return <p key={key}>{i}</p>;
                      })}
                    </div>
                  )}
                </Comment.Text>
                <Comment.Metadata>
                  <div>{comment.postedDate}</div>
                </Comment.Metadata>
              </Comment.Content>
              {comment.replys.length > 0 && (
                <Comment.Group>
                  {comment.replys.map((reply) => (
                    <Comment key={reply.id}>
                      <Comment.Avatar
                        as="a"
                        src={
                          reply.isProfessorAnswer
                            ? "/assets/professor.jpg"
                            : reply.gender === "F"
                            ? "/assets/female.jpg"
                            : "/assets/male.jpg"
                        }
                      />
                      <Comment.Content>
                        <Comment.Author>
                          {reply.isProfessorAnswer ? (
                            <Fragment>
                              Professor <Icon name="graduation cap" />
                            </Fragment>
                          ) : (
                            reply.displayName
                          )}
                        </Comment.Author>
                        <Comment.Text>
                          {reply.isProfessorAnswer ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: reply.text }}
                            />
                          ) : (
                            <div>
                              {reply.text.split("\n").map((i, key) => {
                                return <p key={key}>{i}</p>;
                              })}
                            </div>
                          )}
                        </Comment.Text>
                        <Comment.Metadata>
                          <div>{reply.postedDate}</div>
                        </Comment.Metadata>
                      </Comment.Content>
                    </Comment>
                  ))}
                </Comment.Group>
              )}
            </Comment>
          ))}

        <FinalForm
          initialValues={newComment}
          onSubmit={handleFinalFormSubmit}
          render={({ handleSubmit }) => (
            <Form reply onSubmit={handleSubmit} loading={loading}>
              <Field
                name="text"
                value={newComment.text}
                rows={4}
                component={TextAreaInput}
              />
              <Button
                content="Adicionar Comentário"
                labelPosition="left"
                icon="edit"
                primary
                type="submit"
                loading={loading}
              />
            </Form>
          )}
        />
      </Comment.Group>
    </Segment>
  );
};

export default observer(QuestionComments);
