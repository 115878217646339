import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Confirm,
  Dropdown,
  Form,
  Grid,
  Header,
  Input,
  Label,
  List,
} from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { Form as FinalForm } from "react-final-form";
import {
  ExamCategoryFormValues,
  IExamCategory,
} from "../../../app/models/examCategory";
import ExamSubcategoryFormValues, {
  IExamSubcategory,
} from "../../../app/models/examSubcategory";
import { toast } from "react-toastify";

const ExamConfigForm: React.FC<{ examId: string }> = ({ examId }) => {
  const rootStore = useContext(RootStoreContext);
  const { categoriesOptions, loadCategoriesOptions } = rootStore.categoryStore;
  const { loadSubcategoriesOptions, subcategoriesOptions } =
    rootStore.subcategoryStore;
  const {
    upsertExamCategory,
    deleteExamCategory,
    loadExamCategories,
    examCategoriesByCategory,
  } = rootStore.examCategoryStore;
  const { upsertExamSubcategory, deleteExamSubcategory } =
    rootStore.examSubcategoryStore;

  useEffect(() => {
    loadCategoriesOptions(true);
    loadExamCategories(examId);
  }, [loadCategoriesOptions, loadExamCategories, examId]);

  const [loadingSubcat, setLoadingSubcat] = useState(false);
  const [categoryId, setCategoryId] = useState<string | undefined>("");
  const [totalCatQuestions, setTotalCatQuestions] = useState<
    string | undefined
  >("");
  const [subcategoryId, setSubcategoryId] = useState<string | undefined>("");
  const [totalSubcatQuestions, setTotalSubcatQuestions] = useState<
    string | undefined
  >("");
  const [examCategoryId, setExamCategoryId] = useState("");

  const [state, setState] = useState({ open: false });
  const open = () => setState({ open: true });
  const close = () => setState({ open: false });

  const onChangeCategory = (categoryId: string | any) => {
    setCategoryId(categoryId);
    loadSubcategoriesOptions(false, categoryId).finally(() =>
      setLoadingSubcat(false)
    );
  };

  const handleFinalFormSubmit = () => {
    if (categoryId === "") {
      toast.error("Favor selecionar uma categoria");
      return;
    }

    if (
      totalCatQuestions === "" ||
      Number(totalCatQuestions).toString() === "NaN"
    ) {
      toast.error("Quantidade de questões da categoria precisa ser numérico");
      return;
    }

    if (subcategoryId === "") {
      toast.error("Favor selecionar uma subcategoria");
      return;
    }

    if (
      totalSubcatQuestions === "" ||
      Number(totalSubcatQuestions).toString() === "NaN"
    ) {
      toast.error(
        "Quantidade de questões da subcategoria precisa ser numérico"
      );
      return;
    }

    let newExamCategory = new ExamCategoryFormValues();
    newExamCategory.examId = examId;
    newExamCategory.categoryId = categoryId!;
    newExamCategory.totalQuestions = Number(totalCatQuestions);

    upsertExamCategory(newExamCategory).then((examCategory) => {
      if (examCategory) {
        let newExamSubcategory = new ExamSubcategoryFormValues();
        newExamSubcategory.examCategoryId = examCategory!.id;
        newExamSubcategory.subcategoryId = subcategoryId!;
        newExamSubcategory.totalQuestions = Number(totalSubcatQuestions);

        upsertExamSubcategory(newExamSubcategory).then(() =>
          loadExamCategories(examId)
        );
      }
    });
  };

  const handleDeleteExamCategory = (id: string) => {
    setExamCategoryId(id);
    open();
  };

  return (
    <Fragment>
      <Confirm
        open={state.open}
        header="Deseja realmente excluir a categoria?"
        content="Isso excluirá automaticamente todas as subcategorias associadas a ela."
        cancelButton="Nāo"
        confirmButton="Sim"
        onConfirm={() => {
          deleteExamCategory(examCategoryId).then(() =>
            loadExamCategories(examId)
          );
          close();
        }}
        onCancel={close}
      />
      <FinalForm
        onSubmit={handleFinalFormSubmit}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <label>Categoria</label>
                    {categoriesOptions && (
                      <Dropdown
                        fluid
                        search
                        selection
                        name="categoryId"
                        placeholder="Selecione uma categoria"
                        value={categoryId}
                        options={categoriesOptions}
                        onChange={(e, data) => onChangeCategory(data.value)}
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Quantidade de Questoes da Categoria</label>
                    <Input
                      name="totalCatQuestions"
                      value={totalCatQuestions}
                      onChange={(e, data) =>
                        setTotalCatQuestions(data.value.toString())
                      }
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Subcategoria</label>
                    {subcategoriesOptions && (
                      <Dropdown
                        fluid
                        search
                        selection
                        name="subcategoryId"
                        placeholder="Selecione uma subcategoria"
                        options={subcategoriesOptions}
                        loading={loadingSubcat}
                        onChange={(e, data) =>
                          setSubcategoryId(data.value?.toString())
                        }
                      />
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Quantidade de Questoes da Subcategoria</label>
                    <Input
                      name="totalSubatQuestions"
                      onChange={(e, data) =>
                        setTotalSubcatQuestions(data.value.toString())
                      }
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Button
                    content="Adicionar"
                    floated="right"
                    type="submit"
                    positive
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      />
      {examCategoriesByCategory.map((examCategory: IExamCategory) => (
        <Card fluid key={examCategory.id}>
          <Card.Content>
            <Header>
              <Label
                content={examCategory.totalQuestions}
                style={{ marginRight: "10px" }}
              />
              {examCategory.categoryName}
              <Button
                floated="right"
                content="Excluir"
                onClick={(e) => {
                  handleDeleteExamCategory(examCategory.id);
                }}
              />
            </Header>
          </Card.Content>
          <Card.Content>
            <List divided>
              {examCategory.examSubcategories.map(
                (examSubcategory: IExamSubcategory) => (
                  <List.Item key={examSubcategory.id}>
                    <List.Content floated="right">
                      <Button
                        content="Excluir"
                        onClick={(e) => {
                          deleteExamSubcategory(e, examSubcategory.id).then(
                            () => loadExamCategories(examId)
                          );
                        }}
                      />
                    </List.Content>
                    <List.Content style={{ paddingTop: "8px" }}>
                      <Label
                        style={{ marginRight: "10px" }}
                        circular
                        content={examSubcategory.totalQuestions}
                      />
                      {examSubcategory.subcategoryName}
                    </List.Content>
                  </List.Item>
                )
              )}
            </List>
          </Card.Content>
          <Card.Content extra>
            Total de questões das subcategorias:{" "}
            {examCategory.examSubcategories.length > 0
              ? examCategory.examSubcategories
                  .map((x) => x.totalQuestions)
                  .reduce(function (a, b) {
                    return a + b;
                  })
              : 0}
          </Card.Content>
        </Card>
      ))}
    </Fragment>
  );
};

export default observer(ExamConfigForm);
