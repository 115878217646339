import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Button, Header, Icon, Image, Segment } from "semantic-ui-react";
import LoadComponent from "../../../../app/layout/LoadComponent";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import MobileQuestionContent from "./MobileQuestionContent";
import MobileQuestionFilter from "./MobileQuestionFilter";
import MobileQuestionFooter from "./MobileQuestionFooter";

const MobileQuestion: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadCategoriesOptions } = rootStore.categoryStore;
  const { loadRandomQuestion, question, loadingInitial, questionNotFound } =
    rootStore.questionStore;
  const { loadExaminingBoardsOptions } = rootStore.examiningBoardStore;
  const { openModal } = rootStore.modalStore;

  useEffect(() => {
    loadCategoriesOptions(true);
    loadExaminingBoardsOptions();
    loadRandomQuestion();
  }, [loadCategoriesOptions, loadExaminingBoardsOptions, loadRandomQuestion]);

  if (loadingInitial) return <LoadComponent content="Carregando questāo..." />;

  if (question?.todayQttAnsweredQuestions === 2)
    openModal(
      <Image
        src="/assets/popup1.jpeg"
        href="https://cfcacademy.com.br/cfc-questoes-pv-3/"
      />,
      "small",
      true,
      true
    );

  if (question?.todayQttAnsweredQuestions === 4)
    openModal(
      <Image
        src="/assets/popup2.jpeg"
        href="https://cfcacademy.com.br/cfc-questoes-pv-4/"
      />,
      "small",
      true,
      true
    );

  return (
    <Fragment>
      <MobileQuestionFilter />
      {question ? (
        <Fragment>
          <MobileQuestionContent />
          <MobileQuestionFooter />
        </Fragment>
      ) : (
        <Segment placeholder style={{ paddingTop: "25px" }}>
          {questionNotFound && (
            <Fragment>
              <Header icon>
                <Icon name="warning circle" />
                Oops nenhuma questão encontrada!
              </Header>
              <Segment basic style={{ textAlign: "center" }}>
                <p style={{ fontSize: "13pt" }}>
                  Infelizmente nenhuma questão foi encontrada, favor tentar
                  novamente ou considere mudar o filtro acima para encontrar
                  outras questões.
                </p>
              </Segment>
            </Fragment>
          )}
          {!questionNotFound && (
            <Fragment>
              <Header icon>
                <Icon name="warning circle" />
                Oops
              </Header>
              <Segment basic style={{ textAlign: "center" }}>
                <p style={{ fontSize: "13pt" }}>
                  Você atingiu o limite diário de questões do seu plano.
                </p>
                <p style={{ fontSize: "13pt" }}>
                  Para ter acesso a essa funcionalidade e a milhares de questões
                  para o Exame do CFC, CNAI e CNPC clique no link abaixo para
                  assinar o CFC Questões
                </p>
                <br />
                <Button
                  content="Assinar agora"
                  positive
                  href="https://cfcacademy.com.br/cfc-questoes-pv-2/"
                />
              </Segment>
            </Fragment>
          )}
        </Segment>
      )}
    </Fragment>
  );
};

export default observer(MobileQuestion);
