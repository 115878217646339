export interface ISubcategoriesEnvelope {
  dataCollection: ISubcategory[];
  dataCount: number;
}

export interface ISubcategory {
  id: string;
  code: string;
  name: string;
  description: string;
  categoryId: string;
  categoryName: string;
  questionCount: number;
}

export class SubcategoryFilter {
  name?: string = "";
  categoryId?: string = "";
  sortBy: string = "";
}

export class SubcategoryFormValues {
  id?: string = undefined;
  code: string = "";
  name: string = "";
  description: string = "";
  categoryId: string = "";

  constructor(init?: SubcategoryFormValues) {
    Object.assign(this, init);
  }
}

export class SubcategoryForSelect {
  key: string = "";
  text: string = "";
  value: string = "";

  constructor(key: string, text: string, value: string) {
    this.key = key;
    this.text = text;
    this.value = value;
  }
}
