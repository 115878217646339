import { action, computed, observable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { RootStore } from "./rootStore";

const LIMIT = 10;

export default class StudentProgressByExaminingBoardStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.studentProgressByExaminingBoardPredicate.keys(),
      () => {
        this.studentProgressByExaminingBoardPage = 0;
        this.studentProgressByExaminingBoardRegistry.clear();
        this.loadStudentProgressByExaminingBoard();
      }
    );
  }

  @observable loadingInitialProgressByExaminingBoard = false;

  @observable studentUsername = "";
  @observable examiningBoardId = "";
  @observable sortBy = "";

  @observable studentProgressByExaminingBoardRegistry = new Map();
  @observable studentProgressByExaminingBoardCount = 0;
  @observable studentProgressByExaminingBoardPage = 1;
  @observable studentProgressByExaminingBoardPredicate = new Map();

  @computed get studentProgressByExaminingBoardAxiosParams() {
    const params = new URLSearchParams();
    params.append(
      "username",
      this.studentUsername
        ? this.studentUsername
        : this.rootStore.userStore.user!.username
    );
    params.append("sortBy", this.sortBy);
    params.append("limit", String(LIMIT));
    params.append(
      "offset",
      `${
        this.studentProgressByExaminingBoardPage > 1
          ? (this.studentProgressByExaminingBoardPage - 1) * LIMIT
          : 0
      }`
    );

    return params;
  }

  @computed get studentProgressByExaminingBoardTotalPages() {
    return Math.ceil(this.studentProgressByExaminingBoardCount / LIMIT);
  }

  @action setStudentUsernameForProgressByExaminingBoard = (username: string) => {
    this.studentUsername = username;
  };

  @action setSortByForProgressByExaminingBoard = (sortBy: string) => {
    this.sortBy = sortBy;
  }

  @action studentProgressByExaminingBoardSetPage = (page: number) => {
    this.studentProgressByExaminingBoardPage = page;
  };

  @computed get studentProgressByExaminingBoard() {
    const spByEC = Array.from(this.studentProgressByExaminingBoardRegistry.values());
    return spByEC;
  }

  @action loadStudentProgressByExaminingBoard = async () => {
    this.loadingInitialProgressByExaminingBoard = true;
    try {
      this.studentProgressByExaminingBoardRegistry.clear();
      const studentProgressEnvelope =
        await agent.StudentProgress.detailsByExaminingBoard(
          this.studentProgressByExaminingBoardAxiosParams
        );
      const { dataCollection, dataCount } = studentProgressEnvelope;

      runInAction(() => {
        dataCollection.forEach((item) => {
          this.studentProgressByExaminingBoardRegistry.set(item.examiningBoardId, item);
        });
        this.studentProgressByExaminingBoardCount = dataCount;
        this.loadingInitialProgressByExaminingBoard = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingInitialProgressByExaminingBoard = false;
      });
      toast.error(
        "Não foi possível carregar informações do progresso por banca"
      );
    }
  };
}
