import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import SubcategoryList from "./SubcategoryList";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../app/stores/rootStore";
import LoadComponent from "../../app/layout/LoadComponent";
import { Link } from "react-router-dom";
import TextInput from "../../app/common/form/TextInput";
import SelectInput from "../../app/common/form/SelectInput";
import { CategoryForSelect } from "../../app/models/category";

const SubcategoryDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadSubcategories,
    loadingInitial,
    subcategoryFilter,
    setPredicate,
  } = rootStore.subcategoryStore;
  const { loadCategoriesForSelect } = rootStore.categoryStore;

  const [filterVisible, setFilterVisible] = useState(false);
  const [categoriesForSelect, setCategoriesForSelect] = useState<
    CategoryForSelect[]
  >([]);

  const sortBy = [
    { key: "", text: "", value: "" },
    { key: "name", text: "Nome", value: "name" },
    { key: "name_desc", text: "Nome Desc", value: "name_desc" },
    { key: "questioncount", text: "Questões", value: "questioncount" },
    { key: "questioncount_desc", text: "Questões Desc", value: "questioncount_desc" }
  ];

  useEffect(() => {
    loadSubcategories();

    loadCategoriesForSelect().then((categories) => {
      let categoryList: CategoryForSelect[] = [];
      categoryList.push(new CategoryForSelect("", "", ""));
      categories?.forEach((cat) => {
        let category = new CategoryForSelect(cat.id, cat.name, cat.id);
        categoryList.push(category);
      });
      setCategoriesForSelect(categoryList);
    });
  }, [loadSubcategories, loadCategoriesForSelect]);

  if (loadingInitial)
    return <LoadComponent content="Carregando subcategorias..." />;

  const handleSubmitFilter = (values: any) => {
    const { ...subcatFilter } = values;
    subcategoryFilter.categoryId = subcatFilter.categoryId;
    subcategoryFilter.name = subcatFilter.name;
    subcategoryFilter.sortBy = subcatFilter.sortBy;
    setPredicate();
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={14}>
          <h1>Subcategorias</h1>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            as={Link}
            to="/admin/novasubcategoria"
            active
            positive
            content="Nova"
            floated="right"
            style={{ marginTop: "12px" }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment>
            <h2 onClick={() => setFilterVisible(!filterVisible)}>Filtros</h2>
            {(filterVisible ||
              subcategoryFilter.name ||
              subcategoryFilter.categoryId) && (
              <Segment>
                <FinalForm
                  initialValues={subcategoryFilter}
                  onSubmit={handleSubmitFilter}
                  render={({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                        <Field
                          name="name"
                          placeholder="Subcategoria"
                          value={subcategoryFilter!.name}
                          component={TextInput}
                          width={3}
                        />
                        <Field
                          component={SelectInput}
                          options={categoriesForSelect}
                          name="categoryId"
                          placeholder="Categoria"
                          value={subcategoryFilter!.categoryId}
                          width={5}
                        />
                        <Field
                          component={SelectInput}
                          options={sortBy}
                          name="sortBy"
                          placeholder="Ordenar por"
                          value={subcategoryFilter!.sortBy}
                          width={2}
                        />
                        <Button content="Pesquisar" />
                      </Form.Group>
                    </Form>
                  )}
                />
              </Segment>
            )}
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <SubcategoryList />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(SubcategoryDashboard);
