import React, { Fragment, useContext, useEffect } from "react";
import { Button, Container, Header, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { history } from "../..";

const EmailConfirmation = () => {
  const rootStore = useContext(RootStoreContext);
  const { emailConfirmation } = rootStore.userStore;

  useEffect(() => {
    emailConfirmation();
  }, [emailConfirmation]);

  return (
    <Segment inverted textAlign="center" vertical className="masthead">
      <Container text>
        <Fragment>
          <Header as="h1" inverted>
            CFC Academy
          </Header>
          <Header
            as="h3"
            inverted
            content="Email confirmado com sucesso, clique no botão abaixo para ir para a página inicial de login."
          />
          <Button
            style={{ marginTop: "20px" }}
            size="huge"
            inverted
            onClick={() => history.push("/")}
          >
            Página inicial
          </Button>
        </Fragment>
      </Container>
    </Segment>
  );
};

export default EmailConfirmation;
