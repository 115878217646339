import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Grid } from "semantic-ui-react";
import { IAnswer } from "../../../app/models/answer";
import { RootStoreContext } from "../../../app/stores/rootStore";
import AnswerListItem from "./AnswerListItem";
import AnswerForm from "./form/AnswerForm";

const AnswerList: React.FC<{ questionId: string }> = ({ questionId }) => {
  const rootStore = useContext(RootStoreContext);
  const { loadAnswers, answersByIdentifier } = rootStore.answerStore;
  const { openModal } = rootStore.modalStore;

  const [answerEditMode, setAnswerEditMode] = useState(false);

  useEffect(() => {
    loadAnswers(questionId);
  }, [loadAnswers, questionId]);

  const handleAnswerEditMode = () => {
    setAnswerEditMode(!answerEditMode);
  };
  return (
    <Fragment>
      <Grid>
        <Grid.Column width={12} />
        <Grid.Column width={4}>
          <Button
            onClick={() => openModal(<AnswerForm />, "large", false, true)}
            positive
            content="Adicionar"
            floated="right"
          />
          <Button
            secondary
            content={answerEditMode ? "Cancelar" : "Editar"}
            floated="right"
            onClick={() => handleAnswerEditMode()}
          />
        </Grid.Column>
      </Grid>
      <Grid divided>
        <Grid.Row stretched>
          <Grid.Column>
            {answersByIdentifier.map((answer: IAnswer) => (
              <AnswerListItem key={answer.id} answer={answer} answerEditMode={answerEditMode} />
            ))}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

export default observer(AnswerList);
