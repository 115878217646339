import { observer } from "mobx-react-lite";
import React from "react";
import { NavLink } from "react-router-dom";
import { Button, Rating, Table } from "semantic-ui-react";
import { IExamUserHistory } from "../../../../app/models/examUser";

const ExamHistoryListItem: React.FC<{ examUser: IExamUserHistory }> = ({
  examUser,
}) => {
  const getStarCount = (percentage: number) => {
    if (percentage <= 10) return 0;
    if (percentage >= 11 && percentage <= 30) return 1;
    if (percentage >= 31 && percentage <= 50) return 2;
    if (percentage >= 51 && percentage <= 70) return 3;
    if (percentage >= 71 && percentage <= 90) return 4;
    if (percentage >= 91) return 5;
  };

  return (
    <Table.Row>
      <Table.Cell>{examUser.examName}</Table.Cell>
      <Table.Cell>{examUser.endDate}</Table.Cell>
      <Table.Cell>{Math.trunc(examUser.examResultScore)}%</Table.Cell>
      <Table.Cell>
        <Rating
          icon="star"
          defaultRating={5}
          maxRating={getStarCount(examUser.examResultScore)}
          disabled
        />
      </Table.Cell>
      <Table.Cell>
        <Button
          primary
          content="Ver respostas"
          as={NavLink}
          to={`/aluno/resultadosimulados/${examUser.id}`}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default observer(ExamHistoryListItem);
