import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import ExaminingBoardList from "./ExaminingBoardList";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/rootStore";
import LoadComponent from "../../../app/layout/LoadComponent";
import { Link } from "react-router-dom";
import TextInput from "../../../app/common/form/TextInput";
import SelectInput from "../../../app/common/form/SelectInput";

const ExaminingBoardDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadExaminingBoards,
    loadingInitial,
    examiningBoardFilter,
    setPredicate,
  } = rootStore.examiningBoardStore;

  const [filterVisible, setFilterVisible] = useState(false);

  const sortBy = [
    { key: "", text: "", value: "" },
    { key: "code", text: "Código", value: "code" },
    { key: "code_desc", text: "Código Desc", value: "code_desc" },
    { key: "name", text: "Nome", value: "name" },
    { key: "name_desc", text: "Nome Desc", value: "name_desc" },
  ];

  useEffect(() => {
    loadExaminingBoards();
  }, [loadExaminingBoards]);

  if (loadingInitial) return <LoadComponent content="Carregando bancas..." />;

  const handleSubmitFilter = (values: any) => {
    const { ...exBdFilter } = values;
    examiningBoardFilter.code = exBdFilter.code;
    examiningBoardFilter.name = exBdFilter.name;
    examiningBoardFilter.sortBy = exBdFilter.sortBy;
    setPredicate();
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={14}>
          <h1>Bancas</h1>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            as={Link}
            to="/admin/novabanca"
            active
            positive
            content="Nova"
            floated="right"
            style={{ marginTop: "12px" }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment>
            <h2 onClick={() => setFilterVisible(!filterVisible)}>Filtros</h2>
            {(filterVisible || examiningBoardFilter.code || examiningBoardFilter.name) && (
              <Segment>
                <FinalForm
                  initialValues={examiningBoardFilter}
                  onSubmit={handleSubmitFilter}
                  render={({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group>
                      <Field
                          name="code"
                          placeholder="Sigla"
                          value={examiningBoardFilter!.code}
                          component={TextInput}
                          width={3}
                        />
                        <Field
                          name="name"
                          placeholder="Banca"
                          value={examiningBoardFilter!.name}
                          component={TextInput}
                          width={3}
                        />
                        <Field
                          component={SelectInput}
                          options={sortBy}
                          name="sortBy"
                          placeholder="Ordenar por"
                          value={examiningBoardFilter!.sortBy}
                          width={2}
                        />
                        <Button content="Pesquisar" />
                      </Form.Group>
                    </Form>
                  )}
                />
              </Segment>
            )}
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <ExaminingBoardList />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default observer(ExaminingBoardDashboard);
