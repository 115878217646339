import { action, computed, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { format } from "date-fns";

const LIMIT = 4;

export default class UserPlanStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable userPlanRegistry = new Map();
  @observable loadingInitial = false;
  @observable userPlanCount = 0;
  @observable page = 1;
  @observable predicate = new Map();

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page > 1 ? (this.page - 1) * LIMIT : 0}`);

    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.userPlanCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @computed get userPlansByStartDate() {
    const userPlans = Array.from(this.userPlanRegistry.values());
    return userPlans;
  }

  @action loadUserPlans = async (username: string) => {
    this.loadingInitial = true;
    try {
      this.userPlanRegistry.clear();
      var params = this.axiosParams;
      params.append("username", username);
      const userPlanEnvelope = await agent.UserPlans.list(params);
      const { dataCollection, dataCount } = userPlanEnvelope;
      runInAction("loading user plans", () => {
        dataCollection.forEach((userPlan) => {
          this.userPlanRegistry.set(userPlan.id, userPlan);
        });
        this.userPlanCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load user plans error", () => {
        this.loadingInitial = false;
      });
    }
  };

  @action currentPlan = async () => {
    try {
      var currentPlan = agent.UserPlans.currentPlan();
      return currentPlan;
    } catch (error) {
      toast.error("Erro ao carregar plano atual");
    }
  };

  @action set7DaysTrialToStudent = async (username: string, planCode: string) => {
    try {
      await agent.UserPlans.set7DaysTrialToStudent(username, planCode);
      runInAction(() => {
        this.loadUserPlans(username);
      });
    } catch (error) {
      if (error.data.errors.plan) {
        toast.error(error.data.errors.plan);
      } else {
        toast.error("Erro ao conceder 7 dias gratuito no plano Gold");
      }
    }
  };

  @action subscribeUserToPlan = async (username: string, planCode: string) => {
    try {
      var today = format(new Date(), "yyyy-MM-dd");
      await agent.UserPlans.subscribeUserToPlan(username, planCode, today);
      runInAction(() => {
        this.loadUserPlans(username);
      });
    } catch (error) {
      if (error.data.errors.plan) {
        toast.error(error.data.errors.plan);
      } else {
        toast.error("Erro ao inscrever usuario ao plano M3R");
      }
    }
  };

  @action cancelSubscription = async (id: string, reason: string) => {
    try {
      var nowDate = format(new Date(), "yyyy-MM-dd");
      await agent.UserPlans.cancelSubscription(id, nowDate, reason);
      toast.success("Plano cancelado com sucesso");
    } catch (error) {
      toast.error("Erro ao cancelar inscrição");
    }
  };
}
