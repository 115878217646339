import React, { Fragment, useEffect, useState } from "react";
import { Header, Grid, Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import PhotoWidgetDropzone from "./PhotoWidgetDropzone";
import PhotoWidgetCropper from "./PhotoWidgetCropper";

interface IProps {
  uploadPhoto: (file: Blob) => void;
  loading: boolean;
}

const PhotoUploadWidget: React.FC<IProps> = ({ uploadPhoto, loading }) => {
  const [files, setFiles] = useState<any[]>([]);
  const [image, setImage] = useState<Blob | null>(null);

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  });

  return (
    <Fragment>
      <Grid>
        {
          (files.length === 0 && (
            <Grid.Column width={16}>
              <Header color="teal" sub content="Adicionar Imagem" />
              <PhotoWidgetDropzone setFiles={setFiles} />
            </Grid.Column>
          ))
        }
        {files.length > 0 && (
          <Grid.Column width={16}>
            <Header sub color="teal" content="Redimensionar Imagem" />
            <Fragment>
              <PhotoWidgetCropper
                setImage={setImage}
                imagePreview={files[0].preview}
              />
              <Button.Group widths={2}>
                <Button
                  positive
                  icon="check"
                  loading={loading}
                  onClick={() => uploadPhoto(image!)}
                />
                <Button
                  icon="close"
                  disabled={loading}
                  onClick={() => setFiles([])}
                />
              </Button.Group>
            </Fragment>
          </Grid.Column>
        )}
      </Grid>
    </Fragment>
  );
};

export default observer(PhotoUploadWidget);
