import React from "react";
import { ICategory } from "../../../app/models/category";
import { Table } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

const CategoryListItem: React.FC<{ category: ICategory }> = ({ category }) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Link to={`/admin/categorias/${category.id}`}>{category.code}</Link>
      </Table.Cell>
      <Table.Cell>{category.name}</Table.Cell>
      <Table.Cell>{category.description}</Table.Cell>
      <Table.Cell>{category.questionCount}</Table.Cell>
    </Table.Row>
  );
};

export default observer(CategoryListItem);
