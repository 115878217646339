import { RootStore } from "./rootStore";
import { observable, action, runInAction, computed, reaction } from "mobx";
import {
  CategoryFilter,
  CategoryForSelect,
  ICategory,
} from "../models/category";
import agent from "../api/agent";
import { toast } from "react-toastify";
import { history } from "../..";
import { SyntheticEvent } from "react";

const LIMIT = 10;

export default class CategoryStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.categoryRegistry.clear();
        this.loadCategories();
      }
    );
  }

  @observable categoryRegistry = new Map();
  @observable categoryQuestionsRegistry = new Map();
  @observable category: ICategory | null = null;
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";
  @observable loading = false;
  @observable categoryCount = 0;
  @observable page = 1;
  @observable predicate = new Map();
  @observable categoryFilter = new CategoryFilter();
  @observable categoriesForSelect: CategoryForSelect[] = [];

  @action setPredicate = () => {
    this.predicate.clear();

    if (this.categoryFilter.name) {
      this.predicate.set("name", this.categoryFilter!.name);
    }

    if (this.categoryFilter.sortBy) {
      this.predicate.set("sortby", this.categoryFilter!.sortBy);
    }
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page > 1 ? (this.page - 1) * LIMIT : 0}`);
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });

    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.categoryCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @computed get categoriesByName() {
    const categories = Array.from(this.categoryRegistry.values());
    return categories;
  }

  @computed get categoriesPerQuestion() {
    const categories = Array.from(this.categoryQuestionsRegistry.values());
    return categories;
  }

  @computed get getCategoriesForSelect() {
    const categories = Array.from(this.categoriesForSelect.values());
    return categories;
  }

  @computed get categoriesOptions() {
    const categories = Array.from(this.categoryRegistry.values());
    return categories.map((x) => ({ key: x.id, value: x.id, text: x.name }));
  }

  @action loadCategories = async () => {
    this.loadingInitial = true;
    try {
      this.categoryRegistry.clear();
      const categoriesEnvelope = await agent.Categories.list(this.axiosParams);
      const { dataCollection, dataCount } = categoriesEnvelope;
      runInAction("loading categories", () => {
        dataCollection.forEach((category) => {
          this.categoryRegistry.set(category.id, category);
        });
        this.categoryCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load categories error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar categorias");
    }
  };

  @action loadCategoriesPerQuestion = async () => {
    try {
      this.loadingInitial = true;
      this.categoryQuestionsRegistry.clear();
      let categoriesResult = await agent.Categories.listPerQuestions();

      runInAction("Getting categories per questions", () => {
        categoriesResult.forEach((category) => {
          this.categoryQuestionsRegistry.set(category.id, category);
        });
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("Getting categories per questions error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar categorias por questões");
    }
  };

  @action loadCategoriesForSelect = async () => {
    try {
      const params = new URLSearchParams();
      params.append("limit", "1000");
      const categoriesEnvelope = await agent.Categories.list(params);
      const { dataCollection } = categoriesEnvelope;
      return dataCollection;
    } catch (error) {
      toast.error("Erro ao carregar categorias");
    }
  };

  @action loadCategoriesOptions = async (onlyWithQuestions: boolean) => {
    try {
      this.loading = true;
      this.categoryRegistry.clear();
      const categoriesOptions = await agent.Categories.listForSelect(
        onlyWithQuestions
      );
      runInAction(() => {
        categoriesOptions.forEach((category) => {
          this.categoryRegistry.set(category.id, category);
          this.loading = false;
        });
      });
    } catch (error) {
      runInAction(() => {
        this.loading = true;
      });
      toast.error("Erro ao carregar categorias");
    }
  };

  @action loadCategory = async (id: string) => {
    let category = this.getCategory(id);
    if (category) {
      this.category = category;
      return category;
    } else {
      this.loadingInitial = true;
      try {
        category = await agent.Categories.details(id);
        runInAction("loading category", () => {
          this.category = category;
          this.categoryRegistry.set(category.id, category);
          this.loadingInitial = false;
        });
        return category;
      } catch (error) {
        runInAction("load category error", () => {
          this.loadingInitial = false;
        });
        toast.error("Erro ao carregar categoria");
      }
    }
  };

  @action clearCategory = () => {
    this.category = null;
  };

  getCategory = (id: string) => {
    return this.categoryRegistry.get(id);
  };

  @action createCategory = async (category: ICategory) => {
    this.submitting = true;
    try {
      await agent.Categories.create(category);
      runInAction("creating category", () => {
        this.submitting = false;
      });
      history.push("/admin/categorias/");
    } catch (error) {
      runInAction("create category error", () => {
        this.submitting = false;
      });
      toast.error("Erro ao criar categoria");
    }
  };

  @action editCategory = async (category: ICategory) => {
    this.submitting = true;
    try {
      await agent.Categories.update(category);
      runInAction("editing category", () => {
        this.categoryRegistry.set(category.id, category);
        this.category = category;
        this.submitting = false;
      });
      history.push("/admin/categorias/");
    } catch (error) {
      runInAction("edit category error", () => {
        this.submitting = false;
      });
      toast.error("Problem submitting data");
    }
  };

  @action deleteCategory = async (
    event: SyntheticEvent<HTMLButtonElement>,
    id: string
  ) => {
    this.submitting = true;
    this.target = event.currentTarget.name;
    try {
      await agent.Categories.delete(id);
      runInAction("deleting category", () => {
        this.categoryRegistry.delete(id);
        this.submitting = false;
        this.target = "";
      });
      history.push("/admin/categorias");
    } catch (error) {
      runInAction("delete category error", () => {
        this.submitting = false;
        this.target = "";
      });
    }
  };
}
