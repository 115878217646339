import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { IQuestion } from "../../app/models/question";

const QuestionListItem: React.FC<{ question: IQuestion }> = ({ question }) => {
  let correctAnswer = "";
  question.answers.forEach((answer) => {
    if (answer.isCorrect) {
      correctAnswer = answer.identifier;
    }
  });
  return (
    <Table.Row>
      <Table.Cell>
        <Link to={`/admin/questoes/${question.id}`}>{question.code}</Link>
      </Table.Cell>
      <Table.Cell>{question.text}</Table.Cell>
      <Table.Cell>{correctAnswer}</Table.Cell>
      <Table.Cell>{question.categoryCode}</Table.Cell>
      <Table.Cell>{question.subcategoryCode}</Table.Cell>
      <Table.Cell>{question.examiningBoardCode}</Table.Cell>
      <Table.Cell>{question.year}</Table.Cell>
    </Table.Row>
  );
};

export default observer(QuestionListItem);
