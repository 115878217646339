import { action, computed, observable, reaction, runInAction } from "mobx";
import { SyntheticEvent } from "react";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { CommentFilter, IComment } from "../models/comment";
import { RootStore } from "./rootStore";

const LIMIT = 10;

export default class CommentStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.commentRegistry.clear();
        this.loadComments();
      }
    );
  }

  @observable commentRegistry = new Map();
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";
  @observable page = 1;
  @observable commentCount = 0;
  @observable predicate = new Map();
  @observable commentFilter = new CommentFilter();
  @observable selectedComments: IComment[] = [];

  @action setPredicate = () => {
    this.predicate.clear();

    if (this.commentFilter.sortBy) {
      this.predicate.set("sortby", this.commentFilter!.sortBy);
    }

    this.predicate.set("answeredByProf", this.commentFilter!.answeredByProf);
  };

  @action selectComment = (comment: IComment) => {
    this.selectedComments.push(comment);
  };

  @action removeSelectedComment = (id: string) => {
    this.selectedComments = this.selectedComments.filter((x) => x.id !== id);
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page > 1 ? (this.page - 1) * LIMIT : 0}`);
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });

    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.commentCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @computed get commentsByPostedDate() {
    const comments = Array.from(this.commentRegistry.values());
    return comments;
  }

  @action loadComments = async () => {
    this.loadingInitial = true;
    try {
      this.commentRegistry.clear();
      const commentsEnvelope = await agent.Comments.list(this.axiosParams);
      const { dataCollection, dataCount } = commentsEnvelope;
      runInAction("loading comments", () => {
        dataCollection.forEach((comment) => {
          this.commentRegistry.set(comment.id, comment);
        });
        this.commentCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load comments error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar comentários");
    }
  };

  @action deleteComments = async (event: SyntheticEvent<HTMLButtonElement>) => {
    this.submitting = true;
    this.target = event.currentTarget.name;
    try {
      this.selectedComments.forEach((comment) => {
        agent.Comments.delete(comment.id);
        runInAction("deleting comment", () => {
          this.commentRegistry.delete(comment.id);
          this.submitting = false;
          this.target = "";
          this.selectedComments = [];
        });
      });
      toast.success("Comentário(s) apagado(s) com sucesso");
    } catch (error) {
      runInAction("delete comment error", () => {
        this.submitting = false;
        this.target = "";
      });
      toast.error("Erro ao deletar comentário");
    }
  };
}
