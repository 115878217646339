import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button, Grid, Segment, Tab } from "semantic-ui-react";
import ExamConfigForm from "./form/ExamConfigForm";
import ExamForm from "./form/ExamForm";

interface DetailParams {
  id: string;
}

const ExamItem: React.FC<RouteComponentProps<DetailParams>> = ({ match }) => {
  const panes = [
    {
      menuItem: "Simulado",
      render: () => (
        <Tab.Pane>
          <ExamForm id={match.params.id} />
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: "Configurações",
    //   render: () => (
    //     <Tab.Pane>
    //       <ExamConfigForm examId={match.params.id} />
    //     </Tab.Pane>
    //   ),
    // },
  ];

  if (match.params.id) {
    const configTab = {
      menuItem: "Configurações",
      render: () => (
        <Tab.Pane>
          <ExamConfigForm examId={match.params.id} />
        </Tab.Pane>
      ),
    };
    panes.push(configTab);
  }

  return (
    <Fragment>
      <Grid>
        <Grid.Column width={14}>
          {match.params.id ? <h1>Editar Simulado</h1> : <h1>Novo Simulado</h1>}
        </Grid.Column>
        <Grid.Column width={2}>
          {match.params.id && (
            <Button
              as={Link}
              to="/admin/novosimulado"
              active
              positive
              content="Novo"
              floated="right"
              style={{ marginTop: "12px" }}
            />
          )}
        </Grid.Column>
      </Grid>
      <Segment clearing>
        <Tab panes={panes} />
      </Segment>
    </Fragment>
  );
};

export default observer(ExamItem);
