export interface IExamSubcategory {
  id: string;
  examCategoryId: string;
  subcategoryId: string;
  subcategoryName: string;
  totalQuestions: number;
}

export default class ExamSubcategoryFormValues {
  id: string = "";
  examCategoryId: string = "";
  subcategoryId: string = "";
  totalQuestions: number = 0;

  constructor(init?: ExamSubcategoryFormValues) {
    Object.assign(this, init);
  }
}