import { action, computed, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { IModule } from "../models/module";
import { RootStore } from "./rootStore";

const LIMIT = 10;

export default class ModuleStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable moduleRegistry = new Map();
  @observable module: IModule | null = null;
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable target = "";
  @observable loading = false;
  @observable moduleCount = 0;
  @observable page = 1;
  @observable predicate = new Map();

  @action setPredicate = (courseId: string) => {
    this.predicate.clear();

    this.predicate.set("courseid", courseId);
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page > 1 ? (this.page - 1) * LIMIT : 0}`);

    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });

    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.moduleCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @computed get moduleByName() {
    const modules = Array.from(this.moduleRegistry.values());
    return modules;
  }

  @action loadModules = async () => {
    this.loadingInitial = true;
    try {
      this.moduleRegistry.clear();
      const modulesEnvelope = await agent.Modules.list(this.axiosParams);
      const { dataCollection, dataCount } = modulesEnvelope;
      runInAction("loading modules", () => {
        dataCollection.forEach((module) => {
          this.moduleRegistry.set(module.id, module);
        });
        this.moduleCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load modules error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar modulos");
    }
  };

  @action loadModulesForStudents = async () => {
    this.loadingInitial = true;
    try {
      this.moduleRegistry.clear();
      const modulesEnvelope = await agent.Modules.listForStudent();
      const { dataCollection, dataCount } = modulesEnvelope;
      runInAction("loading modules", () => {
        dataCollection.forEach((module) => {
          this.moduleRegistry.set(module.id, module);
        });
        this.moduleCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load modules error", () => {
        this.loadingInitial = false;
      });
      toast.error("Erro ao carregar modulos");
    }
  };

  @action loadModule = async (id: string) => {
    this.loadingInitial = true;
    let module = this.getModule(id);
    if (module) {
      this.module = module;
      this.loadingInitial = false;
      return module;
    } else {
      try {
        module = await agent.Modules.details(id);
        runInAction("loading module", () => {
          this.module = module;
          this.loadingInitial = false;
        });
        return module;
      } catch (error) {
        runInAction("load module error", () => {
          this.loadingInitial = false;
        });
        toast.error("Erro ao carregar modulo");
      }
    }
  };

  getModule = (id: string) => {
    return this.moduleRegistry.get(id);
  };

  @action createModule = async (module: IModule) => {
    this.submitting = true;
    try {
      module.order = Number(module.order);
      await agent.Modules.create(module);
      runInAction("creating module", () => {
        this.submitting = false;
      });
    } catch (error) {
      runInAction("create module error", () => {
        this.submitting = false;
      });
      toast.error("Erro ao criar modulo");
    }
  };

  @action editModule = async (module: IModule) => {
    this.submitting = true;
    try {
      module.order = Number(module.order);
      await agent.Modules.update(module);
      runInAction("editing module", () => {
        this.moduleRegistry.set(module.id, module);
        this.module = module;
        this.submitting = false;
      });
    } catch (error) {
      runInAction("edit module error", () => {
        this.submitting = false;
      });
      toast.error("Erro ao editar módulo");
    }
  };

  @action deleteModule = async (id: string) => {
    this.submitting = true;
    try {
      await agent.Modules.delete(id);
      runInAction("deleting module", () => {
        this.moduleRegistry.delete(id);
        this.moduleCount--;
        this.submitting = false;
      });
    } catch (error) {
      runInAction("delete module error", () => {
        this.submitting = false;
      });
    }
  };
}
