import React from "react";
import { ISubcategory } from "../../app/models/subcategory";
import { Table } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

const SubcategoryListItem: React.FC<{ subcategory: ISubcategory }> = ({ subcategory }) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Link to={`/admin/subcategorias/${subcategory.id}`}> {subcategory.code}</Link>
      </Table.Cell>
      <Table.Cell>{subcategory.name}</Table.Cell>
      <Table.Cell>{subcategory.description}</Table.Cell>
      <Table.Cell>{subcategory.categoryName}</Table.Cell>
      <Table.Cell>{subcategory.questionCount}</Table.Cell>
    </Table.Row>
  );
};

export default observer(SubcategoryListItem);
