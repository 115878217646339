import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { Button, Divider, Form, Grid, Segment } from "semantic-ui-react";
import SelectInput from "../../app/common/form/SelectInput";
import TextAreaInput from "../../app/common/form/TextAreaInput";
import TextInput from "../../app/common/form/TextInput";
import LoadComponent from "../../app/layout/LoadComponent";
import { RootStoreContext } from "../../app/stores/rootStore";
import { stateOptions } from "../../app/common/options/stateOptions";
import DateInput from "../../app/common/form/DateInput";

const validate = combineValidators({
  displayName: isRequired({ message: "Apelido é obrigatório" }),
  firstName: isRequired({ message: "Nome é obrigatório" }),
  lastName: isRequired({ message: "Sobrenome é obrigatório" }),
  gender: isRequired({ message: "Sexo é obrigatório" }),
  birthDate: isRequired({ message: "Data Nascimento é obrigatório" }),
});

const ProfileEditForm: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadProfile, profile, updateProfile, loadingProfile } =
    rootStore.profileStore;
  const { getUser } = rootStore.userStore;

  useEffect(() => {
    getUser().then((user) => loadProfile(user!.username));
  }, [getUser, loadProfile]);

  const genderOptions = [
    { key: "", text: "", value: "" },
    { key: "M", text: "Masculino", value: "M" },
    { key: "F", text: "Feminino", value: "F" },
  ];

  if (loadingProfile) return <LoadComponent content="Carregando perfil..." />;

  return (
    <Fragment>
      <h2>Perfil</h2>
      <Segment clearing>
        <FinalForm
          onSubmit={updateProfile}
          validate={validate}
          initialValues={profile!}
          render={({
            handleSubmit,
            invalid,
            pristine,
            submitting,
            submitSucceeded,
          }) => (
            <Form onSubmit={handleSubmit} error>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16} textAlign="right">
                    <label style={{ fontSize: "12pt" }}>
                      <strong>E-mail:</strong> {profile!.email}
                    </label>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Field
                      name="displayName"
                      title="Apelido"
                      component={TextInput}
                      placeholder="Apelido"
                      value={profile!.displayName}
                    />
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Field
                      name="firstName"
                      title="Nome"
                      component={TextInput}
                      placeholder="Nome"
                      value={profile!.firstName}
                    />
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Field
                      name="lastName"
                      title="Sobrenome"
                      component={TextInput}
                      placeholder="Sobrenome"
                      value={profile!.lastName}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={3}>
                    <Field
                      name="gender"
                      title="Sexo"
                      component={SelectInput}
                      placeholder="Sexo"
                      options={genderOptions}
                      value={profile!.gender}
                    />
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Field
                      name="cpf"
                      title="Cpf"
                      component={TextInput}
                      placeholder="Cpf"
                      value={profile!.cpf}
                    />
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Field
                      component={DateInput}
                      name="birthDate"
                      date={true}
                      title="Data Nascimento"
                      value={profile!.birthDate ?? ""}
                    />
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Field
                      name="phoneCode"
                      title="DDD"
                      component={TextInput}
                      placeholder="DDD"
                      value={profile!.phoneCode}
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Field
                      name="phoneNumber"
                      title="Telefone"
                      component={TextInput}
                      placeholder="Telefone"
                      value={profile!.phoneNumber}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Field
                      name="bio"
                      title="Descriçāo"
                      component={TextAreaInput}
                      rows={3}
                      placeholder="Bio"
                      value={profile!.bio}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider horizontal>Endereço</Divider>
                <Grid.Row>
                  <Grid.Column width={2}>
                    <Field
                      name="number"
                      title="Número"
                      component={TextInput}
                      placeholder="Número"
                      value={profile!.number}
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Field
                      name="street"
                      title="Rua"
                      component={TextInput}
                      placeholder="Rua"
                      value={profile!.street}
                    />
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Field
                      name="neighborhood"
                      title="Bairro"
                      component={TextInput}
                      placeholder="Bairro"
                      value={profile!.neighborhood}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Field
                      name="city"
                      title="Cidade"
                      component={TextInput}
                      placeholder="Cidade"
                      value={profile!.city}
                    />
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Field
                      name="state"
                      title="Estado"
                      options={stateOptions}
                      component={SelectInput}
                      placeholder="Estado"
                      value={profile!.state}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Field
                      name="cep"
                      title="CEP"
                      component={TextInput}
                      placeholder="CEP"
                      value={profile!.cep}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Button
                      loading={submitting}
                      floated="right"
                      disabled={invalid || pristine || submitSucceeded}
                      positive
                      content="Salvar"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        />
      </Segment>
    </Fragment>
  );
};

export default observer(ProfileEditForm);
