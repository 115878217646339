export interface ICommentsEnvelope {
  dataCollection: IComment[];
  dataCount: number;
}

export interface IComment {
  id: string;
  questionId: string;
  appUserId: string;
  questionCode: string;
  username: string;
  displayName: string;
  gender: string;
  text: string;
  postedDate: string;
  parentCommentId: string;
  parentCommentUsername: string;
  parentCommentDisplayName: string;
  parentCommentText: string;
  parentCommentPostedDate: string;
  answeredByProfessor: boolean;
  isProfessorAnswer: boolean;
  replys: IComment[];
}

export class CommentFilter {
  sortBy: string = "";
  answeredByProf: boolean = false;
}

export class CommentFormValues {
  id?: string = undefined;
  questionId: string = "";
  appUserId: string = "";
  displayName: string = "";
  text: string = "";
  postedDate?: Date = undefined;

  constructor(init?: CommentFormValues) {
    Object.assign(this, init);
  }
}

export class CommentReplyFormValues {
  id?: string = undefined;
  questionId: string = "";
  appUserId: string = "";
  displayName: string = "";
  text: string = "";
  postedDate?: Date = undefined;
  parentCommentId: string = "";

  constructor(init?: CommentReplyFormValues) {
    Object.assign(this, init);
  }
}