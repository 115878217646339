import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Card } from "semantic-ui-react";
import { IExam } from "../../../../app/models/exam";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import MobileReleasedExamsListItem from "./MobileReleasedExamsListItem";

const MobileReleasedExamsList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { examsByName } = rootStore.examStore;

  return (
    <Card.Group>
      {examsByName.map((exam: IExam) => (
        <MobileReleasedExamsListItem key={exam.id} exam={exam} />
      ))}
    </Card.Group>
  );
};

export default observer(MobileReleasedExamsList);
