import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Confirm, Popup, Table } from "semantic-ui-react";
import { ICourse } from "../../app/models/course";
import { RootStoreContext } from "../../app/stores/rootStore";
import CourseForm from "./form/CourseForm";

const CourseListItem: React.FC<{ course: ICourse }> = ({ course }) => {
  const rootStore = useContext(RootStoreContext);
  const { deleteCourse } = rootStore.courseStore;
  const { openModal } = rootStore.modalStore;

  const [courseId, setCourseId] = useState("");
  const [eventTarget, setEventTarget] =
    useState<React.SyntheticEvent<HTMLButtonElement> | null>(null);

  const [state, setState] = useState({ open: false });
  const open = () => setState({ open: true });
  const close = () => setState({ open: false });

  const handleDeleteCourse = (
    e: React.SyntheticEvent<HTMLButtonElement>,
    id: string
  ) => {
    setEventTarget(e);
    setCourseId(id);
    open();
  };

  return (
    <Fragment>
      <Confirm
        open={state.open}
        header="Deseja realmente excluir o curso?"
        content="Isso excluirá automaticamente todas os módulos e aulas associados a ele."
        cancelButton="Nāo"
        confirmButton="Sim"
        onConfirm={() => {
          deleteCourse(eventTarget!, courseId);
          close();
        }}
        onCancel={close}
      />
      <Table.Row>
        <Table.Cell>
          <Link
            onClick={() =>
              openModal(<CourseForm id={course.id} />, "small", false, true)
            }
            to={"/admin/cursos/#"}
          >
            {course.name}
          </Link>
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Popup
            trigger={
              <Button
                icon="folder"
                as={Link}
                to={`/admin/cursos/${course.id}`}
                basic
              />
            }
            content="Módulos"
            position="top center"
          />
          <Popup
            trigger={
              <Button
                icon="delete"
                basic
                onClick={(e) => handleDeleteCourse(e, course.id)}
              />
            }
            content="Excluir"
            position="top center"
          />
        </Table.Cell>
      </Table.Row>
    </Fragment>
  );
};

export default observer(CourseListItem);
