import { action, observable, runInAction } from "mobx";
import { SyntheticEvent } from "react";
import agent from "../api/agent";
import { RootStore } from "./rootStore";

export default class UserLessonStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable submitting = false;
  @observable target = "";

  @action setViewed = async (
    event: SyntheticEvent<HTMLButtonElement>,
    lessonId: string
  ) => {
    this.submitting = true;
    this.target = event.currentTarget.name;
    try {
      await agent.UserLessons.create(lessonId);
      runInAction("setting lesson viewed", () => {
        this.rootStore.lessonStore.lesson!.lessonViewed = true;
        this.submitting = false;
        this.target = "";
      });
    } catch (error) {
      runInAction("setting lesson viewed error", () => {
        this.submitting = false;
        this.target = "";
      });
    }
  };

  @action setNotViewed = async (
    event: SyntheticEvent<HTMLButtonElement>,
    lessonId: string
  ) => {
    this.submitting = true;
    this.target = event.currentTarget.name;
    try {
      await agent.UserLessons.delete(lessonId);
      runInAction("setting lesson not viewed", () => {
        this.rootStore.lessonStore.lesson!.lessonViewed = false;
        this.submitting = false;
        this.target = "";
      });
    } catch (error) {
      runInAction("setting lesson not viewed error", () => {
        this.submitting = false;
        this.target = "";
      });
    }
  };
}
