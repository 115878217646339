import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import LoadComponent from "../../app/layout/LoadComponent";
import { RootStoreContext } from "../../app/stores/rootStore";
import LessonCommentList from "./LessonCommentList";

const LessonCommentDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadComments, loadingInitial } = rootStore.lessonCommentStore;

  useEffect(() => {
    loadComments();
  }, [loadComments]);

  if (loadingInitial)
    return <LoadComponent content="Carregando comentários..." />;
    
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={14}>
          <h1>Comentários das Aulas</h1>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <LessonCommentList />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(LessonCommentDashboard);
