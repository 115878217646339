import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import React, { Fragment, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Form, Grid, Message, Segment } from "semantic-ui-react";
import LoadComponent from "../../../app/layout/LoadComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import StudentProgressByCategoryList from "../../student/progress/byCategory/StudentProgressByCategoryList";
import StudentProgressBySubcategoryList from "../../student/progress/bySubcategory/StudentProgressBySubcategoryList";
import UserProfileExamSummary from "./UserProfileExamSummary";
import UserProfileSubscription from "./UserProfileSubscription";
import UserProfileGeneralProgress from "./UserProfileGeneralProgress";

interface DetailParams {
  id: string;
}

const UserProfile: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { loadProfile, profile } = rootStore.profileStore;
  const {
    loadStudentProgressBySubcategory,
    setStudentUsername,
    loadingInitialProgressBySubCategory
  } = rootStore.studentProgressBySubcategoryStore;

  const {
    loadStudentProgressByCategory,
    setStudentUsernameForProgressBycategory,
    loadingInitialProgressByCategory
  } = rootStore.studentProgressByCategoryStore;

  const { loadData, studentDashboard, loadingInitial } = rootStore.studentDashboardStore;

  useEffect(() => {
    loadProfile(match.params.id);
    setStudentUsername(match.params.id);
    setStudentUsernameForProgressBycategory(match.params.id);
    loadData(match.params.id);
    loadStudentProgressByCategory();
    loadStudentProgressBySubcategory();
  }, [
    loadProfile,
    match.params.id,
    setStudentUsername,
    setStudentUsernameForProgressBycategory,
    loadData,
    loadStudentProgressByCategory,
    loadStudentProgressBySubcategory,
  ]);

  if (loadingInitial || loadingInitialProgressByCategory || loadingInitialProgressBySubCategory)
    return <LoadComponent content="Carregando inforrmações..." />;

  return (
    <Fragment>
      {profile && (
        <Fragment>
          <h1>
            {profile!.displayName} ({profile!.username})
          </h1>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Segment>
                  <h2>Dados Pessoais</h2>
                  <Form>
                    <Grid columns={2}>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Field>
                            <label>Nome</label>
                            <span>
                              {profile!.firstName} {profile!.lastName}
                            </span>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field>
                            <label>CPF</label>
                            <span>{profile!.cpf}</span>
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Field>
                            <label>Data de Nascimento</label>
                            {profile!.birthDate && (
                              <span>
                                {format(
                                  new Date(profile!.birthDate),
                                  "dd/MM/yyyy"
                                )}
                              </span>
                            )}
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field>
                            <label>Sexo</label>
                            <span>
                              {profile!.gender === "M"
                                ? "Masculino"
                                : profile!.gender === "F"
                                ? "Feminino"
                                : "Não informado"}
                            </span>
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Field>
                            <label>Telefone</label>
                            {profile!.phoneNumber && (
                              <span>
                                ({profile!.phoneCode}) {profile!.phoneNumber}
                              </span>
                            )}
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field>
                            <label>Email</label>
                            <span>{profile!.email}</span>
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Field>
                            <label>Bio</label>
                            <span>{profile!.bio}&nbsp;</span>
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Segment>
              </Grid.Column>
              <Grid.Column width={8}>
                <Segment>
                  <h2>Endereço</h2>
                  <Form>
                    <Grid columns={2}>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Field>
                            <label>Nº</label>
                            <span>{profile!.number}</span>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field>
                            <label>Rua</label>
                            <span>{profile!.street}</span>
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Field>
                            <label>Bairro</label>
                            <span>{profile!.neighborhood}</span>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field>
                            <label>Cidade</label>
                            <span>{profile!.city}</span>
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Field>
                            <label>Estado</label>
                            <span>{profile!.state}</span>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Form.Field>
                            <label>CEP</label>
                            <span>{profile!.cep}</span>
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Field>
                            <label>&nbsp;</label>
                            <span>&nbsp;</span>
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <Segment>
                  {studentDashboard &&
                  studentDashboard.mySuccessPercentge > 0 ? (
                    <UserProfileGeneralProgress />
                  ) : (
                    <Fragment>
                      <h2>Aprovômetro</h2>
                      <Message
                        icon="warning circle"
                        header="Oops"
                        content="Ainda não há dados suficientes"
                      />
                    </Fragment>
                  )}
                </Segment>
              </Grid.Column>
              <Grid.Column width={8}>
                <Segment>
                  <UserProfileSubscription username={match.params.id} />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h2>Questões respondidas por categoria</h2>
                <Segment>
                  <StudentProgressByCategoryList />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h2>Questões respondidas por subcategoria</h2>
                <Segment>
                  <StudentProgressBySubcategoryList />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h2>
                  Simulados respondidos com percentual de acerto superior a 70%
                </h2>
                <Segment>
                  <UserProfileExamSummary username={match.params.id} />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

export default observer(UserProfile);
