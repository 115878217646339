export interface IExamsEnvelope {
  dataCollection: IExam[];
  dataCount: number;
}

export interface IExam {
  id: string;
  name: string;
  description: string;
  totalQuestions: number;
  durationTime: number;
  released: boolean;
  examiningBoardId: string;
  examiningBoardCode?: string;
}

export class ExamFormValues {
  id?: string = undefined;
  name: string = "";
  description: string = "";
  totalQuestions: number = 0;
  durationTime: number = 0;
  released: boolean = false;
  examiningBoardId?: string = undefined;
  examiningBoardCode?: string = "";

  constructor(init?: ExamFormValues) {
    Object.assign(this, init);
  }
}
