import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import LoadComponent from "../../../../app/layout/LoadComponent";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import ExamHistoryList from "./ExamHistoryList";

const ExamHistory: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadExamHistory, initialLoading } = rootStore.examUsersStore;

  useEffect(() => {
    loadExamHistory();
  }, [loadExamHistory]);

  if (initialLoading) return <LoadComponent content="Carregando resultados" />

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <h1>Resultados</h1>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <ExamHistoryList />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(ExamHistory);
