import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Button, Card, Grid, Image } from "semantic-ui-react";
import PhotoUploadWidget from "../../../app/common/photoUpload/PhotoUploadWidget";
import { RootStoreContext } from "../../../app/stores/rootStore";

const ImageList: React.FC<{ questionId: string }> = ({ questionId }) => {
  const rootStore = useContext(RootStoreContext);
  const { question, uploadImage, deleteImage, uploadingImage, loading } =
    rootStore.questionStore;

  const [addImageMode, setAddImageMode] = useState(false);
  const [deleteTarget, setDeleteTarget] =
    useState<string | undefined>(undefined);

  const handleUploadImage = (image: Blob) => {
    uploadImage(image, questionId).then(() => setAddImageMode(false));
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={12} />
        <Grid.Column width={4}>
          <Button
            positive
            content={addImageMode ? "Cancel" : "Adicionar"}
            onClick={() => setAddImageMode(!addImageMode)}
            floated="right"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          {addImageMode ? (
            <PhotoUploadWidget
              uploadPhoto={handleUploadImage}
              loading={uploadingImage}
            />
          ) : (
            <Card.Group itemsPerRow={5}>
              {question &&
                question.images &&
                question.images.map((image) => (
                  <Card key={image.id}>
                    <Image
                      src={image.url}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <Button
                      name={image.id}
                      style={{ position: "absolute", bottom: 0 }}
                      onClick={(e) => {
                        deleteImage(questionId, image);
                        setDeleteTarget(e.currentTarget.name);
                      }}
                      loading={loading && deleteTarget === image.id}
                      basic
                      fluid
                      icon="trash"
                    />
                  </Card>
                ))}
            </Card.Group>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(ImageList);
