import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Accordion, Icon, Segment } from "semantic-ui-react";

const FAQ: React.FC = () => {
  const [state, setState] = useState({ activeIndex: 0 });

  const handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    const { activeIndex } = state;
    const newIndex = activeIndex === index ? -1 : index;

    setState({ activeIndex: newIndex });
  };

  const { activeIndex } = state;

  return (
    <Segment basic className="faq">
      <h1 style={{ textAlign: "center", marginBottom: "75px" }}>
        Perguntas Frequentes
      </h1>
      <Accordion fluid styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
          className="faq-title"
        >
          <Icon name="dropdown" />O que é o CFC ACADEMY?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0} className="faq-content">
          <p>
            O CFC Academy é uma plataforma de estudos focada para quem deseja
            ser aprovado no Exame de Suficiência do CFC, CNAI, CNPC ou concursos
            públicos estudando 2 horas por dia.
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={handleClick}
          className="faq-title"
        >
          <Icon name="dropdown" />
          Nao tenho base em contabilidade e auditoria. O CFC Academy é para mim?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1} className="faq-content">
          <p>
            Sim. O CFC academy é focado em aprender contabilidade e auditoria do
            absoluto zero até a sua aprovação. Mesmo que você não tenha
            conhecimento algum em contabilidade, poderá ser aprovado se utilizar
            o CFC Academy.
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 2}
          index={2}
          onClick={handleClick}
          className="faq-title"
        >
          <Icon name="dropdown" />E se eu não gostar?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 2} className="faq-content">
          <p>
            Caso você sinta que o CFC Academy não atendeu às suas necessidades,
            você pode enviar um e-mail para{" "}
            <a href="mailto:cancelamento@cfcacademy.com.br">
              cancelamento@cfcacademy.com.br
            </a>{" "}
            e solicitar seu cancelamento e reembolso. Você terá 7 dias para
            realizar o pedido de reembolso.
          </p>
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 3}
          index={3}
          onClick={handleClick}
          className="faq-title"
        >
          <Icon name="dropdown" />
          Como funciona o suporte da plataforma?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 3} className="faq-content">
          <p>
            Você pode solicitar o suporte de duas formas: para tirar dúvidas
            sobre o conteúdo das aulas ministradas ou das questões, você deve
            deixar um comentário na parte de comentários que o nosso suporte irá
            responder você o mais breve possível.
          </p>
          <p>
            Já para problemas técnicos, você de enviar um e-mail para{" "}
            <a href="mailto:felipe@cfcacademy.com.br">
              felipe@cfcacademy.com.br
            </a>
            .
          </p>
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 4}
          index={4}
          onClick={handleClick}
          className="faq-title"
        >
          <Icon name="dropdown" />O que está incluso?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 4} className="faq-content">
          <p>
            Ao assinar o Método 3R você recebe acesso à nossa metodologia
            exclusiva criada para pessoas que dispõem de pouco tempo para se
            preparar para estudar, apostilas completas, mapas mentais, acesso
            ilimitado a simulados e milhares de questões, cronograma de estudos,
            análise de desempenho automatizada e tira-dúvidas com o professor.
          </p>
        </Accordion.Content>
        <Accordion.Title
          active={activeIndex === 5}
          index={5}
          onClick={handleClick}
          className="faq-title"
        >
          <Icon name="dropdown" />
          Quais são as formas de pagamento?
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 5} className="faq-content">
          <p>Você pode pagar com cartão de crédito, débito, boleto e pix.</p>
        </Accordion.Content>
      </Accordion>
    </Segment>
  );
};

export default observer(FAQ);
