import React, { Fragment, useContext } from "react";
import { Table, Segment, Pagination } from "semantic-ui-react";
import ExaminingBoardListItem from "./ExaminingBoardListItem";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/rootStore";

const ExaminingBoardList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    examiningBoardsByName,
    loadExaminingBoards,
    setPage,
    page,
    totalPages,
  } = rootStore.examiningBoardStore;

  const onPageChange = (e: any, pageInfo: any) => {
    setPage(pageInfo.activePage);
    loadExaminingBoards();
  };

  return (
    <Fragment>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Sigla</Table.HeaderCell>
            <Table.HeaderCell width={5}>Banca</Table.HeaderCell>
            <Table.HeaderCell width={7}>Descriçāo</Table.HeaderCell>
            <Table.HeaderCell width={2}>Qtd. Questões</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {examiningBoardsByName.map((examiningBoard) => (
            <ExaminingBoardListItem
              key={examiningBoard.id}
              examiningBoard={examiningBoard}
            />
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4">
              <Segment basic clearing floated="right">
                <Pagination
                  defaultActivePage={page}
                  onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
                  totalPages={totalPages}
                />
              </Segment>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Fragment>
  );
};

export default observer(ExaminingBoardList);
