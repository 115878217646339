import React, { useContext, Fragment, useState } from "react";
import { Menu, Icon, Button, Label, Confirm, Sidebar } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import MobileProfileEditForm from "../profiles/MobileProfileEditForm";

const MobileNavBar: React.FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
  const rootStore = useContext(RootStoreContext);
  const { user, logout } = rootStore.userStore;
  const { deleteAllAnswersByStudent } = rootStore.userAnswerStore;
  const { deleteAllExamsByStudent } = rootStore.examUsersStore;
  const { loadData } = rootStore.studentDashboardStore;
  const { openModal } = rootStore.modalStore;

  const [state, setState] = useState({ open: false });
  const open = () => setState({ open: true });
  const close = () => setState({ open: false });
  const [menuVisible, setMenuVisible] = useState(false);

  const resetAnswers = () => {
    deleteAllAnswersByStudent().then(() =>
      deleteAllExamsByStudent().then(() => loadData("0"))
    );
  };

  return (
    <Fragment>
      <Confirm
        open={state.open}
        header="Deseja realmente resetar seu desempenho?"
        content="Isso excluirá automaticamente todas as questões e simulados respondidos."
        cancelButton="Nāo"
        confirmButton="Sim"
        onConfirm={() => {
          resetAnswers();
          close();
        }}
        onCancel={close}
      />

      <Menu fixed="top" borderless>
        <Menu.Item header style={{ color: "dimgrey" }}>
          CFC Academy
        </Menu.Item>
        <Menu.Item position="right">
          {!isAdmin && user && (
            <Fragment>
              {!user.activePlanName ? (
                <Button
                  content="Assinar"
                  color="green"
                  href="https://cfcacademy.com.br/cfc-questoes-pv-1/"
                />
              ) : (
                <Label color="teal" style={{ fontSize: "12pt" }}>
                  <Icon name="check square outline" />
                  {user.activePlanName.length > 10
                    ? user.activePlanName.substring(0, 9) + "..."
                    : user.activePlanName}
                </Label>
              )}
            </Fragment>
          )}
        </Menu.Item>
        <Menu.Item>
          {menuVisible ? (
            <Icon name="close" onClick={() => setMenuVisible(!menuVisible)} />
          ) : (
            <Icon name="sidebar" onClick={() => setMenuVisible(!menuVisible)} />
          )}
        </Menu.Item>
      </Menu>
      <Sidebar
        as={Menu}
        animation={"overlay"}
        direction={"left"}
        icon="labeled"
        vertical
        visible={menuVisible}
        width="thin"
      >
        <Menu.Item
          name="dashboard"
          as={NavLink}
          exact
          to="/aluno/dashboard"
          style={{ textAlign: "left", fontSize: "12pt" }}
          onClick={() => setMenuVisible(!menuVisible)}
        >
          Página Inicial
        </Menu.Item>
        <Menu.Item
          name="questoes"
          as={NavLink}
          to="/aluno/questoes"
          style={{ textAlign: "left", fontSize: "12pt" }}
          onClick={() => setMenuVisible(!menuVisible)}
        >
          Questões
        </Menu.Item>
        <Menu.Item
          name="simulados"
          as={NavLink}
          to="/aluno/simulados"
          style={{ textAlign: "left", fontSize: "12pt" }}
          onClick={() => setMenuVisible(!menuVisible)}
        >
          Simulados
        </Menu.Item>
        <div style={{ position: "absolute", bottom: "0", width: "100%" }}>
          <Menu.Item
            style={{ textAlign: "left", width: "100%", fontSize: "12pt" }}
            onClick={() =>
              openModal(<MobileProfileEditForm />, "large", false, true)
            }
          >
            Meu Perfil
          </Menu.Item>
          <Menu.Item
            style={{ textAlign: "left", width: "100%", fontSize: "12pt" }}
            onClick={open}
          >
            Resetar
          </Menu.Item>
          <Menu.Item
            style={{ textAlign: "left", width: "100%", fontSize: "12pt" }}
            onClick={logout}
          >
            Sair
          </Menu.Item>
        </div>
      </Sidebar>
    </Fragment>
  );
};

export default observer(MobileNavBar);
