import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import LoadComponent from "../../../../app/layout/LoadComponent";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import CountDown from "../../../timer/CountDown";
import ExamQuestionContent from "./ExamQuestionContent";

interface DetailParams {
  examuserid: string;
}

const ExamQuestionDashboard: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { exam } = rootStore.examStore;
  const { clearSelectedAnswer, getQuestion, loadingInitial, examUserAnswer } =
    rootStore.examUserAnswerStore;

  useEffect(() => {
    clearSelectedAnswer();
    getQuestion(match.params.examuserid);
  }, [clearSelectedAnswer, getQuestion, match.params.examuserid]);

  if (loadingInitial) return <LoadComponent content="Carregando questão" />;

  return (
    <Fragment>
      {examUserAnswer! && examUserAnswer!.examUser && (
        <CountDown
          startDate={examUserAnswer!.examUser!.startDate}
          initMinutes={exam!.durationTime}
          examUserId={match.params.examuserid}
        />
      )}

      <ExamQuestionContent examUserAnswer={examUserAnswer!} />
    </Fragment>
  );
};

export default observer(ExamQuestionDashboard);
