import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { Button, Divider, Form, Segment } from "semantic-ui-react";
import SelectInput from "../../app/common/form/SelectInput";
import TextAreaInput from "../../app/common/form/TextAreaInput";
import TextInput from "../../app/common/form/TextInput";
import LoadComponent from "../../app/layout/LoadComponent";
import { RootStoreContext } from "../../app/stores/rootStore";
import { stateOptions } from "../../app/common/options/stateOptions";
import DateInput from "../../app/common/form/DateInput";

const validate = combineValidators({
  displayName: isRequired({ message: "Apelido é obrigatório" }),
  firstName: isRequired({ message: "Nome é obrigatório" }),
  lastName: isRequired({ message: "Sobrenome é obrigatório" }),
  gender: isRequired({ message: "Sexo é obrigatório" }),
  birthDate: isRequired({ message: "Data Nascimento é obrigatório" }),
});

const MobileProfileEditForm: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadProfile, profile, updateProfile, loadingProfile } =
    rootStore.profileStore;
  const { getUser } = rootStore.userStore;

  useEffect(() => {
    getUser().then((user) => loadProfile(user!.username));
  }, [getUser, loadProfile]);

  const genderOptions = [
    { key: "", text: "", value: "" },
    { key: "M", text: "Masculino", value: "M" },
    { key: "F", text: "Feminino", value: "F" },
  ];

  if (loadingProfile) return <LoadComponent content="Carregando perfil..." />;

  return (
    <Fragment>
      <h2>Perfil</h2>
      <Segment clearing>
        <FinalForm
          onSubmit={updateProfile}
          validate={validate}
          initialValues={profile!}
          render={({
            handleSubmit,
            invalid,
            pristine,
            submitting,
            submitSucceeded,
          }) => (
            <Form onSubmit={handleSubmit} error>
              <div style={{ fontSize: "12pt", textAlign: "right" }}>
                <label>
                  <strong>E-mail:</strong> {profile!.email}
                </label>
              </div>
              <Field
                name="displayName"
                title="Apelido"
                component={TextInput}
                placeholder="Apelido"
                value={profile!.displayName}
              />
              <Field
                name="firstName"
                title="Nome"
                component={TextInput}
                placeholder="Nome"
                value={profile!.firstName}
              />
              <Field
                name="lastName"
                title="Sobrenome"
                component={TextInput}
                placeholder="Sobrenome"
                value={profile!.lastName}
              />
              <Field
                name="gender"
                title="Sexo"
                component={SelectInput}
                placeholder="Sexo"
                options={genderOptions}
                value={profile!.gender}
              />
              <Field
                name="cpf"
                title="Cpf"
                component={TextInput}
                placeholder="Cpf"
                value={profile!.cpf}
              />
              <Field
                component={DateInput}
                name="birthDate"
                date={true}
                title="Data Nascimento"
                value={profile!.birthDate ?? ""}
              />
              <Field
                name="phoneCode"
                title="DDD"
                component={TextInput}
                placeholder="DDD"
                value={profile!.phoneCode}
              />
              <Field
                name="phoneNumber"
                title="Telefone"
                component={TextInput}
                placeholder="Telefone"
                value={profile!.phoneNumber}
              />
              <Field
                name="bio"
                title="Descriçāo"
                component={TextAreaInput}
                rows={3}
                placeholder="Bio"
                value={profile!.bio}
              />
              <Divider horizontal>Endereço</Divider>
              <Field
                name="number"
                title="Número"
                component={TextInput}
                placeholder="Número"
                value={profile!.number}
              />
              <Field
                name="street"
                title="Rua"
                component={TextInput}
                placeholder="Rua"
                value={profile!.street}
              />
              <Field
                name="neighborhood"
                title="Bairro"
                component={TextInput}
                placeholder="Bairro"
                value={profile!.neighborhood}
              />
              <Field
                name="city"
                title="Cidade"
                component={TextInput}
                placeholder="Cidade"
                value={profile!.city}
              />
              <Field
                name="state"
                title="Estado"
                options={stateOptions}
                component={SelectInput}
                placeholder="Estado"
                value={profile!.state}
              />
              <Field
                name="cep"
                title="CEP"
                component={TextInput}
                placeholder="CEP"
                value={profile!.cep}
              />
              <Button
                loading={submitting}
                floated="right"
                disabled={invalid || pristine || submitSucceeded}
                positive
                content="Salvar"
              />
            </Form>
          )}
        />
      </Segment>
    </Fragment>
  );
};

export default observer(MobileProfileEditForm);
