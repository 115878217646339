import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Icon, Label, Menu, Tab } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import QuestionComments from "./QuestionComments";
import QuestionProfComment from "./QuestionProfComment";
import QuestionReport from "./QuestionReport";
import QuestionUserAnswers from "./QuestionUserAnswers";

const QuestionFooter: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { question } = rootStore.questionStore;
  const { answerResponse } = rootStore.answerStore;

  const panes = [
    {
      menuItem: (
        <Menu.Item
          name="comentarioProfessor"
          key="comentarioProfessor"
          disabled={answerResponse == null}
        >
          <Icon name="graduation cap" /> Comentário do Professor
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane style={{ display: answerResponse == null ? "none" : "" }}>
          <QuestionProfComment />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          name="comentarios"
          key="comentarios"
          disabled={answerResponse == null}
        >
          <Icon name="comments outline" />
          Comentários
          <Label color="teal">{question ? question.commentsCount : 0}</Label>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <QuestionComments />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          name="estatisticas"
          key="estatisticas"
          disabled={answerResponse == null}
        >
          <Icon name="chart line" /> Estatísticas
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <QuestionUserAnswers />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item name="reportar" position="right" key="reportar">
          <Icon name="warning sign" /> Reportar
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <QuestionReport />
        </Tab.Pane>
      ),
    },
  ];

  return <Tab menu={{ pointing: true }} panes={panes} />;
};

export default observer(QuestionFooter);
