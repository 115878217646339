import { action, computed, observable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { StudentProgressBySubcategoryFilter } from "../models/studentProgress";

const LIMIT = 10;

export default class StudentProgressBySubcategoryStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.studentProgressBySubcategoryPredicate.keys(),
      () => {
        this.studentProgressBySubcategoryPage = 0;
        this.studentProgressBySubcategoryRegistry.clear();
        this.loadStudentProgressBySubcategory();
      }
    );
  }

  @observable loadingInitialProgressBySubCategory = false;

  @observable studentUsername = "";
  @observable categoryId = "";
  @observable sortBy = "";

  @observable studentProgressBySubcategoryRegistry = new Map();
  @observable studentProgressBySubcategoryCount = 0;
  @observable studentProgressBySubcategoryPage = 1;
  @observable studentProgressBySubcategoryPredicate = new Map();
  @observable studentProgressBySubcategoryFilter = new StudentProgressBySubcategoryFilter();

  @action setStudentProgressBySubcategoryPredicate = () => {
    this.studentProgressBySubcategoryPredicate.clear();

    if (this.studentProgressBySubcategoryFilter.categoryId) {
      this.studentProgressBySubcategoryPredicate.set("categoryId", this.studentProgressBySubcategoryFilter.categoryId);
    }
  };
  
  @action setStudentUsername = (username: string) => {
    this.studentUsername = username;
  };

  @action setCategoryId = (categoryId: string) => {
    this.categoryId = categoryId;
  }

  @action setSortBy = (sortBy: string) => {
    this.sortBy = sortBy;
  }

  @computed get studentProgressBySubcategoryAxiosParams() {
    const params = new URLSearchParams();
    params.append(
      "username",
      this.studentUsername
        ? this.studentUsername
        : this.rootStore.userStore.user!.username
    );
    params.append("categoryId", this.categoryId);
    params.append("sortBy", this.sortBy);
    params.append("limit", String(LIMIT));
    params.append(
      "offset",
      `${
        this.studentProgressBySubcategoryPage > 1
          ? (this.studentProgressBySubcategoryPage - 1) * LIMIT
          : 0
      }`
    );

    this.studentProgressBySubcategoryPredicate.forEach((value, key) => {
      params.append(key, value);
    });

    return params;
  }

  @computed get studentProgressBySubcategoryTotalPages() {
    return Math.ceil(this.studentProgressBySubcategoryCount / LIMIT);
  }

  @action studentProgressBySubcategorySetPage = (page: number) => {
    this.studentProgressBySubcategoryPage = page;
  };

  @computed get studentProgressBySubcategory() {
    const spBySubcat = Array.from(
      this.studentProgressBySubcategoryRegistry.values()
    );
    return spBySubcat;
  }

  @action loadStudentProgressBySubcategory = async () => {
    this.loadingInitialProgressBySubCategory = true;
    try {
      this.studentProgressBySubcategoryRegistry.clear();
      const studentProgressEnvelope =
        await agent.StudentProgress.detailsBySubcategory(
          this.studentProgressBySubcategoryAxiosParams
        );
      const { dataCollection, dataCount } = studentProgressEnvelope;

      runInAction(() => {
        dataCollection.forEach((item) => {
          this.studentProgressBySubcategoryRegistry.set(
            item.subcategoryId,
            item
          );
        });
        this.studentProgressBySubcategoryCount = dataCount;
        this.loadingInitialProgressBySubCategory = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingInitialProgressBySubCategory = false;
      });
      toast.error(
        "Não foi possível carregar informações do progresso por subcategoria"
      );
    }
  };
}
