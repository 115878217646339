import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import ExamList from "./ExamList";

const ExamDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadExams } = rootStore.examStore;

  useEffect(() => {
    loadExams();
  }, [loadExams]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={14}>
          <h1>Simulados</h1>
        </Grid.Column>
        <Grid.Column width={2}>
          <Button
            as={Link}
            to="/admin/novosimulado"
            active
            positive
            content="Novo"
            floated="right"
            style={{ marginTop: "12px" }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <ExamList />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(ExamDashboard);
