import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import { Button, Form, Segment } from "semantic-ui-react";
import { IQuestionAnswer } from "../../../../app/models/examUserAnswer";
import { RootStoreContext } from "../../../../app/stores/rootStore";

const ExamQuestionOptions: React.FC<{ questionAnswers: IQuestionAnswer[] }> = ({
  questionAnswers,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { answerId, setSelectedAnswer } = rootStore.examUserAnswerStore;

  const handlerCopy = (e: any) => {
    console.log(e.target.innerHTML);
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();

    alert("Não é permitido copiar");
  };

  return (
    <Fragment>
      {questionAnswers && (
        <Segment clearing basic style={{ padding: "20px 0 0 0" }}>
          {questionAnswers.map((answer: IQuestionAnswer) => (
            <Segment
              key={answer.id}
              color={answer.id === answerId ? "green" : undefined}
              onClick={() => setSelectedAnswer(answer.id)}
            >
              <Form>
                <Form.Group inline>
                  <Form.Field>
                    <Button
                      circular
                      content={answer.identifier}
                      color={answer.id === answerId ? "green" : undefined}
                      onClick={() => setSelectedAnswer(answer.id)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <span onCopy={handlerCopy}>{answer.text}</span>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Segment>
          ))}
        </Segment>
      )}
    </Fragment>
  );
};

export default observer(ExamQuestionOptions);
