import { action, computed, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { ExamCategoryFormValues, IExamCategory } from "../models/examCategory";
import { RootStore } from "./rootStore";

export default class ExamCategoryStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable examCategoryRegistry = new Map();
  @observable submitting = false;
  @observable examCategory: IExamCategory | null = null;

  @computed get examCategoriesByCategory() {
    const examCategories = Array.from(this.examCategoryRegistry.values());
    return examCategories;
  }

  @action loadExamCategories = async (examId: string) => {
    try {
      this.examCategoryRegistry.clear();
      const examCategories = await agent.ExamCategories.list(examId);
      runInAction("loading exam categories", () => {
        examCategories.forEach((examCategory) => {
          this.examCategoryRegistry.set(examCategory.id, examCategory);
        });
      });
    } catch (error) {
      toast.error("Erro ao carregar categorias");
    }
  };

  @action upsertExamCategory = async (examCategory: ExamCategoryFormValues) => {
    this.submitting = true;
    this.examCategory = null;
    try {
      let newExamCategory = await agent.ExamCategories.upsert(examCategory);

      runInAction("creating exam category", () => {
        this.submitting = false;
      });

      return newExamCategory;
    } catch (error) {
      runInAction("create exam category error", () => {
        this.submitting = false;
      });
      toast.error(error.data.errors.totalQuestions);
    }
  };

  @action deleteExamCategory = async (id: string) => {
    this.submitting = true;
    try {
      await agent.ExamCategories.delete(id);
      runInAction("deleting exam category", () => {
        this.examCategoryRegistry.delete(id);
        this.submitting = false;
      });
    } catch (error) {
      runInAction("delete exam category error", () => {
        this.submitting = false;
      });
    }
  };
}
