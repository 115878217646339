export interface IQuestionReport {
  id: string;
  appUserId: string;
  questionId: string;
  createdAt: Date;
  text: string;
}

export class QuestionReportFormValues {
  id?: string = undefined;
  appUserId: string = "";
  questionId: string = "";
  createdAt?: Date = undefined;
  text: string = "";
  
  constructor(init?: QuestionReportFormValues) {
    Object.assign(this, init);
  }
}