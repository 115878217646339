import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { Comment, Icon } from "semantic-ui-react";
import { IComment } from "../../../app/models/comment";

const CommentItem: React.FC<{ commentItem: IComment }> = ({ commentItem }) => {
  return (
    <Comment key={commentItem.id}>
      <Comment.Avatar
        as="a"
        src={
          commentItem.isProfessorAnswer
            ? "/assets/professor.jpg"
            : commentItem.gender === "F"
            ? "/assets/female.jpg"
            : "/assets/male.jpg"
        }
      />
      <Comment.Content>
        <Comment.Author>
          {commentItem.isProfessorAnswer ? (
            <Fragment>
              Professor <Icon name="graduation cap" />
            </Fragment>
          ) : (
            commentItem.displayName
          )}
        </Comment.Author>
        <Comment.Text>
          {commentItem.isProfessorAnswer ? (
            <div
              dangerouslySetInnerHTML={{
                __html: commentItem.text,
              }}
            />
          ) : (
            <div>
              {commentItem.text.split("\n").map((i, key) => {
                return <p key={key}>{i}</p>;
              })}
            </div>
          )}
        </Comment.Text>
        <Comment.Metadata>
          <div>{commentItem.postedDate}</div>
        </Comment.Metadata>
      </Comment.Content>
    </Comment>
  );
};

export default observer(CommentItem);
