import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Card } from "semantic-ui-react";
import { IExam } from "../../../app/models/exam";
import { RootStoreContext } from "../../../app/stores/rootStore";
import ReleasedExamsListItem from "./ReleasedExamsListItem";

const ReleasedExamsList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { examsByName } = rootStore.examStore;

  return (
    <Card.Group>
      {examsByName.map((exam: IExam) => (
        <ReleasedExamsListItem key={exam.id} exam={exam} />
      ))}
    </Card.Group>
  );
};

export default observer(ReleasedExamsList);
