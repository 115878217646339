import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import {
  Button,
  Comment,
  Grid,
  Pagination,
  Radio,
  Segment,
} from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import CommentListItem from "./CommentListItem";

const CommentList: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    commentsByPostedDate,
    setPredicate,
    loadComments,
    commentFilter,
    page,
    setPage,
    totalPages,
    deleteComments,
    submitting,
    selectedComments,
  } = rootStore.commentStore;

  const onAnsweredByProfChange = () => {
    commentFilter.answeredByProf = !commentFilter.answeredByProf;
    setPredicate();
  };

  const onPageChange = (e: any, pageInfo: any) => {
    setPage(pageInfo.activePage);
    loadComments();
  };

  return (
    <Segment>
      <h3>Selecione os comentários que deseja excluir:</h3>
      <Segment basic clearing floated="right" style={{ marginTop: "-50px" }}>
        <Radio
          toggle
          name="answeredByProf"
          checked={commentFilter.answeredByProf}
          onChange={onAnsweredByProfChange}
          label="Comentários respondidos"
        />
      </Segment>

      <Comment.Group>
        {commentsByPostedDate.map((comment) => (
          <CommentListItem key={comment.id} comment={comment} />
        ))}
      </Comment.Group>
      <Grid>
        <Grid.Row>
          <Grid.Column width={12}>
            <Pagination
              defaultActivePage={page}
              onPageChange={(e, pageInfo) => onPageChange(e, pageInfo)}
              totalPages={totalPages}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <Button
              floated="right"
              color="red"
              content="Excluir selecionados"
              onClick={(e) => deleteComments(e)}
              disabled={selectedComments.length === 0}
              loading={submitting}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default observer(CommentList);
