import React, { useContext, useState } from "react";
import { Segment, Form, Button, Dropdown, Grid } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import TextInput from "../../app/common/form/TextInput";
import { RootStoreContext } from "../../app/stores/rootStore";
import DropdownInput from "../../app/common/form/DropdownInput";
import { observer } from "mobx-react-lite";

const QuestionFilter: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { questionFilter, setPredicate } = rootStore.questionStore;
  const { categoriesOptions } = rootStore.categoryStore;
  const { loadSubcategoriesOptions, subcategoriesOptions } =
    rootStore.subcategoryStore;
  const { examiningBoardsOptions } = rootStore.examiningBoardStore;

  const [filterVisible, setFilterVisible] = useState(false);
  const [loadingSubcat, setLoadingSubcat] = useState(false);

  const onChangeCategory = (categoryId: string | any) => {
    questionFilter.categoryId = categoryId;
    setLoadingSubcat(true);
    loadSubcategoriesOptions(false, categoryId).finally(() =>
      setLoadingSubcat(false)
    );
  };

  const applyFilter = (values: any) => {
    const { ...q } = values;
    if (q.action === "filter") {
      questionFilter.text = q.text;
      questionFilter.subcategoryId = q.subcategoryId;
      questionFilter.examiningBoardId = q.examiningBoardId;
    } else {
      setLoadingSubcat(true);
      loadSubcategoriesOptions(false, "").finally(() =>
        setLoadingSubcat(false)
      );
      questionFilter.text = "";
      questionFilter.categoryId = "";
      questionFilter.subcategoryId = "";
      questionFilter.examiningBoardId = "";
      q.subcategoryId = "";
    }

    setPredicate();
  };

  return (
    <Segment>
      <h2 onClick={() => setFilterVisible(!filterVisible)}>Filtros</h2>
      {(filterVisible ||
        questionFilter.text ||
        questionFilter.categoryId ||
        questionFilter.subcategoryId ||
        questionFilter.examiningBoardId) && (
        <Segment>
          <FinalForm
            initialValues={questionFilter}
            onSubmit={applyFilter}
            render={({ handleSubmit, form }) => (
              <Form onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={3}>
                      <Field
                        name="text"
                        placeholder="Questāo"
                        value={questionFilter!.text}
                        component={TextInput}
                      />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Dropdown
                        fluid
                        search
                        selection
                        name="categoryId"
                        placeholder="Selecione uma categoria"
                        options={categoriesOptions}
                        onChange={(e, data) => onChangeCategory(data.value)}
                        value={questionFilter!.categoryId}
                      />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Field
                        name="subcategoryId"
                        placeholder="Selecione uma subcategoria"
                        component={DropdownInput}
                        options={subcategoriesOptions}
                        value={questionFilter.subcategoryId}
                        loading={loadingSubcat}
                      />
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <Field
                        name="examiningBoardId"
                        placeholder="Selecione uma banca"
                        component={DropdownInput}
                        options={examiningBoardsOptions}
                        value={questionFilter.examiningBoardId}
                      />
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Button.Group floated="right">
                        <Button
                          content="Filtrar"
                          type="submit"
                          secondary
                          onClick={() => form.change("action", "filter")}
                        />
                        <Button.Or text="/" />
                        <Button
                          content="Limpar"
                          type="submit"
                          onClick={() => form.change("action", "clearFilter")}
                        />
                      </Button.Group>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )}
          />
        </Segment>
      )}
    </Segment>
  );
};

export default observer(QuestionFilter);
