import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect } from "react";
import FAQ from "./FAQ";
import PlansItems from "./PlansItems";
import SiteStatistics from "./SiteStatistics";
import Testimonials from "./Testimonials";

const PlansDashboard: React.FC = () => {
  useEffect(() => {
    document.body.classList.add("background-orange-gradient");
    return () => {
      document.body.classList.remove("background-orange-gradient");
    };
  });

  return (
    <Fragment>
      <PlansItems />
      <SiteStatistics />
      <Testimonials />
      <FAQ />
    </Fragment>
  );
};

export default observer(PlansDashboard);
