import React, { Fragment, useContext } from "react";
import { Button, Container, Header, Icon, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";

const ResendWelcomeEmail = () => {
  const rootStore = useContext(RootStoreContext);
  const { resendWelcomeEmail } = rootStore.userStore;

  return (
    <Segment inverted textAlign="center" vertical className="masthead">
      <Container text>
        <Fragment>
          <Header as="h1" inverted>
            CFC Academy
          </Header>
          <Segment placeholder size="large">
            <Header icon>
              <Icon name="check" />
              <h3>Usuário já cadastrado.</h3>
            </Header>
            <br /> Favor checar e-mail de boas vindas e clicar no link de
            confirmação de e-mail.
            <br /> Caso não tenha recebido o e-mail, clicar no botão abaixo para
            reenvio do mesmo.
          </Segment>
          <Button
            style={{ marginTop: "20px" }}
            size="huge"
            inverted
            onClick={() => resendWelcomeEmail()}
          >
            Reenviar E-mail
          </Button>
        </Fragment>
      </Container>
    </Segment>
  );
};

export default ResendWelcomeEmail;
