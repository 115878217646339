export interface IExaminingBoardsEnvelope {
    dataCollection: IExaminingBoard[];
    dataCount: number;
  }
  
  export interface IExaminingBoard {
    id: string;
    code: string;
    name: string;
    description: string;
    questionCount: number;
  }
  
  export class ExaminingBoardFilter {
    code?: string = "";
    name?: string = "";
    sortBy: string = "";
  }
  
  export class ExaminingBoardFormValues {
    id?: string = undefined;
    code: string = "";
    name: string = "";
    description: string = "";
  
    constructor(init?: ExaminingBoardFormValues) {
      Object.assign(this, init);
    }
  }

  export class ExaminingBoardForSelect {
    key: string = "";
    text: string = "";
    value: string = "";
  
    constructor(key: string, text: string, value: string) {
      this.key = key;
      this.text = text;
      this.value = value;
    }
  }