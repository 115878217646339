import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Icon, Message, Popup, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const ProgressByCategory: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { studentDashboard } = rootStore.studentDashboardStore;

  useEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.padding(40, 40, 40, 40);

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = "categoryName";
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = "categoryName";
    series.dataFields.valueX = "percetage";
    series.tooltipText = "{valueX.value}";
    series.columns.template.strokeOpacity = 0;
    series.columns.template.column.cornerRadiusBottomRight = 5;
    series.columns.template.column.cornerRadiusTopRight = 5;

    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.horizontalCenter = "left";
    labelBullet.label.dx = 10;
    labelBullet.label.text =
      "{values.valueX.workingValue.formatNumber('#.0as')}";
    labelBullet.locationX = 1;

    // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem!.index);
    });
    
    categoryAxis.sortBySeries = series;
    if (studentDashboard) {
      chart.data = studentDashboard!.asnweredCategories.map(selectFewerProps);
    }

    return () => {
      chart.dispose();
    };
  });

  function selectFewerProps(show: any){
    const {categoryName, totalWinAnsweredQuestions, totalAnsweredQuestions} = show;
    const percetage = Math.round((totalWinAnsweredQuestions * 100) / totalAnsweredQuestions);
    return {categoryName, percetage};
  }

  return (
    <Segment style={{height: "100%"}}>
      <h3>
        Desempenho Geral{" "}
        <Popup
          content="Desempenho por categoria"
          basic
          trigger={<Icon name="info circle" />}
          position="bottom left"
        />
      </h3>
      {studentDashboard && studentDashboard.asnweredCategories.length > 0 ? (
        <div id="chartdiv" style={{ width: "100%", height: "350px" }}></div>
      ) : (
        <Message
          icon="warning circle"
          header="Oops"
          content="Ainda não há dados suficientes"
        />
      )}
    </Segment>
  );
};

export default observer(ProgressByCategory);
