import React, { useContext, useState, useEffect, Fragment } from "react";
import { combineValidators, isRequired } from "revalidate";
import { Link, RouteComponentProps } from "react-router-dom";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { SubcategoryFormValues } from "../../../app/models/subcategory";
import { v4 as uuid } from "uuid";
import { Segment, Form, Button, Grid } from "semantic-ui-react";
import TextInput from "../../../app/common/form/TextInput";
import { Form as FinalForm, Field } from "react-final-form";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import { observer } from "mobx-react-lite";
import { CategoryForSelect } from "../../../app/models/category";
import DropdownInput from "../../../app/common/form/DropdownInput";

const validate = combineValidators({
  code: isRequired({ message: "Sigla é obrigatório" }),
  name: isRequired({ message: "Nome é obrigatório" }),
});

interface DetailParams {
  id: string;
}

const SubcategoryForm: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const {
    createSubcategory,
    editSubcategory,
    submitting,
    loadSubcategory,
    deleteSubcategory,
    target,
  } = rootStore.subcategoryStore;
  const { loadCategoriesForSelect } = rootStore.categoryStore;

  const [subcategory, setSubcategory] = useState(new SubcategoryFormValues());
  const [loading, setLoading] = useState(false);
  const [categoriesForSelect, setCategoriesForSelect] = useState<
    CategoryForSelect[]
  >([]);

  useEffect(() => {
    loadCategoriesForSelect().then((categories) => {
      let categoryList: CategoryForSelect[] = [];
      categories?.forEach((cat) => {
        let category = new CategoryForSelect(cat.id, cat.name, cat.id);
        categoryList.push(category);
      });
      setCategoriesForSelect(categoryList);
    });

    if (match.params.id) {
      setLoading(true);

      loadSubcategory(match.params.id)
        .then((subcategory) =>
          setSubcategory(new SubcategoryFormValues(subcategory))
        )
        .finally(() => setLoading(false));
    }
  }, [loadSubcategory, match.params.id, loadCategoriesForSelect]);

  const handleFinalFormSubmit = (values: any) => {
    const { ...subcategory } = values;
    if (!subcategory.id) {
      let newSubcategory = {
        ...subcategory,
        id: uuid(),
      };
      createSubcategory(newSubcategory);
    } else {
      editSubcategory(subcategory);
    }
  };

  return (
    <Fragment>
      {subcategory.id ? (
        <h1>Editar Subcategoria</h1>
      ) : (
        <h1>Nova Subcategoria</h1>
      )}
      <Segment clearing>
        <FinalForm
          validate={validate}
          initialValues={subcategory}
          onSubmit={handleFinalFormSubmit}
          render={({ handleSubmit, invalid, pristine }) => (
            <Form onSubmit={handleSubmit} loading={loading}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Field
                      name="code"
                      title="Sigla"
                      placeholder="Sigla"
                      value={subcategory.code}
                      component={TextInput}
                      disabled={subcategory.id ? true : false}
                    />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Field
                      name="name"
                      title="Nome"
                      placeholder="Nome"
                      value={subcategory.name}
                      component={TextInput}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Field
                      component={DropdownInput}
                      options={categoriesForSelect}
                      name="categoryId"
                      title="Categoria"
                      placeholder="Categoria"
                      value={subcategory.categoryId}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Field
                      name="description"
                      title="Descrição"
                      placeholder="Descrição"
                      value={subcategory.description}
                      rows={4}
                      component={TextAreaInput}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={16}>
                    <Button
                      loading={submitting}
                      disabled={loading || invalid || pristine}
                      floated="right"
                      positive
                      type="submit"
                      content="Salvar"
                    />
                    <Button
                      as={Link}
                      to="/admin/subcategorias"
                      disabled={loading}
                      floated="right"
                      type="button"
                      content="Cancelar"
                    />
                    {subcategory.id && (
                      <Button
                        loading={target === subcategory.id && submitting}
                        disabled={loading}
                        onClick={(e) => {
                          deleteSubcategory(e, subcategory.id!);
                          setSubcategory(new SubcategoryFormValues());
                        }}
                        floated="right"
                        color="red"
                        content="Excluir"
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        />
      </Segment>
    </Fragment>
  );
};

export default observer(SubcategoryForm);
