import React, { useContext, useState } from "react";
import { Button, Form, Segment } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { QuestionReportFormValues } from "../../../app/models/questionReport";
import { toast } from "react-toastify";

const QuestionReport: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { reportCreate, question, loading } = rootStore.questionStore;

  const [report] = useState(new QuestionReportFormValues());

  const handleFinalFormSubmit = (values: any) => {
    if (values.text === "" || values.text === undefined) {
      toast.error("Comentário é obrigatório");
    } else {
      reportCreate(question!.id, values.text);
      values.text = "";
      toast.info("Questāo reportada com sucesso");
    }
  };

  return (
    <Segment>
      <h3>Reportar Questāo</h3>
      <FinalForm
        initialValues={report}
        onSubmit={handleFinalFormSubmit}
        render={({ handleSubmit }) => (
          <Form reply onSubmit={handleSubmit} loading={loading}>
            <Field
              name="text"
              value={report.text}
              rows={6}
              component={TextAreaInput}
            />
            <Button
              content="Reportar Questāo"
              labelPosition="left"
              icon="edit"
              primary
              type="submit"
              loading={loading}
            />
          </Form>
        )}
      />
    </Segment>
  );
};

export default observer(QuestionReport);
