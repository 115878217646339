import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { IStudentUser } from "../../../app/models/user";

const UserListItem: React.FC<{ user: IStudentUser }> = ({ user }) => {
  return (
    <Table.Row>
      <Table.Cell>
        <Link to={`/admin/usuarios/${user.userName}`}>{user.userName}</Link>
      </Table.Cell>
      <Table.Cell>{user.displayName}</Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
    </Table.Row>
  );
};

export default observer(UserListItem);
