import React, { useContext, Fragment, useState } from "react";
import {
  Menu,
  Dropdown,
  Image,
  Icon,
  Button,
  Label,
  Confirm,
} from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import ProfileEditForm from "../profiles/ProfileEditForm";

const NavBar: React.FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
  const rootStore = useContext(RootStoreContext);
  const { user, logout } = rootStore.userStore;
  const { deleteAllAnswersByStudent } = rootStore.userAnswerStore;
  const { deleteAllExamsByStudent } = rootStore.examUsersStore;
  const { loadData } = rootStore.studentDashboardStore;
  const { openModal } = rootStore.modalStore;

  const [state, setState] = useState({ open: false });
  const open = () => setState({ open: true });
  const close = () => setState({ open: false });

  const resetAnswers = () => {
    deleteAllAnswersByStudent().then(() =>
      deleteAllExamsByStudent().then(() => loadData("0"))
    );
  };

  return (
    <Fragment>
      <Confirm
        open={state.open}
        header="Deseja realmente resetar seu desempenho?"
        content="Isso excluirá automaticamente todas as questões e simulados respondidos."
        cancelButton="Nāo"
        confirmButton="Sim"
        onConfirm={() => {
          resetAnswers();
          close();
        }}
        onCancel={close}
      />

      <Menu fixed="top" borderless>
        <Menu.Item header style={{ color: "dimgrey" }}>
          CFC Academy
        </Menu.Item>
        {isAdmin ? (
          <Fragment>
            <Menu.Item
              name="dashboard"
              as={NavLink}
              exact
              to="/admin/dashboard"
            >
              Página Inicial
            </Menu.Item>
            <Menu.Item name="categorias" as={NavLink} to="/admin/categorias">
              Categorias
            </Menu.Item>
            <Menu.Item
              name="subcategorias"
              as={NavLink}
              to="/admin/subcategorias"
            >
              Subcategorias
            </Menu.Item>
            <Menu.Item name="bancas" as={NavLink} to="/admin/bancas">
              Bancas
            </Menu.Item>
            <Menu.Item name="questoes" as={NavLink} to="/admin/questoes">
              Questões
            </Menu.Item>
            <Menu.Item name="simulados" as={NavLink} to="/admin/simulados">
              Simulados
            </Menu.Item>
            <Dropdown item text="Comentários">
              <Dropdown.Menu>
                <Dropdown.Item
                  name="comentariosquestoes"
                  as={NavLink}
                  to="/admin/comentarios/questoes"
                  text="Questões"
                />
                <Dropdown.Item
                  name="comentariosaulas"
                  as={NavLink}
                  to="/admin/comentarios/aulas"
                  text="Aulas"
                />
              </Dropdown.Menu>
            </Dropdown>
            <Menu.Item name="usuarios" as={NavLink} to="/admin/usuarios">
              Usuários
            </Menu.Item>
            <Menu.Item name="usuarios" as={NavLink} to="/admin/cursos">
              Cursos
            </Menu.Item>
          </Fragment>
        ) : (
          <Fragment>
            <Menu.Item
              name="dashboard"
              as={NavLink}
              exact
              to="/aluno/dashboard"
            >
              <Icon name="star" style={{ paddingRight: "5px" }} />
              Página Inicial
            </Menu.Item>
            <Menu.Item name="questoes" as={NavLink} to="/aluno/questoes">
              <Icon name="compose" style={{ paddingRight: "5px" }} />
              Questões
            </Menu.Item>
            <Dropdown
              item
              trigger={
                <span>
                  <Icon name="bolt" style={{ paddingRight: "5px" }} />
                  Raio-X
                </span>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  name="progressoporcategoria"
                  as={NavLink}
                  to="/aluno/progresso/categoria"
                  text="Por Categoria"
                />
                <Dropdown.Item
                  name="progressoporsubcategoria"
                  as={NavLink}
                  to="/aluno/progresso/subcategoria"
                  text="Por Subcategoria"
                />
                <Dropdown.Item
                  name="progressoporbanca"
                  as={NavLink}
                  to="/aluno/progresso/banca"
                  text="Por Banca"
                />
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              item
              trigger={
                <span>
                  <Icon name="edit" style={{ paddingRight: "20px" }} />
                  Simulados
                </span>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  name="responder"
                  as={NavLink}
                  to="/aluno/simulados"
                  text="Responder"
                />
                <Dropdown.Item
                  name="resultados"
                  as={NavLink}
                  to="/aluno/resultadosimulados"
                  text="Meus Resultados"
                />
              </Dropdown.Menu>
            </Dropdown>
          </Fragment>
        )}

        {user && (
          <Fragment>
            <Menu.Item position="right">
              {!isAdmin && (
                <Fragment>
                  {!user.activePlanName ? (
                    <Button
                      content="Assinar"
                      color="green"
                      href="https://cfcacademy.com.br/cfc-questoes-pv-1/"
                    />
                  ) : (
                    <Label size="big" color="teal">
                      <Icon name="check square outline" />
                      {user.activePlanName}
                    </Label>
                  )}
                </Fragment>
              )}
            </Menu.Item>
            <Menu.Item>
              <Image
                avatar
                spaced="right"
                src={user.image || "/assets/user.png"}
              />
              <Dropdown pointing="top right" text={user.displayName}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      openModal(<ProfileEditForm />, "large", false, true)
                    }
                    text="Meu Perfil"
                    icon="user"
                  />
                  <Dropdown.Item
                    onClick={open}
                    text="Resetar Desempenho"
                    icon="undo"
                  />
                  <Dropdown.Item onClick={logout} text="Sair" icon="power" />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Fragment>
        )}
      </Menu>
    </Fragment>
  );
};

export default observer(NavBar);
