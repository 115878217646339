import { action, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { IVoucher } from "../models/voucher";
import { RootStore } from "./rootStore";

export default class VoucherStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable voucher: IVoucher | null = null;

  @action getValidVoucherDetails = async (code: string) => {
    try {
      var result = await agent.Voucher.getValidVoucherDetails(code);
      runInAction(() => {
        this.voucher = result;
      })
    } catch (error) {
      toast.error(error.data.errors.voucher);
    }
  }

  @action clearVoucher = () => {
    this.voucher = null;
  }
}
