import { action, computed, observable, reaction, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import {
  IImage,
  IQuestion,
  QuestionFilter,
  QuestionStudentFilter,
} from "../models/question";
import { RootStore } from "./rootStore";
import { history } from "../..";
import { SyntheticEvent } from "react";
import { IUserQuestionRate } from "../models/userQuestionRate";

const LIMIT = 10;

export default class QuestionStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.predicate.keys(),
      () => {
        this.page = 0;
        this.questionRegistry.clear();
        this.loadQuestions();
      }
    );
  }

  @observable questionRegistry = new Map();
  @observable question: IQuestion | null = null;
  @observable loadingInitial = false;
  @observable submitting = false;
  @observable uploadingImage = false;
  @observable target = "";
  @observable loading = false;
  @observable questionCount = 0;
  @observable page = 1;
  @observable predicate = new Map();
  @observable predicateStudent = new Map();
  @observable questionFilter = new QuestionFilter();
  @observable userRate: IUserQuestionRate | null = null;
  @observable questionStudentFilter = new QuestionStudentFilter();
  @observable questionNotFound = false;
  @observable previousExaminingBoardId = "";
  @observable previousSubcategoryId = "";
  @observable previousYear = 0;

  @action setPredicate = () => {
    this.predicate.clear();

    if (this.questionFilter.categoryId) {
      this.predicate.set("categoryId", this.questionFilter.categoryId);
    }

    if (this.questionFilter.subcategoryId) {
      this.predicate.set("subcategoryId", this.questionFilter.subcategoryId);
    }

    if (this.questionFilter.examiningBoardId) {
      this.predicate.set(
        "examiningBoardId",
        this.questionFilter.examiningBoardId
      );
    }

    if (this.questionFilter.text) {
      this.predicate.set("text", this.questionFilter!.text);
    }
  };

  @action setPredicateStudent = () => {
    this.predicateStudent.clear();

    if (this.questionStudentFilter.categoryId)
      this.predicateStudent.set(
        "categoryId",
        this.questionStudentFilter.categoryId
      );

    if (this.questionStudentFilter.subcategoryId)
      this.predicateStudent.set(
        "subcategoryId",
        this.questionStudentFilter.subcategoryId
      );

    if (this.questionStudentFilter.examiningBoardId) {
      this.predicateStudent.set(
        "examiningBoardId",
        this.questionStudentFilter.examiningBoardId
      );
    }

    if (this.questionStudentFilter.answerStatus)
      this.predicateStudent.set(
        "answerStatus",
        this.questionStudentFilter.answerStatus
      );
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("limit", String(LIMIT));
    params.append("offset", `${this.page > 1 ? (this.page - 1) * LIMIT : 0}`);
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });

    return params;
  }

  @computed get axiosParamsStudent() {
    const params = new URLSearchParams();

    if (!this.predicateStudent.get("answerStatus")) {
      this.questionStudentFilter.answerStatus = "T";
      this.predicateStudent.set(
        "answerStatus",
        this.questionStudentFilter.answerStatus
      );
    }

    this.predicateStudent.forEach((value, key) => {
      params.append(key, value);
    });

    return params;
  }

  @computed get totalPages() {
    return Math.ceil(this.questionCount / LIMIT);
  }

  @action setPage = (page: number) => {
    this.page = page;
  };

  @computed get questionsByName() {
    return Array.from(this.questionRegistry.values());
  }

  @action loadQuestions = async () => {
    this.loadingInitial = true;
    try {
      this.questionRegistry.clear();
      const questionsEnvelope = await agent.Questions.list(this.axiosParams);
      const { dataCollection, dataCount } = questionsEnvelope;
      runInAction("loading questions", () => {
        dataCollection.forEach((question) => {
          this.questionRegistry.set(question.id, question);
        });
        this.questionCount = dataCount;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load questions error", () => {
        this.loadingInitial = false;
      });
    }
  };

  @action loadQuestion = async (id: string) => {
    let question = this.getQuestion(id);
    if (question) {
      this.question = question;
      return question;
    } else {
      this.loadingInitial = true;
      try {
        question = await agent.Questions.details(id);
        runInAction("loading question", () => {
          this.question = question;
          this.questionRegistry.set(question.id, question);
          this.loadingInitial = false;
        });
        return question;
      } catch (error) {
        runInAction("load question error", () => {
          this.loadingInitial = false;
        });
      }
    }
  };

  @action loadRandomQuestion = async () => {
    this.loadingInitial = true;
    this.questionNotFound = false;
    this.question = null;
    try {
      let question = await agent.Questions.random(this.axiosParamsStudent);
      question.answers = question.answers.sort((a, b) =>
        a.identifier > b.identifier ? 1 : -1
      );
      runInAction("loading random question", () => {
        this.question = question;
        this.loadingInitial = false;
      });
      return question;
    } catch (error) {
      if (error && error.data && error.data.errors.subscription) {
        toast.error(error.data.errors.subscription);
      } else {
        if (error && error.data && error.data.errors.question) {
          toast.error(error.data.errors.question);
          runInAction(() => {
            this.questionNotFound = true;
          });
        } else {
          toast.error("Erro ao carregar questāo");
        }
      }

      runInAction("load random question error", () => {
        this.loadingInitial = false;
      });
    }
  };

  @action questionView = async (id: string) => {
    this.loadingInitial = true;
    this.question = null;
    try {
      let question = await agent.Questions.questionView(id);
      question.answers = question.answers.sort((a, b) =>
        a.identifier > b.identifier ? 1 : -1
      );
      runInAction("loading question", () => {
        this.question = question;
        this.loadingInitial = false;
      });
      return question;
    } catch (error) {
      toast.error("Erro ao carregar questāo");

      runInAction("load question error", () => {
        this.loadingInitial = false;
      });
    }
  };

  @action clearQuestion = () => {
    this.question = null;
  };

  getQuestion = (id: string) => {
    return this.questionRegistry.get(id);
  };

  @action createQuestion = async (question: IQuestion) => {
    this.submitting = true;
    let questionId: string = "";
    try {
      await agent.Questions.create(question).then((response) => {
        questionId = response.id;
      });
      runInAction("creating question", () => {
        this.previousExaminingBoardId = question.examiningBoardId;
        this.previousSubcategoryId = question.subcategoryId;
        this.previousYear = question.year ?? 0;
        this.submitting = false;
      });
      history.push(`/admin/questoes/${questionId}`);
    } catch (error) {
      runInAction("create question error", () => {
        this.submitting = false;
      });
      toast.error("Problem submitting data");
    }
  };

  @action editQuestion = async (question: IQuestion) => {
    this.submitting = true;
    try {
      await agent.Questions.update(question);
      runInAction("editing question", () => {
        this.questionRegistry.set(question.id, question);
        this.question = question;
        this.submitting = false;
      });
      toast.info("Questāo salva com sucesso");
    } catch (error) {
      runInAction("edit questions error", () => {
        this.submitting = false;
      });
      toast.error("Problem submitting data");
    }
  };

  @action deleteQuestion = async (
    event: SyntheticEvent<HTMLButtonElement>,
    id: string
  ) => {
    this.submitting = true;
    this.target = event.currentTarget.name;
    try {
      await agent.Questions.delete(id);
      runInAction("deleting question", () => {
        this.questionRegistry.delete(id);
        this.submitting = false;
        this.target = "";
      });
      history.push("/admin/questoes");
    } catch (error) {
      runInAction("delete question error", () => {
        this.submitting = false;
        this.target = "";
      });
    }
  };

  @action uploadImage = async (file: Blob, questionId: string) => {
    this.uploadingImage = true;
    try {
      const image = await agent.Questions.uploadImage(file, questionId);
      runInAction(() => {
        if (this.question) {
          this.question.images.push(image);
        }
        this.uploadingImage = false;
      });
    } catch (error) {
      toast.error("Problem uploading image");
      runInAction(() => {
        this.uploadingImage = false;
      });
    }
  };

  @action deleteImage = async (questionId: string, image: IImage) => {
    this.loading = true;
    try {
      await agent.Questions.deleteImage(questionId, image.id);
      runInAction(() => {
        this.question!.images = this.question!.images.filter(
          (a) => a.id !== image.id
        );
        this.loading = false;
      });
    } catch (error) {
      toast.error("Problem deleting the image");
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action rate = async (id: string, rate: number) => {
    try {
      await agent.Questions.rate(id, rate);
      let userRate = await agent.Questions.getRate(id);
      runInAction("Rating question", () => {
        this.userRate = userRate;
      });
      toast.info("Questāo avaliada com sucesso");
    } catch (error) {
      runInAction("Error rating question", () => {
        this.userRate = null;
      });
      toast.error("Erro ao classificar questāo");
    }
  };

  @action getRate = async (id: string) => {
    try {
      let userRate = await agent.Questions.getRate(id);
      runInAction(() => {
        this.userRate = userRate;
      });
    } catch (error) {
      runInAction(() => {
        this.userRate = null;
      });
    }
  };

  @action commentCreate = async (id: string, text: string) => {
    try {
      this.loading = true;
      let comment = await agent.Questions.commentCreate(id, text);
      runInAction("Creating comment", () => {
        this.loading = false;
        this.question!.comments.push(comment);
        this.question!.commentsCount++;
      });
    } catch (error) {
      runInAction("Creating comment", () => {
        this.loading = false;
      });
      toast.error("Erro ao comentar questāo");
    }
  };

  @action commentReplyCreate = async (
    id: string,
    parentCommentId: string,
    text: string
  ) => {
    try {
      this.loading = true;
      let comment = await agent.Questions.commentReplyCreate(
        id,
        parentCommentId,
        text
      );
      runInAction("Creating comment", () => {
        this.loading = false;
        if (this.question) {
          this.question!.comments.push(comment);
          if (this.question!.commentsCount) {
            this.question!.commentsCount++;
          } else {
            this.question!.commentsCount = 1;
          }
        }
      });
    } catch (error) {
      runInAction("Creating comment", () => {
        this.loading = false;
      });
      console.log(error);
      toast.error("Erro ao comentar questāo");
    }
  };

  @action reportCreate = async (id: string, text: string) => {
    try {
      this.loading = true;
      await agent.Questions.reportCreate(id, text);
      runInAction("Reporting question", () => {
        this.loading = false;
      });
    } catch (error) {
      runInAction("Error reporting question", () => {
        this.loading = false;
      });
      toast.error("Erro ao reportar questāo");
    }
  };
}
