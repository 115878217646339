import React, { useContext } from "react";
import { RootStoreContext } from "../../../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import { Segment, Form, Grid, Button } from "semantic-ui-react";
import { Field, Form as FinalForm } from "react-final-form";
import DropdownInput from "../../../../app/common/form/DropdownInput";

const StudentProgressBySubcategory: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { categoriesOptions } = rootStore.categoryStore;
  const {
    loadStudentProgressBySubcategory,
    setCategoryId,
    studentProgressBySubcategoryFilter,
  } = rootStore.studentProgressBySubcategoryStore;

  const applyFilter = (values: any) => {
    const { ...q } = values;
    if (q.action === "filter") {
      setCategoryId(q.categoryId);
      studentProgressBySubcategoryFilter.categoryId = q.categoryId;
      loadStudentProgressBySubcategory();
    } else {
      setCategoryId("");
      studentProgressBySubcategoryFilter.categoryId = "";
      loadStudentProgressBySubcategory();
    }
  };

  return (
    <Segment>
      <h2>Filtro</h2>
      <Segment>
        <FinalForm
          initialValues={studentProgressBySubcategoryFilter}
          onSubmit={applyFilter}
          render={({ handleSubmit, form }) => (
            <Form onSubmit={handleSubmit}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={14} verticalAlign="bottom">
                    <Field
                      name="categoryId"
                      title="Categoria"
                      placeholder="Selecione uma categoria"
                      component={DropdownInput}
                      options={categoriesOptions}
                      value={studentProgressBySubcategoryFilter.categoryId}
                    />
                  </Grid.Column>
                  <Grid.Column width={2} verticalAlign="bottom">
                    <Button.Group floated="right">
                      <Button
                        content="Filtrar"
                        type="submit"
                        secondary
                        onClick={() => form.change("action", "filter")}
                      />
                      <Button.Or text="/" />
                      <Button
                        content="Limpar"
                        type="submit"
                        onClick={() => form.change("action", "clearFilter")}
                      />
                    </Button.Group>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        />
      </Segment>
    </Segment>
  );
};

export default observer(StudentProgressBySubcategory);
