import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Button, Dropdown, Form, Grid, Segment } from "semantic-ui-react";
import { Form as FinalForm, Field } from "react-final-form";
import DropdownInput from "../../../../app/common/form/DropdownInput";
import { RootStoreContext } from "../../../../app/stores/rootStore";

const MobileQuestionFilter: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { categoriesOptions, loading } = rootStore.categoryStore;
  const { loadSubcategoriesOptions, subcategoriesOptions } =
    rootStore.subcategoryStore;
  const { questionStudentFilter, loadRandomQuestion, setPredicateStudent } =
    rootStore.questionStore;
  const { setSelectedAnswer, clearAnswer } = rootStore.answerStore;
  const { examiningBoardsOptions } = rootStore.examiningBoardStore;

  const [loadingSubcat, setLoadingSubcat] = useState(false);

  const answerStatusOptions = [
    { key: "T", text: "Todas questões", value: "T" },
    { key: "E", text: "Questões que errei", value: "E" },
  ];

  const onChangeCategory = (categoryId: string | any) => {
    questionStudentFilter.categoryId = categoryId;
    setLoadingSubcat(true);
    loadSubcategoriesOptions(true, categoryId).finally(() =>
      setLoadingSubcat(false)
    );
  };

  const applyFilter = (values: any) => {
    const { ...q } = values;

    if (q.action === "filter") {
      questionStudentFilter.subcategoryId = q.subcategoryId;
      questionStudentFilter.answerStatus = q.answerStatus;
      questionStudentFilter.examiningBoardId = q.examiningBoardId;
    } else {
      setLoadingSubcat(true);
      loadSubcategoriesOptions(true, "").finally(() => setLoadingSubcat(false));
      questionStudentFilter.categoryId = "";
      questionStudentFilter.subcategoryId = "";
      questionStudentFilter.answerStatus = "T";
      questionStudentFilter.examiningBoardId = "";
      q.subcategoryId = "";
      q.answerStatus = "T";
      q.examiningBoardId = "";
    }

    setPredicateStudent();
    setSelectedAnswer("");
    clearAnswer();
    loadRandomQuestion();
  };

  return (
    <Segment>
      <h2>Filtros</h2>
      <FinalForm
        initialValues={questionStudentFilter}
        onSubmit={applyFilter}
        render={({ handleSubmit, form }) => (
          <Form onSubmit={handleSubmit}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Dropdown
                    fluid
                    search
                    selection
                    name="categoryId"
                    placeholder="Selecione uma categoria"
                    options={categoriesOptions}
                    onChange={(e, data) => onChangeCategory(data.value)}
                    value={questionStudentFilter.categoryId}
                    loading={loading}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Field
                    name="subcategoryId"
                    placeholder="Selecione uma subcategoria"
                    component={DropdownInput}
                    options={subcategoriesOptions}
                    value={questionStudentFilter.subcategoryId}
                    loading={loadingSubcat}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Field
                    name="examiningBoardId"
                    placeholder="Selecione uma banca"
                    component={DropdownInput}
                    options={examiningBoardsOptions}
                    value={questionStudentFilter.examiningBoardId}
                    loading={loading}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Field
                    name="answerStatus"
                    component={DropdownInput}
                    options={answerStatusOptions}
                    value={questionStudentFilter.answerStatus}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Button
                    content="Filtrar"
                    type="submit"
                    secondary
                    floated="right"
                    onClick={() => form.change("action", "filter")}
                  />
                  <Button
                    content="Limpar"
                    type="submit"
                    floated="right"
                    onClick={() => form.change("action", "clearFilter")}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      />
    </Segment>
  );
};

export default observer(MobileQuestionFilter);
