import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Header, Segment, Statistic } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";

const WinFailTile: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { studentDashboard } = rootStore.studentDashboardStore;

  return (
    <Segment clearing style={{ height: "100%" }}>
      <Header as="h3" content="Total de acertos / erros" />
      <Segment basic clearing floated="right" style={{ marginTop: "40px" }}>
        <Statistic.Group>
          <Statistic floated="right">
            <Statistic.Value>
              {studentDashboard && studentDashboard.totalWinQuestions}
            </Statistic.Value>
            <Statistic.Label>Acertos</Statistic.Label>
          </Statistic>
          <Statistic floated="right">
            <Statistic.Value>|</Statistic.Value>
          </Statistic>
          <Statistic floated="right">
            <Statistic.Value>
              {studentDashboard && studentDashboard.totalFailQuestions}
            </Statistic.Value>
            <Statistic.Label>Erros</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Segment>
    </Segment>
  );
};

export default observer(WinFailTile);
