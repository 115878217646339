import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { RootStoreContext } from "../../../../../app/stores/rootStore";
import IExamUserAnswer from "../../../../../app/models/examUserAnswer";
import LoadComponent from "../../../../../app/layout/LoadComponent";
import AnsweredQuestion from "./AnsweredQuestion";

interface DetailParams {
  examuserid: string;
}

const AnsweredQuestionList: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { getAnsweredQuestions, loadingInitial } =
    rootStore.examUserAnswerStore;

  const [answeredQuestions, setAnsweredQuestions] = useState<
    IExamUserAnswer[] | null
  >(null);

  useEffect(() => {
    getAnsweredQuestions(match.params.examuserid).then((examUserAnswers) =>
      setAnsweredQuestions(examUserAnswers)
    );
  }, [getAnsweredQuestions, match.params.examuserid]);

  if (loadingInitial) return <LoadComponent content="Carregando questões" />;

  return (
    <Fragment>
      {answeredQuestions &&
        answeredQuestions.map((question) => (
          <AnsweredQuestion question={question} />
        ))}
    </Fragment>
  );
};

export default observer(AnsweredQuestionList);
