import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import LoadComponent from "../../../app/layout/LoadComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import QuestionContent from "./QuestionContent";
import QuestionFooter from "./QuestionFooter";

interface DetailParams {
  id: string;
}

const QuestionView: React.FC<RouteComponentProps<DetailParams>> = ({ match }) => {
  const rootStore = useContext(RootStoreContext);
  const { questionView, question, loadingInitial } = rootStore.questionStore;

  useEffect(() => {
    questionView(match.params.id);
  }, [questionView, match.params.id]);

  if (loadingInitial) return <LoadComponent content="Carregando questāo..." />;
  return (
    <Fragment>
      {question && (
        <Fragment>
          <QuestionContent />
          <QuestionFooter />
        </Fragment>
      )}
    </Fragment>
  );
};

export default observer(QuestionView);
