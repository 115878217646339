import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Data } from "react-csv/components/CommonPropTypes";
import { Form as FinalForm, Field } from "react-final-form";
import { Grid, Form, Segment, Button } from "semantic-ui-react";
import SelectInput from "../../../app/common/form/SelectInput";
import TextInput from "../../../app/common/form/TextInput";
import LoadComponent from "../../../app/layout/LoadComponent";
import { RootStoreContext } from "../../../app/stores/rootStore";
import UserList from "./UserList";

const UserDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { loadingInitial, studentFilter, setPredicate, downloadUserReport } =
    rootStore.userStore;

  const statusOptions = [
    { key: true, text: "Ativo", value: true },
    { key: false, text: "Inativo", value: false },
  ];

  const paidOptions = [
    { key: "todos", text: "Todos", value: "todos" },
    { key: "pago", text: "Pago", value: "pago" },
  ];

  const fileHeaders = [
    { label: "fn", key: "firstName" },
    { label: "ln", key: "lastName" },
    { label: "apelido", key: "apelido" },
    { label: "username", key: "username" },
    { label: "cpf", key: "cpf" },
    { label: "dob", key: "birthDate" },
    { label: "doby", key: "birthYear" },
    { label: "age", key: "age" },
    { label: "email", key: "email" },
    { label: "gen", key: "gender" },
    { label: "phone", key: "phoneNumber" },
    { label: "situacao", key: "status" },
    { label: "data criacao", key: "createdDate" },
    { label: "data ultimo login", key: "lastLoginDate" },
    { label: "bairro", key: "neighborhood" },
    { label: "ct", key: "city" },
    { label: "st", key: "state" },
    { label: "country", key: "country" },
    { label: "zip", key: "cep" },
    { label: "value", key: "totalMoneySpent" },
  ];

  const [fileData, setFileData] = useState<Data | string>("");

  useEffect(() => {
    setPredicate();
    downloadUserReport().then((response) => {
      setFileData(response);
    });
  }, [setPredicate, downloadUserReport]);

  if (loadingInitial) return <LoadComponent content="Carregando usuarios..." />;

  const handleSubmitFilter = (values: any) => {
    const { ...filter } = values;
    studentFilter.name = filter.name;
    studentFilter.onlyActive = filter.onlyActive;
    studentFilter.paidStatus = filter.paidStatus;
    setPredicate();
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={12}>
          <h1>Usuários</h1>
        </Grid.Column>
        <Grid.Column width={4}>
          {fileData?.length && (
            <CSVLink
              headers={fileHeaders}
              data={fileData}
              target="_blank"
              filename={`alunos_${new Date().toLocaleDateString()}.csv`}
              className="ui primary button"
              style={{ float: "right", marginTop: "15px" }}
            >
              Exportar para Facebook
            </CSVLink>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment>
            <h2>Filtros</h2>
            <Segment>
              <FinalForm
                initialValues={studentFilter}
                onSubmit={handleSubmitFilter}
                render={({ handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Field
                        name="name"
                        placeholder="Nome"
                        value={studentFilter!.name}
                        component={TextInput}
                        width={3}
                      />
                      <Field
                        component={SelectInput}
                        options={statusOptions}
                        name="onlyActive"
                        value={studentFilter!.onlyActive.toString()}
                        width={2}
                      />
                      <Field
                        component={SelectInput}
                        options={paidOptions}
                        name="paidStatus"
                        value={studentFilter!.paidStatus}
                        width={2}
                      />
                      <Button content="Pesquisar" />
                    </Form.Group>
                  </Form>
                )}
              />
            </Segment>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <UserList />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default observer(UserDashboard);
