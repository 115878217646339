import { RootStore } from "./rootStore";
import { action, observable } from "mobx";
import { BreadcrumbSection } from "../models/breadcrumbSection";

export default class BreadcrumbStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable.shallow sections: BreadcrumbSection[] | undefined = undefined;

  @action setBreadcrumbsSections = (sections: any) => {
    this.sections = sections;
  };
}
