import { ILesson } from "./lesson";

export interface IModulesEnvelope {
  dataCollection: IModule[];
  dataCount: number;
}

export interface IModule {
  id: string;
  name: string;
  courseId: string;
  order: number;
  lessons: ILesson[];
}

export class ModuleFormValues {
  id?: string = undefined;
  name: string = "";
  courseId: string = "";
  order: number = 1;

  constructor(init?: ModuleFormValues) {
    Object.assign(this, init);
  }
}