import React, { useContext } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { Form, Button, Segment } from "semantic-ui-react";
import TextInput from "../../app/common/form/TextInput";
import { RootStoreContext } from "../../app/stores/rootStore";
import { IUserFormValues } from "../../app/models/user";
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import ErrorMessage from "../../app/common/form/ErrorMessage";
import { Link } from "react-router-dom";
import ForgotPasswordForm from "./ForgotPasswordForm";
import RegisterForm from "./RegisterForm";

const validate = combineValidators({
  email: isRequired({ message: "Email é obrigatório" }),
  password: isRequired({ message: "Senha é obrigatório" }),
});

const LoginForm = () => {
  const rootStore = useContext(RootStoreContext);
  const { login } = rootStore.userStore;
  const { openModal } = rootStore.modalStore;
  return (
    <Segment>
      <FinalForm
        onSubmit={(values: IUserFormValues) =>
          login(values).catch((error) => ({
            [FORM_ERROR]: error,
          }))
        }
        validate={validate}
        render={({
          handleSubmit,
          submitting,
          submitError,
          invalid,
          pristine,
          dirtySinceLastSubmit,
        }) => (
          <Form onSubmit={handleSubmit} error>
            <Field name="email" placeholder="Email" component={TextInput} />
            <Field
              name="password"
              placeholder="Senha"
              component={TextInput}
              type="password"
            />
            {submitError && !dirtySinceLastSubmit && (
              <ErrorMessage
                error={submitError}
                text="Email ou senha inválidos"
              />
            )}
            <Button
              disabled={(invalid && !dirtySinceLastSubmit) || pristine}
              loading={submitting}
              style={{ backgroundColor: "#1977F2", color: "white" }}
              content="Entrar"
              fluid
            />
            <Segment
              clearing
              basic
              textAlign="center"
              style={{
                marginTop: "0px",
                paddingTop: "15px",
                paddingBottom: "15px",
                paddingRight: "1px",
                bottom: "-8px",
              }}
            >
              <Link
                to="#"
                onClick={() => openModal(<ForgotPasswordForm />, "mini", false, true)}
                style={{ color: "#1977F2", fontWeight: "bolder" }}
              >
                Esqueci minha senha
              </Link>
              <br />
              <br />
              <hr />
              <br />
              <Button
                onClick={() => openModal(<RegisterForm />, "mini", false, true)}
                content="Criar uma nova conta"
                color="green"
              />
            </Segment>
          </Form>
        )}
      />
    </Segment>
  );
};

export default LoginForm;
