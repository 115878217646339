import { ILessonComment } from "./lessonComment";

export interface ILessonEnvelope {
  dataCollection: ILesson[];
  dataCount: number;
}

export interface ILesson {
  id: string;
  name: string;
  moduleId: string;
  moduleName: string;
  videoUrl: string;
  order: number;
  documentUrls: string;
  courseId: string;
  courseName: string;
  lessonViewed: boolean;
  lessonComments: ILessonComment[];
}

export class LessonFormValues {
  id?: string = undefined;
  name: string = "";
  moduleId: string = "";
  videoUrl: string = "";
  order: number = 1;
  documentUrls: string = "";

  constructor(init?: LessonFormValues) {
    Object.assign(this, init);
  }
}