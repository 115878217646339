import { observer } from "mobx-react-lite";
import React from "react";
import { Grid, Icon, Segment } from "semantic-ui-react";

const Testimonials: React.FC = () => {
  return (
    <Segment basic className="testimonials">
      <h1 style={{ textAlign: "center", marginBottom: "75px" }}>
        O que nossos alunos falam do CFC Academy
      </h1>
      <Grid columns="3">
        <Grid.Row>
          <Grid.Column>
            <Segment className="testimonials-items">
              <div>
                <Icon name="quote left" />
                <i>
                  {" "}
                  O sistema é fantástico. A organização das disciplinas por
                  Norma Contábil é algo que ajuda muito nos estudos para o Exame
                  do CFC e CNAI. Não existe outa ferramenta igual a essa.{" "}
                </i>
                <Icon name="quote right" />
              </div>
              <div className="testimonials-item-author">
                <div>Danilo</div>
                <div className="testimonials-item-passed-exam">
                  Aprovado CNAI
                </div>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment className="testimonials-items testimonials-centered-item">
              <div>
                <Icon name="quote left" />
                <i>
                  {" "}
                  Responde as dúvidas bem rápido e forma didática. O sistema de
                  questões é a melhor coisa que inventaram para os estudantes de
                  contabilidade. Incrível. Faltava uma ferramenta como essa para
                  os estudantes de contabilidade. Irei utilizar para estudar
                  para as provas da faculdade e para estudar para concurso!
                  Simplesmente fantástica!{" "}
                </i>
                <Icon name="quote right" />
              </div>
              <div className="testimonials-item-author">
                <div>Marta</div>
                <div className="testimonials-item-passed-exam">
                  Aprovada Exame de Suficiência
                </div>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment className="testimonials-items">
              <div>
                <Icon name="quote left" />
                <i>
                  {" "}
                  Graças ao CFC Academy e as milhares de questões que respondi
                  fui aprovada no Exame de Suficiência 2021.1. Sem esse sistema
                  minha aprovação não teria acontecido. Recomendo a todo mundo!
                  Assine!{" "}
                </i>
                <Icon name="quote right" />
              </div>
              <div className="testimonials-item-author">
                <div>Ana</div>
                <div className="testimonials-item-passed-exam">
                  Aprovada Exame de Suficiência
                </div>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default observer(Testimonials);
