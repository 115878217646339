import { action } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./rootStore";
import { format } from "date-fns";

export default class CheckoutStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action completeOrder = async (pagarMeOrderId: string) => {
    try {
      var today = format(new Date(), "yyyy-MM-dd");
      var orderStatus = await agent.Checkout.completeOrder(pagarMeOrderId, today);
      return orderStatus;
    } catch (error) {
      console.log(error);
    }
  };
}
